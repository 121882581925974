.rates-modal .checkbox-list {
  height: 22rem;
  overflow: scroll;
  border: 1px solid rgba(0, 0, 0, 0.15); }

.rates-modal .bottom-buttons {
  display: flex;
  justify-content: right;
  margin-top: 1rem; }

.rates-modal .select-all-rates {
  margin: 0.5rem; }
  .rates-modal .select-all-rates .check-item {
    margin-right: 0.25rem;
    width: 15px; }
