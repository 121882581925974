.userProfileContainer .app-header {
  width: 110%;
  position: static !important;
  padding-left: 20px; }

.userProfileContainer .informationContainer {
  float: center;
  margin-top: 40px;
  margin-left: 30px; }

.userProfileContainer .profileInfoContainer, .userProfileContainer .activityInfoContainer {
  border: solid 2px #cfd8dc; }

.userProfileContainer .profileInfoContainer {
  padding: 0px 15px 15px 30px; }

.userProfileContainer .activityInfoContainer {
  padding: 30px 0px 30px 30px; }

.userProfileContainer .boxTitleProfile {
  margin-top: -12px;
  margin-bottom: 5px; }

.userProfileContainer .boxTitle, .userProfileContainer .boxTitleProfile {
  width: 60px;
  padding-left: 8px;
  background-color: white; }

.userProfileContainer .boxTitle {
  margin-top: -42px;
  margin-bottom: 20px; }

.userProfileContainer .changeExam {
  margin-top: 20px;
  cursor: pointer; }

.userProfileContainer .deleteUser {
  display: block;
  margin-right: 50px;
  margin-top: 20px;
  cursor: pointer; }

.userProfileContainer .disableUser {
  display: block;
  margin-right: 50px;
  cursor: pointer; }

.userProfileContainer .resetPwd {
  cursor: pointer; }

.userProfileContainer .space {
  margin-top: 20px; }

.userProfileContainer .adminSelect {
  width: 155px;
  height: 31px; }

.userProfileContainer .buttonsContainer {
  clear: both;
  margin-top: 20px; }

.userProfileContainer .navbar {
  justify-content: flex-start; }

.userProfileContainer .profileTitle {
  margin-left: 10px;
  font-weight: bold; }

.userProfileContainer .subtopics-list-title {
  margin-top: 10px;
  margin-bottom: 10px; }

.userProfileContainer .subtopics-list {
  padding: 10px; }

.userProfileContainer .changeStripeIdBtn {
  margin-top: 20px; }

.userProfileContainer .RequestChange {
  color: #999999;
  font-style: italic; }
  .userProfileContainer .RequestChange .RequestChangeActions {
    text-align: center;
    text-decoration: underline;
    margin-bottom: 10px; }

[title~="Disabled"] {
  color: #f86c6b; }

[title~="Enabled"] {
  color: #4dbd74; }

[title="Blocked"] {
  color: #f86c6b; }

[title="Not Blocked"] {
  color: #4dbd74; }

.userCardsContainer {
  width: 100%; }

.user-practice-exams-container {
  padding: 8px 0; }
  .user-practice-exams-container .cardRowContainer {
    width: 100%; }
    .user-practice-exams-container .cardRowContainer .middleColumn {
      padding-right: 0; }
      .user-practice-exams-container .cardRowContainer .middleColumn .pe-row {
        display: flex;
        margin-bottom: 6px;
        align-items: center; }
        .user-practice-exams-container .cardRowContainer .middleColumn .pe-row .pe-name {
          width: 65%;
          overflow-x: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          margin-right: auto;
          font-weight: bold; }
        .user-practice-exams-container .cardRowContainer .middleColumn .pe-row .pe-button {
          width: 30%;
          margin: 0;
          font-size: 12px;
          text-align: center; }

.cardRowContainer .input-group-select {
  display: inline-block;
  width: 80%; }

.cardRowContainer .crossSelect {
  display: inline-block;
  width: 15%;
  margin-left: 5px; }

.cardRowContainer .writersSelector {
  width: 100% !important; }

.cardRowContainer .select2-container--default, .cardRowContainer .select2-selection--single, .cardRowContainer .select2-selection {
  border-radius: 0px !important;
  height: 35px !important; }

.cardRowContainer .select2-selection__arrow {
  display: none !important; }

.cardRowContainer .address-user-verification {
  width: inherit; }

.cardRowContainer .error-message {
  font-size: small;
  color: red; }

.cardRowContainer .tooltipStyle {
  position: absolute;
  z-index: 9999;
  white-space: normal;
  max-width: 200px;
  padding: 8px; }

.practice-exam-modal .pe-modal-body {
  padding-top: 16px; }
  .practice-exam-modal .pe-modal-body .close-icon {
    position: absolute;
    right: 16px;
    top: 16px;
    font-size: 16px;
    cursor: pointer; }
  .practice-exam-modal .pe-modal-body .big-title {
    font-size: 18px; }
  .practice-exam-modal .pe-modal-body .detail-row {
    display: flex;
    width: 100%;
    margin: 16px 0;
    justify-content: space-between; }
    .practice-exam-modal .pe-modal-body .detail-row .detail-title {
      font-weight: bold;
      width: 33.33%;
      text-align: left; }
    .practice-exam-modal .pe-modal-body .detail-row .detail-value {
      width: 33.33%;
      text-align: right; }
    .practice-exam-modal .pe-modal-body .detail-row .detail-points {
      width: 33.33%;
      text-align: right; }
  .practice-exam-modal .pe-modal-body .pe-modal-footer {
    width: 100%;
    display: flex;
    align-items: center;
    margin-top: 16px; }
    .practice-exam-modal .pe-modal-body .pe-modal-footer .confirmation-text {
      color: red; }
    .practice-exam-modal .pe-modal-body .pe-modal-footer .btn {
      border: none;
      border-radius: 4px; }
