.ContentRow {
  display: flex;
  height: 100%; }

.pdfContainer {
  background: #505050;
  padding: 10px;
  margin-bottom: 150px;
  display: flex;
  justify-content: center;
  height: 100%;
  flex: 1; }

.ResizableContainer {
  border-right: 1px solid black; }

.SimplePDF {
  flex: 1; }

.pdf-upload input {
  display: none; }

.pdf-upload {
  display: inline-block;
  margin-left: 10px; }
  .pdf-upload i {
    cursor: pointer; }

.NoPdfText {
  width: 50%;
  display: flex;
  align-items: center;
  height: 80%;
  justify-content: center; }
