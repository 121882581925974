.sidebar {
  width: 70px !important;
  background-color: #021e5f !important;
  z-index: 999999; }
  .sidebar .nav {
    width: 70px; }
  .sidebar .sidebar-nav {
    width: 70px !important; }
  .sidebar .nav-item {
    width: 100%;
    text-align: center; }
  .sidebar .nav-link.active img {
    background-color: #20A8D9; }
  .sidebar .linkIcon {
    padding: 0.75rem;
    border-radius: 6px; }
  .sidebar .__react_component_tooltip.place-right {
    margin-left: 2px !important;
    margin-top: 10px !important;
    left: 70px !important; }

.sidebar-fixed .main, .sidebar-fixed .app-footer {
  margin-left: 70px !important; }

.sidebar-fixed.sidebar-hidden .main, .sidebar-fixed.sidebar-hidden .app-footer {
  margin-left: 0 !important; }

.sidebar .nav .nav-item .nav-link.active,
.sidebar .nav .nav-item .navbar .active.dropdown-toggle,
.navbar .sidebar .nav .nav-item .active.dropdown-toggle {
  background: transparent !important; }

.sidebar .nav .nav-item .nav-link,
.sidebar .nav .nav-item .navbar .dropdown-toggle,
.navbar .sidebar .nav .nav-item .dropdown-toggle {
  padding: 0; }

.app-footer {
  flex: 0 0 30px;
  min-height: 30px;
  line-height: 30px; }
