.groups-component {
  height: 100%;
  overflow-y: scroll;
  margin-right: 0px;
  margin-left: 0px; }
  .groups-component .groups-content-container {
    padding: 15px; }
  .groups-component .card {
    max-width: 480px;
    min-width: 320px; }
    .groups-component .card .fa-plus {
      margin-top: 5px;
      display: block;
      float: right; }
  .groups-component .rates-list-container {
    padding-right: 0px !important;
    padding-left: 0px !important;
    text-align: left !important;
    overflow-y: hidden; }
  .groups-component .TextLinkAtBottom {
    margin-top: 30px; }
  .groups-component button {
    margin-top: 30px; }
  .groups-component .member-line {
    padding: 5px; }
    .groups-component .member-line:hover {
      background-color: #dcf5f3; }
  .groups-component .fa-trash {
    float: right;
    cursor: pointer; }
