.rolesListContainer {
  width: 100%; }
  .rolesListContainer .rolesTable {
    clear: both;
    padding-left: 10px;
    padding-top: 10px; }
    .rolesListContainer .rolesTable .roleRow {
      display: flex;
      align-items: center; }
      .rolesListContainer .rolesTable .roleRow .valueName {
        margin-left: 15px; }
  .rolesListContainer .card-block {
    width: 150%; }
  .rolesListContainer .plusContainer {
    display: flex;
    align-items: center; }
  .rolesListContainer .pagination {
    padding-top: 20px;
    width: 75%;
    margin: 0 auto; }
  .rolesListContainer .roleRow {
    height: 70px;
    cursor: pointer; }
    .rolesListContainer .roleRow.active {
      background-color: #ffffff; }
  .rolesListContainer .btn-primary {
    cursor: pointer; }
  .rolesListContainer .row {
    margin: 0px; }
  .rolesListContainer .title {
    margin-top: 16px;
    margin-left: 16px;
    font-weight: bold !important; }
  .rolesListContainer .line {
    margin-top: 12px;
    width: 88px;
    height: 4px;
    opacity: 0.79;
    background-color: #000000; }
  .rolesListContainer .roleRow {
    width: 100%;
    height: 48px; }
    .rolesListContainer .roleRow:hover {
      background-color: #f6f5f4; }
  .rolesListContainer .table {
    overflow-y: scroll;
    overflow-x: hidden;
    height: 900px; }
  .rolesListContainer .plusContainer {
    display: flex;
    align-items: center;
    width: 18px;
    margin: auto;
    padding: 23px 0px 0px 13px; }
    .rolesListContainer .plusContainer .fa-plus {
      margin-top: 1px;
      margin-left: 2px; }
