.createBtn {
  display: block;
  width: 94.17px !important;
  height: 35px !important;
  margin: 0 auto !important; }

.alert-success-newAdmin {
  margin-top: 10px; }

.hidden {
  opacity: 0;
  display: none; }
