.create-reference-component form {
  margin-top: 10px;
  margin-bottom: 10px; }

.create-reference-component .btn {
  width: 50%;
  height: 40px;
  font-weight: bold; }

.create-reference-component .bottom-buttons {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 15px;
  margin-right: 15px; }

.create-reference-component .bottom-buttons-new .fa-trash {
  position: absolute;
  left: 20px;
  bottom: 27px;
  font-size: 20px;
  color: grey; }

.create-reference-component .card-block {
  margin-bottom: 35px; }

.create-reference-component .text {
  margin-bottom: 20px;
  line-height: 170%; }

.create-reference-component .errors {
  color: red;
  text-align: center; }

.create-reference-component .label {
  display: inline-block;
  font-weight: bold; }

.modal-create-reference .modal-content {
  margin-top: 200px !important; }

.modal-create-reference .card-block {
  padding-top: 0px; }

.modal-create-reference .modal-body {
  padding-bottom: 0px; }
