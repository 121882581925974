.rates-list-component {
  margin-bottom: 50px;
  height: 100%; }
  .rates-list-component .rates-title {
    padding: 16px 0 0 18px;
    font-size: 120%; }
    .rates-list-component .rates-title .fa-plus {
      float: right;
      cursor: pointer;
      margin-right: 15px;
      margin-left: 15px; }
  .rates-list-component .rate-line-pmk {
    cursor: pointer; }
    .rates-list-component .rate-line-pmk.active {
      font-weight: bold; }
  .rates-list-component .rate-line {
    cursor: pointer;
    font-size: 93%;
    height: unset;
    padding: 15px;
    cursor: pointer;
    background: #eaecee;
    width: 100%; }
    .rates-list-component .rate-line.active {
      font-weight: bold;
      background-color: #ffffff; }
  .rates-list-component .rates-list {
    text-align: left; }
    @media screen and (max-width: 767px) {
      .rates-list-component .rates-list {
        display: flex;
        justify-content: space-between; } }
    .rates-list-component .rates-list .row {
      padding-left: 15px;
      margin-bottom: 0px; }
  .rates-list-component .fa-book {
    float: right;
    margin-right: 10px; }
  .rates-list-component .fa-book, .rates-list-component .fa-list-ul {
    cursor: pointer;
    margin-top: 5px; }
  .rates-list-component .fa-list-ul {
    float: right; }
  .rates-list-component .PaginationWrapper {
    bottom: 35px;
    position: absolute; }
    @media screen and (max-width: 767px) {
      .rates-list-component .PaginationWrapper {
        bottom: 0; } }
    .rates-list-component .PaginationWrapper .page-link {
      padding: 0.4rem 0.55rem; }
  .rates-list-component .shortCutsContainer a {
    color: black; }
