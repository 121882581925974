.search-page {
  height: 100%;
  overflow-y: auto; }
  .search-page .search-page-container {
    padding-right: 30px;
    padding-left: 30px;
    padding-top: 60px;
    height: 100%; }
  .search-page .searchBarRow {
    justify-content: center; }
    .search-page .searchBarRow .searchBarContainer .fa-question-circle {
      color: rgba(0, 0, 0, 0.2);
      cursor: pointer; }
    .search-page .searchBarRow .searchBarContainer .fa-question-circle:hover {
      color: rgba(0, 0, 0, 0.5); }
    .search-page .searchBarRow .searchBarContainer .searchBar {
      width: 65%;
      border-right: 1px dotted #999;
      height: 50px;
      font-size: 19px;
      font-weight: 500;
      border-top-left-radius: 5px;
      border-bottom-left-radius: 5px;
      margin-left: auto; }
    .search-page .searchBarRow .searchBarContainer .red-border {
      border-color: red !important; }
    .search-page .searchBarRow .searchBarContainer .selector {
      width: 22%;
      height: 50px !important;
      border-left: none;
      font-size: 19px;
      font-weight: 500;
      margin-right: auto;
      border-top-right-radius: 5px;
      border-bottom-right-radius: 5px; }
    .search-page .searchBarRow .searchBarContainer .fa-lg {
      font-size: 1.75em !important; }
    .search-page .searchBarRow .searchBarContainer .btn {
      height: 50px;
      width: 7%;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 5px;
      margin: 0px; }
  .search-page .indicatorsRow {
    align-items: center; }
    .search-page .indicatorsRow .fa-spin {
      font-size: 45px !important;
      margin-top: 30px;
      margin-bottom: 30px; }
    .search-page .indicatorsRow .searchIndicator {
      min-height: 500px;
      width: 100%;
      flex-direction: column;
      display: flex;
      align-items: center;
      justify-content: center; }
      .search-page .indicatorsRow .searchIndicator .fa-lg {
        font-size: 8em !important;
        color: rgba(0, 0, 0, 0.2); }
      .search-page .indicatorsRow .searchIndicator .searchIndicatorText {
        display: block;
        font-size: 20px;
        padding-top: 25px; }
    .search-page .indicatorsRow .notFoundMessage {
      min-height: 500px;
      width: 100%;
      flex-direction: column;
      display: flex;
      align-items: center;
      justify-content: center; }
      .search-page .indicatorsRow .notFoundMessage .fa-close {
        font-size: 8em !important;
        color: rgba(196, 0, 0, 0.6);
        margin-bottom: 25px; }
      .search-page .indicatorsRow .notFoundMessage .searchIndicatorText {
        display: block;
        font-size: 20px; }
  .search-page .resultsRow {
    padding-top: 40px; }
    .search-page .resultsRow .clear-all-container {
      padding-left: 50px;
      display: flex;
      align-items: center;
      font-weight: bold; }
      .search-page .resultsRow .clear-all-container .badge {
        color: white;
        display: flex;
        align-items: center;
        font-family: sans-serif;
        background-color: rgba(0, 0, 0, 0.5);
        font-size: 12px;
        padding: 6px 8px;
        cursor: pointer; }
        .search-page .resultsRow .clear-all-container .badge .closeButton {
          margin-left: 8px; }
    .search-page .resultsRow .resultsPageCol {
      margin: 30px 0px;
      padding: 0px 45px !important; }
      .search-page .resultsRow .resultsPageCol .spinnerPosition {
        margin-top: 25px !important; }
        .search-page .resultsRow .resultsPageCol .spinnerPosition .fa {
          font-size: 30px !important; }
      .search-page .resultsRow .resultsPageCol .expandButton {
        display: inline-flex;
        align-items: center;
        justify-content: center;
        width: 30px;
        height: 30px;
        border-radius: 2px;
        padding: 0.5rem !important;
        font-weight: bold; }
      .search-page .resultsRow .resultsPageCol .ResultsTabTitle {
        font-weight: bold;
        font-size: 16px; }
      .search-page .resultsRow .resultsPageCol .no-results-div {
        display: flex;
        margin-top: 20px;
        margin-left: 40px;
        font-weight: bold; }
      .search-page .resultsRow .resultsPageCol .btn-load-more {
        border-radius: 6px !important;
        margin-left: 80px !important;
        width: 90%;
        display: flex;
        justify-content: flex-start;
        align-items: center; }
        .search-page .resultsRow .resultsPageCol .btn-load-more .fa {
          margin-right: 20px; }
      .search-page .resultsRow .resultsPageCol .badge {
        font-size: 16px;
        margin-left: 30px;
        background-color: #042875; }
      .search-page .resultsRow .resultsPageCol .resultsList {
        margin-left: 40px;
        margin-top: 20px;
        padding-left: 0; }
        .search-page .resultsRow .resultsPageCol .resultsList .resultItem {
          margin-top: 5px;
          display: inline-flex;
          align-items: baseline;
          width: 100%; }
          .search-page .resultsRow .resultsPageCol .resultsList .resultItem .resultItemTitle {
            color: #367598;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            max-width: 50%;
            width: fit-content; }
          .search-page .resultsRow .resultsPageCol .resultsList .resultItem .resultItemMeta {
            font-size: 13px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            max-width: 50%;
            width: fit-content; }

.search-modal-title {
  font-size: 18px;
  font-weight: bold;
  display: inline-flex !important;
  margin-right: auto; }

.search-modal .fa {
  position: absolute;
  right: 15px;
  top: 23px;
  cursor: pointer; }
