.ListContainer {
  margin-top: 0px !important;
  padding: 15px;
  max-width: 480px;
  min-width: 320px;
  padding-right: 0px !important;
  padding-left: 0px !important;
  background-color: #ffffff;
  padding-bottom: 0px; }

.ListContainerSmall {
  margin-top: 0px !important;
  padding-top: 15px;
  padding-bottom: 0;
  padding-right: 0px !important;
  padding-left: 0px !important;
  background-color: #ffffff;
  text-align: center;
  width: 100px;
  height: 100%; }
  .ListContainerSmall .fa-plus {
    margin-left: 0px !important; }

.InfiniteScrollContainer {
  width: 100%;
  height: 100%;
  font-family: Helvetica;
  font-size: 14px;
  color: #000000;
  padding-left: 15px; }
  .InfiniteScrollContainer .questionListContainer {
    height: calc(100% - 40px); }
    .InfiniteScrollContainer .questionListContainer > div {
      overflow: auto !important; }
    .InfiniteScrollContainer .questionListContainer:hover > div {
      overflow-y: auto !important; }
    .InfiniteScrollContainer .questionListContainer .clickable {
      padding-right: 16.7px; }
  .InfiniteScrollContainer .LineThrough {
    text-decoration: line-through; }
  .InfiniteScrollContainer .questionListContainerSmall {
    height: calc(100% - 30px); }
  .InfiniteScrollContainer .questionsTotal {
    padding: 15px;
    display: block;
    height: 60px; }
    .InfiniteScrollContainer .questionsTotal .floatRight {
      float: right; }
    .InfiniteScrollContainer .questionsTotal .fa-plus {
      margin: 0px; }
  .InfiniteScrollContainer p {
    margin-bottom: 0px; }
  .InfiniteScrollContainer .activeQuestion {
    background-color: #f0f3f5;
    font-weight: bold; }

.clickable {
  cursor: pointer;
  padding-top: 15px;
  padding-bottom: 15px;
  padding-left: 15px; }

.goToLabel {
  display: flex;
  align-items: center;
  float: right;
  margin-top: -10px; }
  .goToLabel input[type="checkbox"] {
    margin-left: 5px; }
  .goToLabel input[type="number"]::-webkit-outer-spin-button, .goToLabel input[type="number"]::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0; }
  .goToLabel input[type="number"] {
    -moz-appearance: textfield; }

.questionList {
  display: flex;
  align-items: center;
  justify-content: space-between; }
