.generateReportButton{
    display: flex;
    justify-content: center;
}

.generateReportButton .generate-report {
  margin: 20px auto;
  display: flex;
  align-items: center;
  height: 31px;
  cursor: pointer;
  font-size: 14px;
  color: #0058ff;
  border: solid 1px #0058ff;
  justify-content: center;
  width: 140px;
}

.generateReportButton .generate-report.disabled {
  background-color: lightgrey;
  color: darkgrey;
  border-color: darkgray;
  cursor: not-allowed;
}

.report-title{
    font-size: 1.2rem;
    font-weight: 700;
    margin-bottom: 15px;
}

.cyclesList{
  border: 1px solid black;
  color:black;
}

input[type="checkbox"] {
  width: 20px;
}

