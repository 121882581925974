.update-phone-number-component form {
  margin-top: 10px;
  margin-bottom: 10px; }

.update-phone-number-component .text {
  margin-bottom: 20px;
  line-height: 170%; }

.update-phone-number-component .errors {
  color: red;
  text-align: center; }

.update-phone-number-component .buttons {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 15px;
  margin-top: 15px; }
  .update-phone-number-component .buttons .btn {
    width: 45%;
    height: 40px;
    font-weight: bold;
    margin-left: 0px !important;
    margin-right: 0px !important; }

.update-phone-number-component .label {
  display: inline-block; }

.update-phone-number-component .card-block {
  padding-top: 0px; }
