.ActivityContainer .link {
  color: #366fde;
  cursor: pointer; }

.ActivityContainer .bold {
  font-weight: bold; }

.ActivityContainer .separatorLine {
  margin-top: 9px;
  margin-left: 15px;
  width: 100%; }

.ActivityContainer .paginationContainer {
  margin-top: 9px;
  margin-left: -22px;
  margin-right: -22px;
  display: flex;
  align-items: center;
  align-content: center;
  text-align: center;
  width: 115%;
  height: 64px;
  background-color: #f0f3f5;
  border: solid 1px #c2cfd6; }

.ActivityContainer .cardContentWrapper {
  max-height: calc(100vh - 180px);
  overflow-y: auto !important; }

.detailactivity-row {
  word-break: break-all; }

.closeButton {
  float: right;
  padding: 4px;
  padding-left: 6px;
  padding-right: 6px;
  cursor: pointer;
  border: 1px solid #ecedee; }

.activity-modal .modal-dialog {
  max-width: 900px !important; }

.headerRow {
  margin-left: 5px;
  padding-top: 8px;
  padding-bottom: 8px;
  width: 100%;
  border-top: 1px solid #ecedee; }
