.create-coupon-component .card-block {
  padding: 0px; }
  .create-coupon-component .card-block .containerButton {
    width: 100%;
    display: flex;
    justify-content: center; }
  .create-coupon-component .card-block .buttonCoupon {
    margin-right: 20px;
    cursor: pointer;
    height: 31px;
    background-color: white !important;
    border: none;
    font-size: medium; }
    .create-coupon-component .card-block .buttonCoupon.active {
      border-bottom: 3px solid #0058ff;
      color: #0058ff;
      font-weight: bold; }
    .create-coupon-component .card-block .buttonCoupon:focus {
      outline: 0px !important; }
  .create-coupon-component .card-block .edit-dialog-header {
    padding-top: 10px;
    padding-bottom: 10px;
    border-bottom: 1px solid #d9d9d9;
    margin-bottom: 1rem;
    display: flex;
    justify-content: space-between;
    font-family: Helvetica;
    font-size: 24px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #000000; }
  .create-coupon-component .card-block .componentHeader {
    padding-top: 10px;
    padding-bottom: 10px;
    border-bottom: 1px solid #d9d9d9;
    margin-bottom: 1rem; }
    .create-coupon-component .card-block .componentHeader .createCouponTitle {
      font-family: Helvetica;
      font-size: 24px;
      font-weight: normal;
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: normal;
      color: #000000;
      padding-top: 5px; }
  .create-coupon-component .card-block .createCouponTitle .fa-times {
    font-size: 16px;
    cursor: pointer;
    float: right;
    opacity: 0.5;
    margin-top: 5px; }
  .create-coupon-component .card-block .codes-list {
    height: 22rem;
    overflow: scroll;
    border: 1px solid rgba(0, 0, 0, 0.15);
    margin-bottom: 1rem;
    margin-top: 1rem; }
    .create-coupon-component .card-block .codes-list .code-item {
      padding: 0.25rem;
      margin-left: 0.5rem;
      display: flex; }
      .create-coupon-component .card-block .codes-list .code-item .used-code {
        color: red;
        font-style: italic;
        margin-left: 0.5rem; }
      .create-coupon-component .card-block .codes-list .code-item .used-code-nc {
        color: green;
        font-style: italic;
        margin-left: 0.5rem; }
  .create-coupon-component .card-block .code-uses {
    display: flex;
    justify-content: space-around; }

.componentFooter {
  padding-top: 10px;
  border-top: 1px solid #d9d9d9; }
  .componentFooter .bottom-buttons-new {
    display: flex;
    justify-content: flex-end; }
    .componentFooter .bottom-buttons-new .btn-success {
      color: #fff;
      background-color: #3e7ae2;
      border-color: #3e7ae2; }
    .componentFooter .bottom-buttons-new .btn-secondary {
      color: #263238;
      background-color: #d6dbe1;
      border-color: #d6dbe1; }
    .componentFooter .bottom-buttons-new .fa-trash {
      position: absolute;
      left: 20px;
      bottom: 27px;
      font-size: 20px;
      color: grey; }

.CodeGroupRow {
  margin: 10px; }

.coupon-list-container {
  text-align: center;
  height: 100%;
  align-items: flex-start;
  overflow: auto;
  background-color: #eaecee;
  padding-right: 0px;
  width: 100%; }

.coupon-name-list {
  margin-bottom: 50px;
  height: 100%;
  text-align: start;
  padding-left: 15px; }
  .coupon-name-list .coupon-line {
    cursor: pointer;
    font-size: 93%;
    height: unset;
    padding: 15px;
    cursor: pointer;
    background: #eaecee;
    width: 100%; }
    .coupon-name-list .coupon-line.active {
      font-weight: bold;
      background-color: #ffffff; }

.customers {
  display: flex; }

.customers-container {
  margin-right: 10px;
  font-size: x-small;
  color: #134199;
  padding: 2px; }

.displayFlex {
  display: flex; }

.CodeGroup {
  height: 52px;
  display: flex;
  background-color: #eaecf0;
  justify-content: space-between;
  align-items: center;
  font-size: 13px;
  color: #000000; }

.Flex {
  display: flex;
  text-align: right;
  align-items: center; }

.detailCoupon {
  margin: 1rem;
  margin-left: 0;
  width: 100%;
  background-color: white; }
  .detailCoupon.disable {
    background-color: #f4f5f6; }

.coupon-header {
  width: 100%;
  height: 44px;
  font-size: 1rem;
  padding-bottom: 12px;
  border-bottom: none;
  white-space: nowrap; }

.titleContainer {
  width: 100%;
  background-color: #f0f3f5;
  padding-bottom: 12px;
  padding-top: 12px;
  padding-left: 19px;
  padding-right: 19px;
  border-bottom: none; }

.couponDate {
  word-wrap: break-word;
  display: block; }
  .couponDate.disable {
    background-color: #f4f5f6;
    color: #3333334f; }
  .couponDate .containerTitle {
    margin: 0.5rem; }

.separatorLine {
  height: 1px;
  background-color: #c2cfd6;
  width: 100%; }

.dropContainer {
  margin-right: 10px;
  margin-top: -0.3rem; }
  .dropContainer .positionItem {
    position: relative; }

.plusContainer {
  display: flex;
  align-items: center; }
  .plusContainer .fa-plus {
    margin: 0 !important; }

.CodeGroupTitle {
  font-weight: bold;
  margin-left: 10px; }

.coupons-list .rate-line .description {
  font-weight: bold;
  color: #000000; }

.coupons-list .disable {
  font-weight: bold;
  color: #000000;
  text-decoration: line-through; }

.coupons-list .codes {
  font-weight: normal;
  color: #9e9f9f; }

.coupons-list .flexItemsTitlePlus {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-content: center;
  align-items: center; }

.searchInputContainer {
  min-width: 300px;
  display: flex;
  align-items: center;
  justify-content: flex-start; }

.name {
  font-weight: bold;
  color: #000000; }

.couponContainer {
  margin-left: 5px;
  margin-bottom: 5px;
  width: 100%;
  align-items: center; }
  .couponContainer .searchInput {
    background-color: #FFFFFF;
    height: 40px;
    display: flex;
    margin: 10px 16px 0 0;
    position: relative;
    flex: 1 1 auto; }
    .couponContainer .searchInput input {
      height: 40px;
      padding: 8px;
      border: none;
      background-color: transparent;
      position: relative;
      z-index: 1; }
    .couponContainer .searchInput .fa-search {
      display: flex;
      align-items: center;
      color: #e4e5e6;
      margin-right: 16px;
      margin-top: 0;
      position: absolute;
      height: 100%;
      right: 0;
      z-index: 0; }
    .couponContainer .searchInput .fa-close {
      float: right;
      margin-right: 16px;
      margin-top: 10px;
      margin-left: 2px;
      position: absolute;
      right: 0;
      z-index: 2;
      cursor: pointer; }

.containerCouponDetail {
  height: 100%;
  overflow: auto; }

.marginTop15 {
  margin-top: 15px; }

.inputCheckbox .labelCheck {
  margin-left: 5px; }

.requiredInput {
  color: red;
  font-size: small; }

.textInfo {
  margin-left: 8px;
  margin-top: 10px;
  font-size: small;
  font-style: italic;
  color: red; }

.contentCenter {
  display: flex;
  justify-content: center; }

.cardDetail {
  width: 80%;
  border-radius: 1rem;
  background: #eaecee;
  border: solid 1px #00000026;
  padding: 2rem;
  margin: 2rem; }
  .cardDetail .centerHeader {
    justify-content: space-between; }

.has-subcode {
  font-size: small; }

.centerItems {
  display: flex;
  align-items: center; }

.messageRequired {
  color: red; }

.plans-title {
  font-size: 1rem;
  display: flex;
  justify-content: center;
  padding-top: 1.5rem; }

.coupon-details-container {
  display: flex;
  justify-content: center;
  margin-top: 0.75rem; }
  .coupon-details-container .coupon-details-table {
    width: 80%;
    border: solid 1px #c2cfd6;
    padding: 0.5rem;
    background-color: white;
    margin-bottom: 15px; }

.centerRow {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center; }

.cardsCenter {
  text-align: center; }

.height100 {
  height: 100%;
  overflow: auto; }

.cardCouponBorder {
  border: solid 1px #c2cfd6; }

.newCouponUsed {
  color: green;
  width: 7rem; }

.ExistingCouponUsed {
  color: red;
  width: 7rem; }
