.CheckBoxListItemContainer .label {
  margin: 0;
  padding: 5px;
  cursor: pointer;
  display: flex;
  align-items: center; }
  .CheckBoxListItemContainer .label:hover {
    background-color: #f4f5f6; }
  .CheckBoxListItemContainer .label > div {
    display: inline-block;
    margin-left: 10px; }
