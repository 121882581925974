.practice-exams-component {
  display: flex;
  height: 100%; }
  .practice-exams-component .practice-exams-group-list {
    background-color: #EDEFF2;
    min-width: 400px; }
    .practice-exams-component .practice-exams-group-list .rates-container {
      padding-bottom: 80px;
      height: 90%;
      overflow-y: scroll; }
      .practice-exams-component .practice-exams-group-list .rates-container .group-list {
        margin: 0; }
        .practice-exams-component .practice-exams-group-list .rates-container .group-list .group-row-hover {
          display: flex;
          align-items: center; }
          .practice-exams-component .practice-exams-group-list .rates-container .group-list .group-row-hover .expand-icon-container {
            cursor: pointer;
            font-size: 20px;
            align-items: center;
            padding-left: 10px; }
          .practice-exams-component .practice-exams-group-list .rates-container .group-list .group-row-hover .rate-name {
            width: 100%;
            font-size: 14px;
            cursor: pointer;
            padding: 15px 16.7px 15px 0; }
        .practice-exams-component .practice-exams-group-list .rates-container .group-list .expanded-content-container .rank-row {
          width: 100%; }
          .practice-exams-component .practice-exams-group-list .rates-container .group-list .expanded-content-container .rank-row .active-row {
            background-color: white; }
          .practice-exams-component .practice-exams-group-list .rates-container .group-list .expanded-content-container .rank-row .duty-state-row {
            margin: 0;
            padding: 7px; }
            .practice-exams-component .practice-exams-group-list .rates-container .group-list .expanded-content-container .rank-row .duty-state-row .center-text {
              text-align: center;
              font-weight: bold; }
  .practice-exams-component .practice-exams-content {
    background-color: white;
    height: 100%;
    flex: 1;
    padding: 16px;
    overflow-y: scroll; }
    .practice-exams-component .practice-exams-content .progress-indicator {
      text-align: center;
      font-size: 16px; }
    .practice-exams-component .practice-exams-content .spinnerPosition {
      margin-top: 25% !important;
      font-size: 22px; }
      .practice-exams-component .practice-exams-content .spinnerPosition .fa-spin {
        font-size: 27px; }
    .practice-exams-component .practice-exams-content .top-bar {
      width: 100%;
      display: flex; }
      .practice-exams-component .practice-exams-content .top-bar .cycle-selector {
        width: 250px;
        font-weight: bold; }
      .practice-exams-component .practice-exams-content .top-bar .exam-selector {
        width: 250px;
        font-weight: bold;
        margin-left: 16px; }
      .practice-exams-component .practice-exams-content .top-bar .practice-exams-button {
        margin-left: 16px;
        margin-right: 0;
        width: 130px;
        border-radius: 5px;
        border: none; }
    .practice-exams-component .practice-exams-content .questions-content {
      width: 100%;
      margin-top: 30px; }
      .practice-exams-component .practice-exams-content .questions-content .no-questions-message {
        width: 100%;
        font-size: 18px;
        font-weight: bold; }
      .practice-exams-component .practice-exams-content .questions-content .question-updated {
        color: transparent;
        transition: color 2.5s; }
      .practice-exams-component .practice-exams-content .questions-content .question-updated-visible {
        color: #0562ff; }
      .practice-exams-component .practice-exams-content .questions-content .question-detail {
        padding: 8px;
        border: 1px solid #ECECEC;
        border-radius: 4px;
        margin-bottom: 12px; }
        .practice-exams-component .practice-exams-content .questions-content .question-detail .detail-header {
          width: 100%;
          display: flex;
          align-items: center;
          font-weight: bold; }
          .practice-exams-component .practice-exams-content .questions-content .question-detail .detail-header .fa-trash {
            color: #b50000;
            margin-left: auto; }
        .practice-exams-component .practice-exams-content .questions-content .question-detail .question-text {
          font-size: 16px;
          margin-top: 8px;
          padding-right: 12px; }

.delete-question-modal-body {
  padding: 16px;
  font-size: 16px;
  font-weight: bold; }
  .delete-question-modal-body .modal-buttons {
    display: flex;
    margin-top: 30px;
    align-items: center;
    justify-content: space-between; }
    .delete-question-modal-body .modal-buttons .btn {
      margin: 0;
      width: 40%;
      border: none;
      border-radius: 5px; }
