.UpdateChapterSubtopics {
  padding: 10px 0px; }
  .UpdateChapterSubtopics .btn {
    width: 50%;
    height: 40px;
    font-weight: bold; }
  .UpdateChapterSubtopics .bottom-buttons {
    display: inline-flex;
    float: right; }
  .UpdateChapterSubtopics .select-all-option {
    margin-bottom: 10px;
    margin-left: 5px; }
  .UpdateChapterSubtopics .subtopics-list {
    margin-left: 5px;
    min-height: 300px;
    max-height: 300px;
    overflow-y: auto;
    overflow-x: hidden;
    margin-bottom: 35px; }
  .UpdateChapterSubtopics .TextLink {
    text-align: left;
    margin-left: 5px;
    margin-bottom: 15px; }

.modal-add-subtopic-chapters .modal-body {
  padding-right: 0px; }

.modal-add-subtopic-chapters .card-block {
  padding-right: 0px; }
  .modal-add-subtopic-chapters .card-block .separatorLine {
    margin-top: 0px;
    width: 107%; }

.modal-add-subtopic-chapters .form-update-subtopic-chapters {
  max-height: 400px;
  overflow-y: scroll; }

.modal-add-subtopic-chapters .update-subtopics-component .card-block .new-admin-form .CheckBoxListItemContainer .label {
  font-weight: normal; }

.no-chapters {
  padding-bottom: 50px;
  padding-top: 20px; }
