.confirm-deletion-component .deleteConfirmationForm {
  margin-top: 10px; }

.confirm-deletion-component .btn {
  width: 45%;
  height: 40px;
  font-weight: bold;
  margin-left: 0px !important;
  margin-right: 0px !important; }

.confirm-deletion-component .bottom-buttons {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 15px; }

.confirm-deletion-component .text {
  margin-bottom: 20px;
  line-height: 170%; }

.confirm-deletion-component .errors {
  color: red;
  text-align: center; }

.confirm-deletion-component .containerQuestions {
  max-height: 200px;
  overflow: auto;
  padding-right: 15px; }
