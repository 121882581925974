.settingsPageContainer {
  height: calc(100% - 24px);
  overflow-y: scroll;
  overflow-x: hidden;
  margin-left: 17px; }
  .settingsPageContainer .title {
    font-weight: bold;
    font-size: 25px;
    margin-top: 10px; }
  .settingsPageContainer .form {
    margin-top: 10px; }
  .settingsPageContainer .key {
    font-weight: bold; }
  .settingsPageContainer .btn-success {
    width: 128.8px;
    display: block; }
  .settingsPageContainer .keysContainer {
    margin-top: 20px; }
  .settingsPageContainer .errors {
    color: red; }
  .settingsPageContainer .settingRowContainer {
    margin-top: 10px; }
