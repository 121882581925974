.AnalyticsContainer .BackArrow {
  display: flex;
  align-items: center;
  font-size: 14px;
  font-weight: bold;
  cursor: pointer; }
  .AnalyticsContainer .BackArrow .fa-arrow-left {
    margin: 10px; }

.AnalyticsContainer .Title {
  font-size: 14px;
  font-weight: bold;
  padding-top: 25px;
  padding-left: 10px;
  padding-bottom: 25px; }

.AnalyticsContainer .TitleBig {
  font-size: 24px;
  font-weight: bold; }

.AnalyticsContainer .row {
  margin: 0px; }

.AnalyticsContainer .font20 {
  font-size: 20px; }

.AnalyticsContainer .TabRow {
  display: flex;
  margin-top: 30px;
  justify-content: space-between;
  padding: 0px 10px; }
  .AnalyticsContainer .TabRow .Tab {
    cursor: pointer;
    width: 50px;
    height: 40px;
    font-size: 14px;
    text-align: center;
    color: #0058ff;
    width: 130px;
    display: flex;
    align-items: center;
    justify-content: center; }
  .AnalyticsContainer .TabRow .Active {
    background-color: #23a127;
    font-weight: bold;
    color: #ffffff; }

.AnalyticsContainer .DateRow {
  align-items: center;
  justify-content: flex-start; }
  .AnalyticsContainer .DateRow .Tab {
    margin-left: 15px; }
