.historyContainer .card-block {
  margin-bottom: 10px; }

.historyContainer .text {
  margin-bottom: 20px;
  line-height: 170%;
  word-wrap: break-word; }

.historyContainer .containerStatusActive {
  padding: 5px;
  max-width: 50px;
  color: white;
  font-weight: bold;
  border-radius: 5px;
  background-color: #3ee63e;
  text-align: center; }

.historyContainer .containerStatusInactive {
  padding: 5px;
  max-width: 60px;
  color: white;
  font-weight: bold;
  border-radius: 5px;
  background-color: red;
  text-align: center; }

.historyContainer .tableContainer {
  overflow-y: auto;
  margin-top: 5px;
  max-height: 300px; }
  .historyContainer .tableContainer.short {
    max-height: 200px; }
  .historyContainer .tableContainer .headTable {
    position: sticky;
    top: 0px;
    background-color: white;
    z-index: 10; }
