.selectContainer {
  z-index: 5 !important; }
  .selectContainer .placeHolderButtons {
    width: 100%; }
  .selectContainer .placeHolder {
    white-space: nowrap;
    width: 100%;
    display: block;
    overflow: hidden;
    text-overflow: ellipsis;
    letter-spacing: 0.4px;
    text-align: left;
    color: #707070;
    background-color: #ffffff;
    border: 1px solid #c2cfd6;
    height: 33px;
    padding-left: 12px;
    padding-top: 3px; }
  .selectContainer .placeHolder {
    width: 80%; }
  .selectContainer .crossSelect {
    display: inline-block;
    width: 15%;
    margin-left: 5px; }
  .selectContainer .check {
    display: flex;
    justify-content: center;
    align-items: center;
    align-self: center;
    width: 33px;
    height: 33px;
    background-color: #366fde; }
  .selectContainer .cross {
    display: flex;
    justify-content: center;
    align-items: center;
    align-self: center;
    width: 33px;
    height: 33px;
    background-color: #ffffff;
    border: 1px solid #dedede; }
  .selectContainer .fa-check {
    color: #ffffff; }
  .selectContainer .redBorder {
    border: 1px solid red; }
  .selectContainer .placeHolderButtons {
    display: flex;
    align-content: center;
    align-items: center;
    flex-direction: row; }
  .selectContainer .option {
    white-space: nowrap;
    width: 100%;
    display: block;
    overflow: hidden;
    text-overflow: ellipsis;
    padding-top: 9px;
    cursor: pointer;
    height: 42px;
    border: 1px solid #dedede;
    padding-left: 12px;
    background-color: #fff; }
  .selectContainer .active {
    background-color: #366fde !important;
    font-weight: bold;
    letter-spacing: 0.4px;
    text-align: left;
    color: #ffffff; }
  .selectContainer .optionsContainer {
    margin-bottom: 10px;
    overflow: auto;
    max-height: 266px; }
  .selectContainer .selectGrow {
    flex-grow: 1; }
