.assignemnt-comments-component {
  width: 100%;
  height: 700px;
  overflow-y: auto;
  overflow-x: hidden; }
  .assignemnt-comments-component .headerRow {
    border-top: 0 !important;
    font-weight: bold; }
  .assignemnt-comments-component .row {
    border-top: 1px solid #ecedee;
    padding-top: 8px;
    padding-bottom: 8px; }
  .assignemnt-comments-component .rowStriped {
    background-color: #edf2f5; }
  .assignemnt-comments-component .fa-trash, .assignemnt-comments-component .fa-plus, .assignemnt-comments-component .fa-trash, .assignemnt-comments-component .fa-times, .assignemnt-comments-component .fa-check {
    margin-right: 8px;
    cursor: pointer; }
  .assignemnt-comments-component .form-group, .assignemnt-comments-component .mb-3 {
    margin-bottom: 0px !important; }
  .assignemnt-comments-component .editableButtons {
    display: flex;
    align-items: center; }
  .assignemnt-comments-component .closeButton {
    font-size: 30px;
    line-height: 1px;
    text-align: right;
    cursor: pointer;
    color: gray; }
  .assignemnt-comments-component .textRight {
    text-align: right; }
  .assignemnt-comments-component .errors {
    color: red;
    text-align: center;
    position: relative; }
  .assignemnt-comments-component .change-text {
    margin-left: 5px; }
  .assignemnt-comments-component .title {
    font-weight: bold; }
  .assignemnt-comments-component .bold {
    font-weight: bold;
    padding-right: 2px;
    padding-left: 2px; }
  .assignemnt-comments-component .value {
    margin-left: 2px; }
  .assignemnt-comments-component .closeButton {
    margin-top: 5px;
    float: right; }

.noRecords {
  font-weight: bold;
  text-align: center; }

.textRight {
  text-align: right; }
