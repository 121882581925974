.promptContainer {
  margin-right: 0px;
  margin-left: 0px;
  height: 100%; }
  .promptContainer .promptListContainer {
    min-width: 400px;
    overflow: scroll;
    height: 100%;
    overflow-x: hidden;
    height: 100%;
    background-color: #EAECEE;
    padding-left: 0px;
    padding-right: 0px; }
