.profileSheetContainer{
    margin-top: 20px;
   
}

.profileSheetContainer  .cardContentWrapper{
    height: 200px;
   }

.profileSheetText {
    text-align: center;
    width: 100%;
    margin-bottom: 1rem;
}

.profileSheetCycles{
    width: 100%;
    display: flex;
    flex-direction: row;
    padding-left: 40%;
    padding-top: 1rem;
}

.profileSheetSpan {
    text-align: center;
    font-weight: bold; }

.disableSpan{
    text-align: center;
    font-weight: bold;
    font-size: small;
    width: 80px;
}
.listCycle{
    align-items: center;
}

.cyclesDescription{
  display: flex;
  flex-direction: column;
  text-align: center;
}
.cyclesDescription .fontCycleDeleteByUser{
    color: red;
    font-weight: bold;
}

.cyclesDescription .fontCycle{
    color: #20a9de;
    font-weight: bold;
}

.cyclesDescription .fontTitle{
    font-weight: bold;
    font-size: xx-small;
}
.checkStyle{
    display: flex;
    justify-content: center;
    text-align: center;
   

}
.checkStyle  .checkStyleInput{
    margin-right: 4rem !important;

}


.input-group  .form-checkbox {
    margin-top: 6px;
    margin-left: 10px;
    height: 20px;
    width: 20px;
    }

 

.form-group {
    margin-bottom: 0rem;
}

.table th, .table td {
    border-top: 1px solid #cfd8dc;
    padding-left: 1rem;
    vertical-align: middle;
}