.draft-component {
  width: 100%;
  height: 100%;
  overflow: hidden;
  border: 1px solid rgba(0, 0, 0, 0.15); }
  .draft-component .public-DraftStyleDefault-block {
    margin: 0 !important; }
  .draft-component .editor-wrapper {
    width: 100%;
    height: 100%;
    border: 1px solid rgba(0, 0, 0, 0.15); }
  .draft-component .editor-class {
    padding-left: 5px !important;
    border-radius: 2px !important;
    overflow: auto;
    box-sizing: border-box; }
  .draft-component .disabled {
    background-color: #e4e5e6 !important;
    text-decoration: line-through; }
  .draft-component .editor-toolbar {
    margin-bottom: 0px !important; }
    .draft-component .editor-toolbar .rdw-inline-dropdown {
      width: 35px !important; }
    .draft-component .editor-toolbar .rdw-list-dropdown {
      width: 35px !important;
      z-index: 2 !important; }
    .draft-component .editor-toolbar .rdw-link-dropdown {
      width: 35px !important; }
    .draft-component .editor-toolbar .rdw-text-align-dropdown {
      width: 35px !important;
      z-index: 2 !important; }

.redBorderEditor {
  border: 1px solid red !important; }

.react-resizable-handle {
  z-index: 10; }

.rdw-image-imagewrapper img {
  max-width: 500px;
  max-height: 500px; }
