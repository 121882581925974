.cycles-list-component .cycles-title {
  margin: 10px;
  font-size: 120%; }
  .cycles-list-component .cycles-title .fa-plus {
    float: right;
    cursor: pointer; }

.cycles-list-component .cycles-line {
  cursor: pointer;
  font-size: 93%; }
  .cycles-list-component .cycles-line.active {
    font-weight: bold; }

.cycles-list-component .cycles-list {
  text-align: left;
  margin-top: 10px; }
  @media screen and (max-width: 767px) {
    .cycles-list-component .cycles-list {
      display: flex;
      justify-content: space-between; } }
  .cycles-list-component .cycles-list .cycleLineHover:hover {
    background-color: #f4f5f6; }

.cycles-list-component .fa-check {
  margin-top: 2.4px;
  float: right; }
