.rates-component {
  height: 100%;
  align-items: flex-start;
  margin-right: 0px;
  margin-left: 0px; }
  @media screen and (max-width: 767px) {
    .rates-component {
      width: auto;
      flex-direction: column;
      height: initial; } }
  .rates-component .rates-content-container {
    padding-left: 0px;
    flex: 1 1;
    height: calc(100vh - 80px);
    overflow: auto; }
    @media screen and (max-width: 767px) {
      .rates-component .rates-content-container {
        width: 100%; } }
  .rates-component .icon-button {
    cursor: pointer;
    margin: 0 3px; }
    .rates-component .icon-button:last-of-type {
      margin-right: 0; }
    @media screen and (max-width: 767px) {
      .rates-component .icon-button {
        margin: 0 15px; } }
  .rates-component .nav .active {
    font-weight: bold; }
  .rates-component .rates-list-container {
    padding-right: 0px !important;
    padding-left: 0px !important;
    text-align: left !important;
    overflow-y: hidden !important;
    height: 100vh; }
    @media screen and (max-width: 767px) {
      .rates-component .rates-list-container {
        height: auto;
        width: 100%;
        max-width: none; } }

.subtopicsPage .searchInput {
  background-color: #FFFFFF;
  height: 40px;
  display: flex;
  margin: 10px 16px 0 0;
  position: relative;
  flex: 1 1 auto; }
  .subtopicsPage .searchInput input {
    padding: 8px;
    border: none;
    height: 40px;
    background: transparent;
    position: relative;
    z-index: 1; }
  .subtopicsPage .searchInput .fa-search {
    display: flex;
    align-items: center;
    color: #e4e5e6;
    margin-right: 16px;
    margin-top: 0;
    position: absolute;
    height: 100%;
    right: 0;
    z-index: 0; }
  .subtopicsPage .searchInput .fa-close {
    float: right;
    margin-right: 16px;
    margin-top: 0;
    margin-left: 2px;
    position: absolute;
    right: 0;
    z-index: 2;
    cursor: pointer; }

.subtopics-spinner {
  position: absolute;
  top: 25%;
  left: 45%; }
