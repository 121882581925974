.bannerContainer {
  margin-right: 0px;
  margin-left: 0px;
  height: 100%; }
  .bannerContainer .bannerListContainer {
    overflow: scroll;
    height: 100%;
    overflow-x: hidden;
    height: 100%;
    background-color: #EAECEE; }
