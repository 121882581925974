.modal-summary-container {
  padding: 0px !important;
  width: 100%;
  height: 700px;
  .card-block {
    padding: 0px; }
  .title-container {
   font-family: Helvetica;
   font-size: 24px;
   font-weight: normal;
   font-style: normal;
   font-stretch: normal;
   line-height: normal;
   letter-spacing: normal;
   color: #000000;
   display: flex;
   flex-direction: row;
   justify-content: space-between;
   align-items: center;
   .fa-times {
     font-size: 16px;
     cursor: pointer;
     float: right;
     opacity: 0.5; } } }
.hrModal {
  margin-left: -15px;
  width: 60rem; }
.customPromptContainer {
  max-height: 580px;
  overflow-y: auto;
  overflow-x: hidden;
  .summaryText {
    text-align: justify;
    white-space: pre-wrap; }
  .substitle {
    font-weight: bold; } }
