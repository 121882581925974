.questions-component {
  width: 100%;
  margin: 0 auto;
  margin-top: 30px;
  margin-bottom: 10px;
  border: 1px solid #cfd8dc;
  position: relative;
  padding: 10px; }
  .questions-component .gridTitle {
    margin-top: -22px;
    margin-left: 20px;
    z-index: 10;
    position: absolute;
    background-color: white;
    padding: 0px 10px 0px 10px;
    font-weight: bold; }
  .questions-component .gridContainer {
    margin: 0 !important;
    margin-top: 30px !important; }
  .questions-component .nav {
    margin-top: 30px; }
  .questions-component .nav-item {
    cursor: pointer; }
  .questions-component .errors {
    color: red;
    text-align: center;
    position: relative; }
  .questions-component .orderDropDown {
    display: inline-block;
    margin-left: 12px; }
  .questions-component .marginLeft5 {
    margin-left: 5px; }
  .questions-component .input-position {
    display: inline-block; }
  .questions-component .inputFileQuestion {
    width: 110px;
    margin: 0 auto; }
  .questions-component .fa-trash, .questions-component .fa-history {
    margin-left: 0px !important;
    margin-bottom: 5px !important; }
  .questions-component .fa-history {
    margin-right: 2px; }
  .questions-component .middleIcon {
    display: inline-flex !important;
    margin-left: 5px !important;
    margin-right: 5px !important; }
  .questions-component .icon-directions {
    margin-top: 5px; }
  .questions-component .pdf-upload input {
    display: none; }
  .questions-component .subtopicsContainer {
    margin-top: 10px;
    margin-left: 20px; }
  .questions-component .subtopicsTitle {
    margin-right: 15px;
    display: inline-flex;
    font-weight: bold; }
  .questions-component .fa-pencil {
    display: inline-block; }
  .questions-component .subtopicsListContainer {
    margin-top: 5px; }
  .questions-component .fa-file-pdf-o {
    margin-right: 5px; }
  .questions-component .cellContainer, .questions-component .cellContainerUndoDelete {
    -webkit-box-flex: 1;
    -ms-flex: 1 0 0px;
    flex: 1 0 0;
    white-space: nowrap;
    text-overflow: ellipsis;
    padding: 7px 5px;
    overflow: hidden;
    transition: .3s ease;
    transition-property: width,min-width,padding,opacity;
    text-align: center; }
  .questions-component .content {
    overflow: hidden;
    text-overflow: ellipsis;
    margin-right: 5px; }
  .questions-component .editModeContainer, .questions-component .cellContainer {
    display: flex !important;
    align-items: center !important;
    justify-content: center !important; }
  .questions-component .editModeInput {
    margin-right: 5px;
    height: 40px; }
  .questions-component .marginTop5 {
    margin-top: 5px !important; }
  .questions-component .furtherReadingCell {
    margin: 0 auto;
    margin-bottom: 5px; }
  .questions-component .correctCell {
    margin: 0 auto; }
  .questions-component .statusContainer {
    float: right; }
  .questions-component .statusSelect {
    width: 170px; }
  .questions-component .cellContainerUndoDelete {
    text-decoration: normal !important; }

.pointer {
  cursor: pointer; }

.red {
  color: red; }

.centerSpinner {
  display: flex;
  height: 70%;
  align-items: center; }
