.btn-sm {
  cursor: pointer; }

.bibliographies-component {
  height: 100vh;
  overflow-y: scroll;
  margin-right: 0px;
  justify-content: center;
  align-items: flex-start; }
  @media screen and (max-width: 767px) {
    .bibliographies-component {
      width: auto;
      flex-direction: column;
      height: initial; } }
  .bibliographies-component .rates-content-container {
    padding-left: 0px;
    flex: 1 1; }
    @media screen and (max-width: 767px) {
      .bibliographies-component .rates-content-container {
        width: 100%; } }
  .bibliographies-component .icon-button {
    cursor: pointer;
    margin: 0 7px; }
    .bibliographies-component .icon-button:last-of-type {
      margin-right: 0; }
    @media screen and (max-width: 767px) {
      .bibliographies-component .icon-button {
        margin: 0 15px; } }
  .bibliographies-component .nav .active {
    font-weight: bold; }
  .bibliographies-component .rates-list-container {
    max-width: 250px;
    text-align: center;
    height: 100%;
    overflow: auto; }
    @media screen and (max-width: 767px) {
      .bibliographies-component .rates-list-container {
        height: auto;
        width: 100%;
        max-width: none; } }
  .bibliographies-component .bibliographies-secondHeader {
    background-color: #d3d3d3; }
  .bibliographies-component .selectContainer {
    margin-left: 2%;
    margin-top: 20px; }
  .bibliographies-component .listsContainer {
    margin-top: 30px;
    margin-left: 2%; }
  .bibliographies-component .buttonsContainer {
    padding-left: 15px;
    margin-top: 20px; }
  .bibliographies-component .add {
    margin-right: 10px; }
  .bibliographies-component .select-all-option {
    margin-top: 20px; }
  .bibliographies-component .subtopics-list {
    width: 100%;
    margin-top: 10px; }
  .bibliographies-component .questions-qty {
    font-weight: bold;
    font-variant: small-caps;
    margin-left: 10px; }
  .bibliographies-component .checkbox-inline {
    margin-left: 5px; }

.bibliography-container {
  display: flex;
  height: 100%; }
  .bibliography-container .ratesContainer {
    padding-left: 0px !important;
    background-color: #edeff2; }
    .bibliography-container .ratesContainer .cycles-list-component .cycleLineHover {
      margin: 0px; }
      .bibliography-container .ratesContainer .cycles-list-component .cycleLineHover .cycle-row {
        padding: 15px; }
    .bibliography-container .ratesContainer .Group {
      margin: 0px; }
  .bibliography-container .assignmentsStatusContainer .fa-plus {
    float: right;
    cursor: pointer;
    padding-top: 6px;
    margin: 0 5px 0 auto;
    color: #95989A; }
  .bibliography-container .statusHeaderContainer {
    margin-top: 0px;
    padding-top: 10px;
    padding-left: 0px; }
    .bibliography-container .statusHeaderContainer .searchInput {
      width: 195px;
      margin-left: 20px; }
      .bibliography-container .statusHeaderContainer .searchInput input {
        border: none; }
      .bibliography-container .statusHeaderContainer .searchInput .fa-search {
        float: right;
        margin-right: 16px;
        margin-top: -24px;
        position: relative;
        z-index: 2; }
      .bibliography-container .statusHeaderContainer .searchInput .fa-close {
        float: right;
        margin-right: 40px;
        margin-top: -24px;
        position: relative;
        z-index: 2; }
  .bibliography-container .selectContainerBibliographies {
    width: 123px;
    height: 31px;
    object-fit: contain;
    border: solid 1px #a4b7c1;
    background: white;
    font-size: 14px;
    color: #000000;
    border-radius: 0 !important; }
  .bibliography-container .ratingExamButtons {
    width: 123px;
    display: flex;
    align-items: center;
    height: 31px;
    cursor: pointer;
    font-size: 14px;
    color: #0058ff;
    border: solid 1px #0058ff;
    justify-content: center;
    margin-right: 15px; }
  .bibliography-container .ratingExamButtonsBlue {
    width: 123px;
    display: flex;
    align-items: center;
    height: 31px;
    cursor: pointer;
    font-size: 14px;
    color: #ffffff;
    background-color: #0058ff;
    border: solid 1px #0058ff;
    margin-right: 15px;
    justify-content: center; }
  .bibliography-container .ratingButtonsContainer {
    display: flex;
    padding: 15px; }
    .bibliography-container .ratingButtonsContainer .dotsContainer {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-left: 15px; }
      .bibliography-container .ratingButtonsContainer .dotsContainer .threeDotsMenuContainer {
        margin-top: 0px; }
  .bibliography-container .RateName {
    width: 100%;
    font-size: 14px;
    cursor: pointer;
    font-weight: normal;
    padding-top: 15px;
    padding-bottom: 15px;
    display: flex; }
    .bibliography-container .RateName .percentage {
      margin-left: auto;
      display: inline-block;
      min-width: 6.8rem; }
    .bibliography-container .RateName .rateNameLabel {
      margin-right: 10px; }
  .bibliography-container .expandIconContainer {
    padding-left: 10px;
    margin-left: unset !important;
    margin-right: 0px !important; }
  .bibliography-container .expandedContentContainer .centerText {
    text-align: center; }
  .bibliography-container .expandedContentContainer .row {
    padding: 7px; }
  .bibliography-container .expandedContentContainer .row:hover {
    background-color: #f4f5f6; }
  .bibliography-container .expandedContentContainer .activeRow {
    background-color: white; }
  .bibliography-container .expandedContentContainer .pointer {
    cursor: pointer; }
  .bibliography-container .expandedContentContainer .linked {
    background-color: #d1d1d1;
    cursor: default; }
  .bibliography-container .expandedContentContainer .linked:hover {
    background-color: #d1d1d1; }
  .bibliography-container .subtopics-list {
    width: 100%;
    margin-top: 10px;
    margin-bottom: 100px; }
  .bibliography-container .subtopicTitle {
    font-weight: bold; }
    .bibliography-container .subtopicTitle label {
      padding: 15px;
      display: block;
      cursor: pointer; }
      .bibliography-container .subtopicTitle label:hover {
        background-color: #f4f5f6; }
  .bibliography-container .select-all-option {
    margin-top: 20px; }
    .bibliography-container .select-all-option label {
      display: block;
      cursor: pointer;
      padding: 15px; }
      .bibliography-container .select-all-option label:hover {
        background-color: #f4f5f6; }
  .bibliography-container .bibliography-linked {
    padding-right: 40px;
    font-size: 15px; }
  .bibliography-container .deselectedCounter {
    padding: 0 0 6px 15px;
    border-bottom: 1px solid #c4c4c4;
    font-weight: bold; }
