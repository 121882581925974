.userProfileContainer .app-header {
  width: 110%;
  position: static !important;
  padding-left: 20px; }

.userProfileContainer .informationContainer {
  float: center;
  margin-top: 40px;
  margin-left: 30px; }

.userProfileContainer .profileInfoContainer, .userProfileContainer .activityInfoContainer {
  border: solid 2px #cfd8dc; }

.userProfileContainer .profileInfoContainer {
  padding: 0px 15px 15px 30px; }

.userProfileContainer .activityInfoContainer {
  padding: 30px 0px 30px 30px; }

.userProfileContainer .boxTitleProfile {
  margin-top: -12px;
  margin-bottom: 5px; }

.userProfileContainer .boxTitle, .userProfileContainer .boxTitleProfile {
  width: 60px;
  padding-left: 8px;
  background-color: white; }

.userProfileContainer .boxTitle {
  margin-top: -42px;
  margin-bottom: 20px; }

.userProfileContainer .changeExam {
  margin-top: 20px;
  cursor: pointer; }

.userProfileContainer .deleteUser {
  display: block;
  margin-right: 50px;
  margin-top: 20px;
  cursor: pointer; }

.userProfileContainer .disableUser {
  display: block;
  margin-right: 50px;
  cursor: pointer; }

.userProfileContainer .resetPwd {
  cursor: pointer; }

.userProfileContainer .space {
  margin-top: 20px; }

.userProfileContainer .adminSelect {
  width: 155px;
  height: 31px; }

.userProfileContainer .buttonsContainer {
  clear: both;
  margin-top: 20px; }

.userProfileContainer .navbar {
  justify-content: flex-start; }

.userProfileContainer .profileTitle {
  margin-left: 10px;
  font-weight: bold; }

.userProfileContainer .subtopics-list-title {
  margin-top: 10px;
  margin-bottom: 10px; }

.userProfileContainer .subtopics-list {
  padding: 10px; }

.userProfileContainer .changeStripeIdBtn {
  margin-top: 20px; }

.userCardsContainer {
  width: 100%;
  margin-bottom: 10px; }
  .userCardsContainer .threeDotsMenuContainer, .userCardsContainer .threeDotsMenuContainer2 {
    margin-top: -25px;
    position: absolute;
    z-index: 5;
    right: 0px; }

[title~="Disabled"] {
  color: #f86c6b; }

[title~="Enabled"] {
  color: #4dbd74; }

.profileImageContainer {
  display: inline-block;
  position: relative;
  width: 99px;
  height: 99px;
  overflow: hidden;
  border-radius: 50%; }
  .profileImageContainer .profileImage {
    width: auto;
    height: 100%;
    margin-bottom: 10px; }

.photoCol {
  display: flex;
  align-items: center;
  padding-right: 0px;
  padding-right: 0px; }
  .photoCol .valueName {
    margin-left: 0px;
    margin-bottom: 0px; }

.photoColPen {
  margin-bottom: 0px;
  padding-left: 41px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 0px; }
  .photoColPen .photoValueName {
    font-size: 14px;
    margin-left: 0px; }

.cardRowContainer .input-group-select {
  display: inline-block;
  width: 80%; }

.cardRowContainer .crossSelect {
  display: inline-block;
  width: 15%;
  margin-left: 5px; }

.DropzoneContainer {
  display: flex;
  align-items: center; }
  .DropzoneContainer p {
    font-family: Helvetica;
    font-size: 14px;
    color: #0565e4;
    cursor: pointer;
    margin-bottom: 0px;
    margin-left: 5px; }
  .DropzoneContainer .Dropzone {
    background: linear-gradient(135deg, white 25%, #f2f7fd 25%, #f2f7fd 50%, white 50%, white 75%, #f2f7fd 75%);
    background-size: 10px 10px;
    width: 139px;
    height: 37px;
    background-color: #ffffff;
    border: dashed 1px #c2cfd6;
    border-radius: 200px;
    display: flex;
    align-items: center;
    justify-content: center; }
    .DropzoneContainer .Dropzone p {
      margin-bottom: 0px;
      font-size: 14px;
      font-family: Helvetica;
      color: black; }

.fileName {
  font-family: Helvetica;
  font-size: 14px;
  font-weight: bold;
  color: #0565e4;
  cursor: pointer; }

.warning-tooltip-change-password {
  margin-left: 20px; }
