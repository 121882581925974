.spinnerPosition {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  text-align: center;
  margin: auto !important;
  color: #204293; }
  .spinnerPosition .fa {
    padding: 5px;
    font-size: 24px; }
