.ScrollContainer {
  overflow-y: hidden !important;
  width: 100rem; }

.ScrollContainer:hover {
  overflow-y: auto !important; }

.QuestionDetailsContainer {
  height: 100%;
  overflow-y: auto !important;
  overflow-x: hidden !important;
  padding-right: 20px;
  width: 77%;
  flex: 1; }
  .QuestionDetailsContainer .form-control:disabled {
    text-decoration: line-through; }
  .QuestionDetailsContainer .ImageRow {
    display: flex;
    padding-left: 30px;
    align-items: center; }
  .QuestionDetailsContainer .aiSuggestion {
    margin: 15px 0 0 39px;
    color: #df24da; }
    .QuestionDetailsContainer .aiSuggestion span {
      margin-left: 5px; }

.QuestionTextAreaContainer {
  display: flex;
  align-items: center;
  padding: 10px 15px 0px 15px; }
  .QuestionTextAreaContainer .text-holder {
    padding: 0.5rem 1rem;
    min-height: 30px; }
  .QuestionTextAreaContainer .centeredTextLink {
    display: inline-flex;
    margin-left: 30%; }

.SpanLink {
  color: #366fde;
  text-decoration: underline;
  cursor: pointer; }

.SmallInput {
  margin-left: 15px;
  width: 65px;
  text-align: center;
  padding-right: 5px;
  padding-left: 5px; }

.TextArea {
  height: 85px;
  margin-top: 10px; }

.QuestionTextArea {
  height: 35px;
  margin-top: 10px; }

.BorderTextArea {
  border-radius: 7px; }

.QuestionTextAreaCheckbox {
  margin-botom: 0px; }
  .QuestionTextAreaCheckbox input[type=radio] {
    margin-right: 5px; }

.QuestionTextAreaSelect {
  margin-top: 0px; }

.TextCenter {
  text-align: center; }

.CenterFlex {
  display: flex;
  align-items: center;
  justify-content: center; }

.MarginTop30 {
  margin-top: 30px; }

.Padding20 {
  padding: 20px; }

.MarginTop10 {
  padding: 10px; }

.MarginRight15 {
  margin-right: 15px; }

.MarginTop25 {
  margin-top: 25px; }

.MarginTop70 {
  margin-top: 70px; }

.colCentered {
  float: left;
  margin: 0 auto; }

.bigTextArea {
  height: 90%;
  margin: 0 auto;
  width: 80%; }

.btn {
  margin-left: 10px; }

.Backbutton {
  float: left; }

.Question-list {
  max-width: 250px;
  text-align: center;
  align-items: flex-start;
  overflow: auto;
  background-color: #ECEFF1; }
  @media screen and (max-width: 767px) {
    .Question-list {
      height: auto;
      width: 100%;
      max-width: none; } }

.DisableModeButton {
  float: right; }

.HeaderText {
  margin: 0;
  height: 35px;
  display: flex;
  align-items: center;
  justify-content: center; }

.TextLink {
  margin-left: 10px;
  margin-right: 10px; }

.draft-component {
  background: white; }

.redBorder {
  border-color: red; }

.tree-options .threeDotsMenuContainer {
  margin-top: 0;
  padding: 5px 10px;
  z-index: 6; }
  .tree-options .threeDotsMenuContainer .box {
    margin-left: -185px; }

.tree-options .threeDotsMenuContainer2 {
  margin-top: 10px;
  z-index: 7; }
  .tree-options .threeDotsMenuContainer2 .box {
    margin-left: -185px;
    position: absolute;
    right: -2px;
    top: -11px; }

.rdw-image-modal-upload-option-label {
  font-size: 12px;
  word-break: break-all;
  white-space: normal; }

.threeDotsMenuContainer, .threeDotsMenuContainer2 {
  margin-top: 0.2rem; }

.flexEnd {
  justify-content: flex-end;
  display: flex;
  align-items: center; }
  .flexEnd input[type='number'] {
    -moz-appearance: textfield; }
  .flexEnd input::-webkit-outer-spin-button,
  .flexEnd input::-webkit-inner-spin-button {
    -webkit-appearance: none; }

.dotsRow {
  display: flex;
  padding-right: 25px;
  height: 15px;
  justify-content: flex-end; }

.CorrectOption {
  border: solid 1px #9ac6a6; }

.MisspelledWords {
  color: grey; }

.width100 {
  width: 100% !important; }

.redText {
  color: red; }

.redTextQuestion {
  color: red;
  padding: 0px 15px 15px 15px; }

.editor-hide .fr-box .fr-toolbar {
  display: none; }

.editor-hide .fr-box .fr-wrapper {
  border-top: 1px solid lightgray !important; }

.full-editor-125 .ql-container.ql-snow {
  min-height: 125px;
  border-radius: 0;
  border-bottom: 0; }
  .full-editor-125 .ql-container.ql-snow .ql-editor {
    min-height: 125px; }

.full-editor-350 .ql-container.ql-snow {
  min-height: 350px;
  border-radius: 0;
  border-bottom: 0; }
  .full-editor-350 .ql-container.ql-snow .ql-editor {
    min-height: 350px; }

.counter {
  width: 100%;
  padding: 10px;
  color: gray;
  text-align: right;
  border-radius: 0 0 10px 10px;
  background-color: white;
  border: 1px solid lightgray !important; }

.ql-toolbar.ql-snow {
  border-radius: 10px 10px 0 0;
  background-color: white;
  padding: 8px; }

.ql-container.ql-snow {
  border-radius: 0 0 10px 10px;
  background-color: white;
  padding: 8px;
  font-size: 14px; }

.editor-hide .ql-toolbar {
  display: none; }

.editor-hide .ql-container {
  border-radius: 10px;
  border-top: 1px solid lightgray !important; }

.more-options-btn {
  padding-left: 0.25rem;
  padding-right: 0.25rem;
  padding-top: 0.10rem;
  padding-bottom: 0.10rem;
  border-radius: 4px;
  float: right;
  font-size: 20px;
  color: #607d8b; }

.more-options-btn-question {
  margin-right: 15px; }

.more-options-btn:hover {
  cursor: pointer;
  color: black; }

.disabledButton {
  opacity: 0.5;
  color: black;
  background: linear-gradient(90deg, #df24da 50%, #f689f9 100%, #d758dd 100%);
  padding: 2px 13px 2px 13px;
  margin: 0 0 5px 0;
  border-radius: 5px;
  font-size: 18px; }

.suggestion-btn {
  font-size: 18px;
  color: #f0f3f5;
  background: linear-gradient(90deg, #df24da 50%, #f689f9 100%, #d758dd 100%);
  padding: 2px 13px 2px 13px;
  margin: 0 0 5px 0;
  border-radius: 5px; }

.suggestion-btn:hover {
  color: black; }

.include-further-reading-checkbox {
  font-size: 0.8rem;
  cursor: pointer; }
  .include-further-reading-checkbox label {
    display: flex;
    align-items: center;
    gap: 6px;
    padding-top: 0;
    margin-bottom: 0;
    cursor: inherit;
    user-select: none;
    max-width: 160px; }
  .include-further-reading-checkbox input {
    cursor: inherit;
    margin-left: 6px; }

.question-area-select {
  margin-bottom: 2px; }

.titleOriginalResponseContainer {
  background-color: #9f8fef;
  display: flex;
  flex-direction: row;
  padding: 5px; }
  .titleOriginalResponseContainer .title {
    font-weight: 600;
    color: #fff; }
  .titleOriginalResponseContainer .tree-view_arrow {
    transition: transform 1s linear;
    transform: rotate(270deg);
    color: #fff; }
  .titleOriginalResponseContainer .tree-view_arrow-collapsed {
    transform: rotate(0deg);
    transition: transform 1s linear;
    color: #fff; }

.originalResponseContainer {
  background-color: #dfd8fd;
  border: 0.5px solid #9f8fef;
  border-bottom-right-radius: 5px;
  border-bottom-left-radius: 5px;
  padding: 10px;
  min-height: 1rem; }
  .originalResponseContainer .text {
    font-weight: 600; }

.check {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 35px !important;
  height: 35px !important;
  background-color: #366fde;
  color: white; }

.cross {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 35px !important;
  height: 35px !important;
  background-color: #ffffff;
  border: 1px solid #dedede; }

.marginLeft {
  margin-left: 0px !important; }

.modal-question-history .modal-dialog {
  display: flex;
  justify-content: center; }
  .modal-question-history .modal-dialog .modal-content {
    margin-top: 0;
    min-width: 80vw;
    overflow-y: auto; }

.statusContainer {
  padding: 5px;
  border: 2px solid black;
  color: #fff;
  margin-bottom: 0.5rem;
  width: 9rem;
  text-align: center;
  font-weight: bold; }
