.references-component {
  height: 100%;
  justify-content: center;
  align-items: flex-start;
  overflow-y: scroll; }
  @media screen and (max-width: 767px) {
    .references-component {
      width: auto;
      flex-direction: column;
      height: initial; } }
  .references-component .rates-content-container {
    padding-left: 0px;
    flex: 1 1; }
    @media screen and (max-width: 767px) {
      .references-component .rates-content-container {
        width: 100%; } }
  .references-component .icon-button {
    cursor: pointer;
    margin: 0 7px; }
    .references-component .icon-button:last-of-type {
      margin-right: 0; }
    @media screen and (max-width: 767px) {
      .references-component .icon-button {
        margin: 0 15px; } }
  .references-component .nav .active {
    font-weight: bold; }
  .references-component .rates-list-container {
    max-width: 250px;
    text-align: center;
    height: 100%;
    overflow: auto; }
    @media screen and (max-width: 767px) {
      .references-component .rates-list-container {
        height: auto;
        width: 100%;
        max-width: none; } }
  .references-component .bibliographies-secondHeader {
    background-color: #d3d3d3; }
  .references-component .listsContainer {
    margin-top: 60px;
    margin-left: 2%; }
  .references-component .buttonsContainer {
    padding-left: 15px;
    margin-top: 20px; }
  .references-component .add {
    margin-right: 10px; }
  .references-component .select-all-option {
    margin-top: 40px; }
  .references-component .subtopics-list {
    width: 500px;
    margin-top: 20px; }
  .references-component .create-reference-btn-container {
    float: right;
    margin-top: 30px;
    margin-right: 30px; }
  .references-component .gridContainer {
    margin: 30px; }
  .references-component .ReactTable .rt-tr:hover {
    background: #e2e2e2; }
  .references-component .ReactTable .rt-tr:hover .settingsRow {
    visibility: visible; }
  .references-component .statusLabel {
    z-index: 1000;
    cursor: pointer !important;
    color: #20a8d8; }
  .references-component .fa-trash {
    cursor: pointer;
    margin-left: 10px; }
  .references-component .fa-gear, .references-component .fa-file-pdf-o {
    cursor: pointer; }
  .references-component .circleSize {
    cursor: pointer;
    width: 15px;
    height: 15px; }
  .references-component .circleSizeManualRed {
    width: 16px;
    height: 15px; }
  .references-component .fa-plus {
    margin-left: 15px;
    cursor: pointer; }
  .references-component .noMargins {
    margin: 0 !important; }
  .references-component .settingsRow {
    visibility: hidden; }
