.add-filter-component .filterName {
  display: flex;
  align-items: center;
  font-size: 14px;
  color: #7e7e7e; }

.add-filter-component .filterContainer {
  display: flex; }

.add-filter-component .boxesContainer {
  display: flex;
  margin-left: 20.5px; }

.add-filter-component .serviceColumn {
  margin-left: 9px; }

.add-filter-component .rankColumn {
  margin-left: 10px; }

.add-filter-component .filterBox {
  cursor: pointer;
  padding-left: 19px;
  padding-right: 19px;
  border: solid 1px #a4b7c1;
  height: 34px;
  display: flex;
  align-items: center;
  font-size: 14px !important;
  color: #000000 !important; }
  .add-filter-component .filterBox.active {
    background-color: #042875;
    color: #ffffff !important;
    margin-right: -1px;
    margin-left: -1px; }

.add-filter-component .separatorLine {
  margin-top: 19px;
  height: 1px;
  background-color: #c2cfd6;
  width: 105.3%;
  margin-left: -15px; }

.add-filter-component .mTop20 {
  margin-top: 20px; }

.add-filter-component .bottomButtons {
  margin-top: 19px;
  float: right; }

.add-filter-component .labelAll {
  margin-left: 20px; }
