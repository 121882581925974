.studyPlan {
  margin: 5rem 5rem;
  width: 60%;
  max-width: 70%;
  flex: 1; }
  .studyPlan .dataItem {
    display: inline-block;
    margin-right: 35px;
    font-size: .9rem; }
  .studyPlan .navyButton {
    width: 135px;
    align-items: center;
    height: 31px;
    cursor: pointer;
    font-size: 14px;
    color: #ffffff;
    background-color: #0058ff;
    border: solid 1px #0058ff;
    margin-right: 15px;
    justify-content: center;
    flex-orientation: row; }
  .studyPlan .navyButtonClicked {
    width: 135px;
    align-items: center;
    height: 31px;
    cursor: pointer;
    font-size: 14px;
    color: #ffffff;
    background-color: #042875;
    border: solid 1px #042875;
    margin-right: 15px;
    justify-content: center;
    flex-orientation: row; }
  .studyPlan .buttonsContainer {
    margin-top: 35px;
    padding-left: 0px !important; }
  .studyPlan .dataContainer {
    margin-top: 35px; }
  .studyPlan h1 {
    font-size: 35px; }
  .studyPlan .componentContainer {
    margin-top: 45px;
    height: 500px;
    overflow-y: auto;
    padding-left: 10px; }
  .studyPlan .editingButton {
    width: 135px;
    align-items: center;
    height: 31px;
    cursor: pointer;
    font-size: 14px;
    color: #0058ff;
    background-color: white;
    border: solid 1px #0058ff;
    margin-right: 15px;
    justify-content: center;
    flex-orientation: row; }
