.notifications-container {
  margin: 10px 10px 10px 0px; }
  .notifications-container .link {
    color: black;
    text-decoration: none;
    background-color: transparent; }
  .notifications-container .notifications-title {
    background-color: #eaecf0;
    padding: 1rem;
    font-weight: bold;
    white-space: nowrap;
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    max-height: 3.5rem;
    margin-left: 0 !important;
    margin-right: 0 !important; }
  .notifications-container .notifications-details {
    padding: 0 1rem 0 1rem; }
    .notifications-container .notifications-details .subtitle {
      font-weight: bold; }
      .notifications-container .notifications-details .subtitle.disabled {
        color: #c2c6cb; }
    .notifications-container .notifications-details .subtitleThan {
      font-weight: bold; }
      .notifications-container .notifications-details .subtitleThan.disabled {
        color: #c2c6cb; }
    .notifications-container .notifications-details .textAreaContainer {
      border: none;
      background-color: transparent; }
    .notifications-container .notifications-details .inputAreaContainer {
      background-color: transparent; }

.directionColumn {
  display: flex;
  flex-direction: column; }

.directionRow {
  display: flex;
  flex-direction: row;
  align-items: start;
  margin-top: 3px; }
  .directionRow.background {
    background-color: #eaecf0; }

.height-5 {
  height: auto; }

.width50 {
  width: 50%; }

.width100 {
  width: 100% !important; }

.inputRow {
  width: auto;
  text-align: center;
  border: none; }

.circle-size {
  font-size: 8px; }

.itemCenter {
  display: flex;
  align-items: center; }

.margin-top {
  margin-top: 10px;
  margin-bottom: 10px; }

.margin-bottom {
  margin-bottom: 10px; }

.moreOrLessText {
  display: flex;
  flex-direction: row; }
