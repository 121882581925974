.statsContainer {
  margin: 0px; }
  .statsContainer > div {
    display: inline-block;
    *display: inline;
    width: 25%;
    text-align: center; }
  .statsContainer .Answered, .statsContainer .Percentage {
    color: #050507; }
  .statsContainer .Correct {
    color: #00bf04; }
  .statsContainer .Incorrect {
    color: #ff0000; }
  .statsContainer .statValue {
    font-weight: bold; }
