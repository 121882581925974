.coupons-list .rate-line .description {
  font-weight: bold;
  color: #000000; }

.coupons-list .rate-line .codes {
  font-weight: normal;
  color: #9e9f9f; }

.coupons-list .flexItemsTitlePlus {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-content: center;
  align-items: center; }

.coupons-list .searchInputContainer {
  min-width: 300px;
  display: flex;
  align-items: center;
  justify-content: flex-end; }
  .coupons-list .searchInputContainer .fa-in-search {
    position: absolute;
    right: 30px;
    top: 26px; }
  .coupons-list .searchInputContainer .fa-close {
    cursor: pointer; }
  .coupons-list .searchInputContainer .form-control {
    margin-right: 30px !important; }
