.simple-confirmation-component .simple-confirmation-form {
  margin-top: 10px; }

.simple-confirmation-component .btn {
  width: 50%;
  height: 40px;
  font-weight: bold; }

.simple-confirmation-component .bottom-buttons {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 15px;
  margin-right: 15px; }

.simple-confirmation-component .card-block {
  margin-bottom: 35px; }

.simple-confirmation-component .text {
  margin-bottom: 20px;
  line-height: 170%;
  word-wrap: break-word; }

.simple-confirmation-component .errors {
  color: red;
  text-align: center; }

.simple-confirmation-component .danger {
  color: red;
  font-weight: bold; }
