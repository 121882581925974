.AssignWriterModal {
  padding: 15px; }
  .AssignWriterModal .AssignWriterModalTitle {
    display: inline-flex;
    align-items: center; }
    .AssignWriterModal .AssignWriterModalTitle .title {
      font-size: 18px;
      font-weight: bold; }
    .AssignWriterModal .AssignWriterModalTitle .closeIcon {
      position: absolute;
      right: 25px;
      font-size: 17px;
      cursor: pointer; }
  .AssignWriterModal .AssignWriterModalBody {
    min-height: 35px; }
    .AssignWriterModal .AssignWriterModalBody .assignWriterSpinnerContainer {
      min-height: 200px;
      display: flex;
      align-items: center; }
      .AssignWriterModal .AssignWriterModalBody .assignWriterSpinnerContainer .spinnerPosition {
        font-size: 33px !important; }
    .AssignWriterModal .AssignWriterModalBody .AssignWriterForm {
      border: none !important;
      padding-left: 0px;
      padding-right: 0px; }
      .AssignWriterModal .AssignWriterModalBody .AssignWriterForm .react-datepicker__input-container {
        width: 100% !important; }
      .AssignWriterModal .AssignWriterModalBody .AssignWriterForm .secondaryWritersSelector {
        display: flex;
        width: 100% !important; }
    .AssignWriterModal .AssignWriterModalBody .AssignWriterButtons {
      display: inline-flex;
      width: 100%;
      justify-content: space-between; }
      .AssignWriterModal .AssignWriterModalBody .AssignWriterButtons .btn {
        margin-right: 0px !important;
        margin-left: 0px !important;
        width: 30%; }
      .AssignWriterModal .AssignWriterModalBody .AssignWriterButtons .btn-primary:disabled {
        cursor: not-allowed; }
  .AssignWriterModal .select2-selection--multiple {
    border: 1px solid rgba(0, 0, 0, 0.15) !important;
    border-radius: 0px !important;
    margin-top: 8px !important;
    padding: 0.5rem 1rem;
    display: flex; }
    .AssignWriterModal .select2-selection--multiple .select2-search__field {
      margin: 0px !important; }
    .AssignWriterModal .select2-selection--multiple .select2-selection__rendered {
      display: flex;
      align-items: center; }
    .AssignWriterModal .select2-selection--multiple .select2-selection__choice {
      margin-top: 0px !important; }
  .AssignWriterModal .select2-container--disabled .select2-selection--multiple {
    background-color: #cfd8dc !important;
    border: none !important; }
