.daily-quiz-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  max-height: 100vh;
  overflow-y: auto; }
  .daily-quiz-container .text-red {
    color: red;
    font-weight: bold; }
  .daily-quiz-container .text-green {
    color: green;
    font-weight: bold; }
  .daily-quiz-container .generate-daily-button {
    display: flex;
    align-items: center;
    height: 2.4rem;
    cursor: pointer;
    font-size: 14px;
    color: #0058ff;
    border: solid 1px #0058ff;
    justify-content: center;
    width: fit-content;
    padding: 1rem;
    margin: 0 auto;
    margin-top: 3.5rem;
    transition: all 0.2s ease;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); }
  .daily-quiz-container .generate-daily-button:hover {
    background-color: #0058ff;
    color: white; }
  .daily-quiz-container .generate-daily-button:active {
    transform: translateY(2px);
    box-shadow: 0 2px 3px rgba(0, 0, 0, 0.2); }
  .daily-quiz-container .rates-daily-quiz-container {
    margin-top: 4rem;
    width: 100%;
    display: flex;
    justify-content: center;
    gap: 15rem; }
    .daily-quiz-container .rates-daily-quiz-container .rates-daily-quiz .title-text {
      font-weight: bold; }
