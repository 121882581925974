.cycles-component {
  height: 100%;
  justify-content: center;
  align-items: flex-start; }
  @media screen and (max-width: 767px) {
    .cycles-component {
      width: auto;
      flex-direction: column;
      height: initial; } }
  .cycles-component .cycles-content-container {
    flex: 1 1; }
    @media screen and (max-width: 767px) {
      .cycles-component .cycles-content-container {
        width: 100%; } }
  .cycles-component .icon-button {
    cursor: pointer;
    margin: 0 7px; }
    .cycles-component .icon-button:last-of-type {
      margin-right: 0; }
    @media screen and (max-width: 767px) {
      .cycles-component .icon-button {
        margin: 0 15px; } }
  .cycles-component .nav .active {
    font-weight: bold; }
  .cycles-component .cycles-list-container {
    max-width: 250px;
    text-align: center;
    height: 100%;
    overflow: auto;
    background-color: #ECEFF1; }
    @media screen and (max-width: 767px) {
      .cycles-component .cycles-list-container {
        height: auto;
        width: 100%;
        max-width: none; } }

.TextLinkAtBottom {
  position: absolute;
  bottom: 0;
  width: 90%;
  margin-bottom: 10PX; }

.cycles-content-container {
  height: 100%;
  overflow: auto; }
