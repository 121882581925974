.rates-list {
  overflow-y: auto;
  overflow-x: hidden;
  height: 80vh; }
  .rates-list .form-control {
    margin-right: 20px;
    margin-left: 20px;
    margin-bottom: 10px; }
  .rates-list .rates-title .fa-plus {
    margin: 0px 15px !important; }
  .rates-list .rate-line {
    width: 97.3% !important;
    padding-left: 35px !important; }
    .rates-list .rate-line .description {
      font-weight: bold;
      color: #000000; }
    .rates-list .rate-line .codes {
      font-weight: normal;
      color: #9e9f9f; }
  .rates-list .flexItemsTitlePlus {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-content: center;
    align-items: center; }
  .rates-list .searchInputContainer {
    min-width: 300px;
    display: flex;
    align-items: center;
    justify-content: flex-end; }
    .rates-list .searchInputContainer .fa-in-search {
      position: absolute;
      right: 50px;
      top: 26px; }
    .rates-list .searchInputContainer .fa-close {
      cursor: pointer; }

.searchInput {
  display: flex; }
