.usersListContainer {
  margin-top: 0px !important;
  max-width: 480px;
  min-width: 320px;
  padding-left: 10px;
  padding-top: 10px;
  padding-right: 0px;
  background-color: #EAECEE; }

.AdminCol {
  padding: 15px;
  max-width: 480px;
  min-width: 320px; }
  .AdminCol .cardContentWrapper {
    max-height: calc(100vh - 180px);
    overflow-y: auto !important; }

.react-datepicker-popper {
  z-index: 10 !important;
  min-width: 248px; }

.react-datepicker__input-container, .react-datepicker-wrapper {
  width: 100%; }
  .react-datepicker__input-container input, .react-datepicker-wrapper input {
    width: 100%; }

.newStudentWrapper .select2-container, .newStudentWrapper .select2-search__field, .newStudentWrapper .select2-search, .newStudentWrapper .select2-selection, .newStudentWrapper .select2-selection--multiple, .newStudentWrapper .select2-search__field, .newStudentWrapper .select2-dropdown, .newStudentWrapper .select2-dropdown--below {
  width: 130px !important; }

.myAccountContainer {
  overflow-y: auto !important;
  overflow-x: hidden; }
  .myAccountContainer .MyAccountTitle {
    font-size: 25px;
    font-weight: bold;
    margin-top: 10px;
    margin-left: 20px; }

.height-90 {
  height: 90% !important; }

.responsive-col-4 {
  width: 33.33333% !important; }

.responsive-col-6 {
  width: 50% !important; }

.responsive-col-8 {
  width: 66.66667% !important; }

@media (max-width: 991px) {
  .myAccountContainer {
    margin-top: 35px; } }

.pill {
  width: 80px !important; }

.my-account-col {
  margin: 20px;
  margin-top: 0px;
  max-width: 480px;
  min-width: 320px;
  padding: 15px;
  max-height: 432px; }

.adminSpinner {
  width: 75%;
  height: 100%;
  position: relative;
  top: 25%; }

.hidden {
  opacity: 0;
  height: 0px;
  width: 0px; }

.btn-login {
  background: linear-gradient(261deg, #13d5e7, #1a4bef);
  background-color: #fff;
  border: none;
  color: #fff;
  border-radius: .312rem;
  border-radius: 0.312rem;
  font-weight: bold; }

.admin-login {
  background-color: #131743;
  background-image: url("/public/img/Blob Shapes.svg");
  background-size: contain; }

.logo-bj {
  width: 310px; }

.iheader {
  height: 100%;
  width: 100%;
  display: block;
  margin: auto;
  background-color: #142060;
  padding: 1rem; }

.h1-margin-bot {
  margin-bottom: 1rem; }

.error-tooltip-red {
  color: red;
  font-size: 12px; }

.row .btn-back-home {
  background: linear-gradient(261deg, #ec7069, #ff2100);
  background-color: #fff;
  border: none;
  color: #fff;
  border-radius: .312rem;
  border-radius: 0.312rem;
  font-weight: bold;
  margin-left: 15px; }

.warning-tooltip-password {
  font-size: 12px;
  color: red; }
