.notification--in-progress {
  font-size: 1.2rem;
  margin-left: auto;
  display: flex;
  flex-direction: column;
  border: 2px solid #9f8fef;
  background-color: #dfd8fd;
  padding: 6px 25px 6px 15px;
  border-radius: 4px;
  position: relative; }

.notification--finished {
  font-size: 1.2rem;
  margin-left: auto;
  display: flex;
  flex-direction: column;
  border: 2px solid #45A049;
  background-color: #9BE3A7;
  padding: 6px 25px 6px 15px;
  border-radius: 4px;
  position: relative; }

.notification--failed {
  font-size: 1.2rem;
  margin-left: auto;
  display: flex;
  flex-direction: column;
  border: 2px solid #B71C1C;
  background-color: #FFCDD2;
  padding: 6px 25px 6px 15px;
  border-radius: 4px;
  position: relative; }

.close--icon {
  position: absolute;
  top: 5px;
  right: 5px;
  cursor: pointer; }
