.studyPlan {
  margin: 5rem 5rem;
  width: 60%;
  max-width: 70%; }
  .studyPlan .navyButton {
    width: 135px;
    align-items: center;
    height: 31px;
    cursor: pointer;
    font-size: 14px;
    color: #ffffff;
    background-color: #0058ff;
    border: solid 1px #0058ff;
    margin-right: 15px;
    justify-content: center;
    flex-orientation: row; }
  .studyPlan .buttonsContainer {
    margin-top: 35px;
    padding-left: 0px !important; }
  .studyPlan .dataContainer {
    margin-top: 35px; }
  .studyPlan h1 {
    font-size: 35px; }
  .studyPlan .componentContainer {
    margin-top: 45px;
    height: 500px;
    overflow-y: auto;
    padding-left: 10px; }
  .studyPlan .form-control {
    margin-left: 0px;
    width: 60%; }
  .studyPlan .personalData {
    margin-bottom: 20px; }
  .studyPlan .form-group {
    display: inline-block;
    margin-left: 0px;
    width: 70%;
    margin-bottom: 0px; }
  .studyPlan .disabled {
    cursor: not-allowed;
    opacity: 0.5;
    text-decoration: none; }
