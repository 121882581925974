.submitted__questions__title {
  font-size: 1.2rem;
  font-weight: 700;
  margin-bottom: 1rem; }

.submitted__questions__left__container {
  max-width: 710px;
  display: flex;
  flex-direction: column;
  gap: 1rem; }

.submitted__questions__search__container {
  display: flex;
  flex-direction: column; }

.submitted__questions__search__input {
  max-width: 900px; }

.submitted__questions__rate__container {
  display: flex;
  flex-direction: column;
  gap: 1.5rem; }

.submitted__questions__rate__item {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 1rem; }

.submitted__questions__rate__item__all {
  font-weight: 700; }

.whiteButtons.disabled {
  background-color: lightgrey;
  color: darkgrey;
  border-color: darkgray;
  cursor: not-allowed; }

p {
  margin: 0; }
