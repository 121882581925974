.cycle-form-component {
  margin-left: 20px;
  margin-right: 20px; }
  .cycle-form-component form {
    margin-top: 10px;
    margin-bottom: 10px; }
  .cycle-form-component .btn {
    width: 50%;
    height: 40px;
    font-weight: bold; }
  .cycle-form-component .bottom-buttons {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0; }
  .cycle-form-component .card-block {
    margin-bottom: 35px; }
  .cycle-form-component .text {
    margin-bottom: 20px;
    line-height: 170%; }
  .cycle-form-component .errors {
    color: red;
    text-align: center;
    position: relative;
    top: -10px; }
  .cycle-form-component .label {
    display: inline-block; }
  .cycle-form-component .success-cycle-form {
    width: 210px; }
  .cycle-form-component .label-exam-date {
    padding-top: 5px;
    margin-right: 10px; }
  .cycle-form-component .date-picker-input {
    border: 1px solid #dbdbdb;
    display: flex;
    width: 10rem; }
  .cycle-form-component .calendar-icon {
    border: 0;
    line-height: normal;
    vertical-align: middle;
    padding: 5%; }
  .cycle-form-component .date-blabla {
    border: 0;
    color: #757575;
    font-size: 1.2rem;
    cursor: pointer;
    text-align: center; }
  .cycle-form-component .react-datepicker__input-container {
    width: 100% !important; }
  .cycle-form-component .destroy-buttons {
    margin-top: 10px; }
  .cycle-form-component .new-cycle-form .input-group {
    z-index: auto; }
  .cycle-form-component .button-row {
    margin-left: -8px !important; }
