.roles-component {
  width: 100%;
  padding: 20px; }
  .roles-component .btnDelete {
    margin-left: 20px; }
  .roles-component .submitSectionContainer {
    margin-left: 25px;
    width: 40%;
    margin-top: 50px; }
  .roles-component .checkbox-inline {
    margin-left: 10px; }

.rolesSectionContainer {
  overflow: scroll;
  height: 100%;
  overflow-x: hidden;
  width: 100%; }
  .rolesSectionContainer .rolesListSector {
    padding-right: 0px !important;
    padding-left: 0px !important;
    background-color: #EAECEE; }
