.ActivityContainer .link {
  color: #366fde; }

.ActivityContainer .bold {
  font-weight: bold; }

.ActivityContainer .separatorLine {
  margin-top: 9px;
  margin-bottom: 9px;
  margin-left: 15px;
  width: 100%; }

.ActivityContainer .paginationContainer {
  margin-top: 9px;
  margin-left: -22px;
  margin-right: -22px;
  display: flex;
  align-items: center;
  align-content: center;
  text-align: center;
  width: 115%;
  height: 64px;
  background-color: #f0f3f5;
  border: solid 1px #c2cfd6; }

.ActivityContainer .cardContentWrapper {
  max-height: calc(100vh - 180px);
  overflow-y: auto !important; }

.roleCol {
  padding-right: 0px;
  line-height: 30px; }

.pClass {
  text-overflow: ellipsis;
  color: #000;
  font-family: Helvetica;
  font-size: 14px;
  font-weight: 700;
  height: 17px;
  overflow: hidden;
  white-space: nowrap; }

.centered {
  margin: 0 auto; }

.editGeneralRoles {
  margin: 0 auto;
  padding-top: 10px; }

.check {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 35px !important;
  height: 35px !important;
  background-color: #366fde;
  color: white; }

.cross {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 35px !important;
  height: 35px !important;
  background-color: #ffffff;
  border: 1px solid #dedede; }

.selectContainer {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%; }
  .selectContainer .Select-control {
    width: 150px;
    border-radius: 0px; }

.Select-clear-zone {
  display: none; }

.Select-arrow-zone {
  display: none; }

.selectContainerBibliographies {
  position: relative;
  display: flex;
  width: 100%; }

.buttonsEditContainer {
  display: flex;
  flex-direction: row;
  gap: 10px; }

.Select-control {
  width: 400px; }

.select2-container--default .select2-selection--multiple {
  border-radius: 0px; }

.RolesContainer .select2-container, .RolesContainer .select2-search__field, .RolesContainer .select2-search, .RolesContainer .select2-selection, .RolesContainer .select2-selection--multiple, .RolesContainer .select2-search__field, .RolesContainer .select2-dropdown, .RolesContainer .select2-dropdown--below {
  width: 100px !important; }

.select2-container--default .select2-results > .select2-results__options {
  max-height: 300px !important; }

.select2-results__options {
  max-height: 300px !important; }
