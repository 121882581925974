.instructionsModal .si-modal hr {
  margin: 8px 0px; }

.instructionsModal .si-modal .search-instructions-text-title .title {
  font-size: 24px;
  color: black;
  font-weight: bold; }

.instructionsModal .si-modal .search-instructions-text-title .instructions-text {
  margin-top: 10px; }
  .instructionsModal .si-modal .search-instructions-text-title .instructions-text .instructions {
    margin-bottom: 5px; }

.instructionsModal .si-modal .search-instructions-title {
  display: inline-flex; }
  .instructionsModal .si-modal .search-instructions-title .title {
    font-size: 20px;
    color: black;
    font-weight: bold;
    margin-left: 10px;
    margin-bottom: 6px; }
  .instructionsModal .si-modal .search-instructions-title .close {
    position: absolute;
    right: 18px;
    top: 20px;
    font-size: 24px !important;
    cursor: pointer; }

.instructionsModal .si-modal .search-instructions-body {
  padding: 0px 10px 10px 10px; }
  .instructionsModal .si-modal .search-instructions-body .subtitle {
    font-size: 16px;
    margin: 6px 0px;
    padding: 6px 0px;
    width: 100%;
    display: flex;
    font-weight: bold;
    color: #0562ff; }
  .instructionsModal .si-modal .search-instructions-body .item {
    padding: 0px 6px;
    margin-bottom: 1px;
    display: flex;
    width: 100%; }
    .instructionsModal .si-modal .search-instructions-body .item .tag {
      font-weight: bold; }
    .instructionsModal .si-modal .search-instructions-body .item .dataType {
      color: grey;
      margin-left: auto;
      font-weight: bold; }
