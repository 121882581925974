.edit-question-modal .editionTitle {
  color: black;
  font-weight: bold;
  padding-bottom: 10px; }

.edit-question-modal .radioButton {
  margin-top: 7px;
  margin-left: 130px; }

.edit-question-modal .orderSelect {
  display: inline-flex;
  width: 40%;
  float: right; }

.edit-question-modal .textAreaContainer {
  padding-top: 40px !important; }
  .edit-question-modal .textAreaContainer .form-control {
    margin-left: 0px; }

.edit-question-modal .textAreaContent {
  height: 180px; }

.edit-question-modal .pTop20 {
  padding-top: 20px !important; }

.edit-question-modal .redBorder {
  border: 1px solid red; }

.edit-question-modal .pages {
  width: 15%; }

.edit-question-modal .BorderTextArea {
  border-radius: 6px; }

.edit-question-modal .pagesOne {
  margin-right: 5px; }

.edit-question-modal .pagesContainer {
  width: 70%;
  float: right; }

.edit-question-modal .editorWrapper {
  padding-top: 40px; }

.edit-question-modal .pageLabel {
  margin-right: 5px; }

.edit-question-modal .buttonsContainer {
  float: right;
  margin-top: 20px; }
  .edit-question-modal .buttonsContainer .btn-success {
    margin-right: 0px !important;
    background-color: #366FDE; }
  .edit-question-modal .buttonsContainer .btn-secondary {
    background-color: #A4B7C1; }
  .edit-question-modal .buttonsContainer .helper-text {
    font-size: 12.4px;
    display: block;
    margin-top: 3px;
    margin-left: 11px; }

.edit-question-grid-image {
  margin-top: 4px; }

.substantialChangesCheckbox {
  display: flex;
  padding-left: 0.8rem;
  padding-top: 1rem;
  align-items: center; }

.check {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 35px !important;
  height: 35px !important;
  background-color: #366fde;
  color: white; }

.cross {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 35px !important;
  height: 35px !important;
  background-color: #ffffff;
  border: 1px solid #dedede; }

.marginLeft {
  margin-left: 0px !important; }

.aiSuggestion {
  margin: 15px 0 0 10px;
  color: #df24da; }
  .aiSuggestion span {
    margin-left: 5px; }

.include-further-reading-checkbox {
  font-size: 0.8rem;
  cursor: pointer; }
  .include-further-reading-checkbox label {
    display: flex;
    align-items: center;
    gap: 6px;
    padding-top: 0;
    margin-bottom: 0;
    cursor: inherit;
    user-select: none;
    max-width: 160px; }
  .include-further-reading-checkbox input {
    cursor: inherit;
    margin-left: 6px; }
