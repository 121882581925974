.containerList .noBanners {
  margin: 15px; }

.containerList .searchInput {
  background-color: #FFFFFF;
  height: 40px;
  display: flex;
  margin: 10px 16px 0 0;
  position: relative;
  flex: 1 1 auto; }
  .containerList .searchInput .fa-search {
    display: flex;
    align-items: center;
    color: #e4e5e6;
    margin-right: 16px;
    margin-top: 0;
    position: absolute;
    height: 100%;
    right: 0;
    z-index: 0; }
  .containerList .searchInput .fa-close {
    float: right;
    margin-right: 16px;
    margin-top: 10px;
    margin-left: 2px;
    position: absolute;
    right: 0;
    z-index: 2;
    cursor: pointer; }

.containerList .bannerName {
  padding: 15px;
  margin-top: 10px;
  cursor: pointer; }
  .containerList .bannerName.active {
    background: white;
    font-weight: bold; }

.containerList .plusContainer {
  display: flex;
  align-items: center;
  margin-top: 10px; }
  .containerList .plusContainer .fa-plus {
    margin-top: 1px;
    margin-left: 2px; }

.contectCenter {
  display: flex;
  justify-content: center; }

.cardBanner {
  margin: 15px;
  padding: 15px;
  border: 1px solid #00000017;
  border-radius: 5px;
  background: #eaecee;
  width: 80%; }
  .cardBanner .bannerInformation {
    display: flex;
    flex-direction: column;
    height: 90px; }
    .cardBanner .bannerInformation .headerBanner {
      display: flex;
      flex-direction: row;
      justify-content: space-between; }
      .cardBanner .bannerInformation .headerBanner .containerActive {
        background: #46ff468f;
        width: 8rem;
        text-align: center;
        border: 1px solid #b0bec5;
        border-radius: 5px;
        margin-bottom: 5px; }
        .cardBanner .bannerInformation .headerBanner .containerActive.inactive {
          background: #f717008f; }

.cardCreateBanner {
  padding: 1rem;
  margin-top: 2rem;
  background: #eaecee;
  border: solid 1px #00000026;
  width: 80%; }
  .cardCreateBanner .colorContainer {
    width: 100%;
    height: 2.2rem; }
  .cardCreateBanner .buttonContainer {
    margin-top: 1rem;
    text-align: end; }
  .cardCreateBanner .btn-success-save {
    width: 68px;
    background-color: #366fde;
    margin-right: 0rem; }
  .cardCreateBanner .btn-success-cancel {
    width: 80px;
    background-color: #a4b7c1;
    border: none;
    margin-right: 0rem; }

.buttonViewBanner {
  color: #0098f7;
  text-decoration: underline;
  cursor: pointer; }

.viewContainer {
  min-height: 10rem;
  background-color: #131743;
  margin-top: 1rem;
  width: 100%; }
  .viewContainer .bannerDetail {
    width: 90%; }
    .viewContainer .bannerDetail .bannerText {
      font-weight: bold;
      color: white;
      margin-left: 2rem;
      padding: 0.5rem; }

.required {
  border-color: red; }
