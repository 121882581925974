.BooksContainer {
  width: 100%;
  height: calc(100% - 124px);
  margin-left: 0px; }
  .BooksContainer .flexAlignCenter {
    display: flex;
    align-items: center; }
  .BooksContainer .expandAllDiv {
    display: flex;
    width: 100%;
    margin-bottom: 10px;
    align-items: center;
    justify-content: space-between; }
  .BooksContainer .itemName {
    cursor: pointer;
    align-self: center;
    flex: 1 1 auto;
    white-space: nowrap;
    overflow: hidden !important;
    text-overflow: ellipsis;
    font-size: 13px;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.54;
    letter-spacing: normal;
    text-align: left;
    color: #000000;
    padding: 0 1rem; }
    .BooksContainer .itemName .NameWithArrows {
      display: flex;
      align-items: center; }
      .BooksContainer .itemName .NameWithArrows .MoveRowUpDown {
        margin-right: 10px;
        margin-left: 5px;
        color: #7b7b7b; }
    .BooksContainer .itemName .chapter-name {
      font-size: 13px;
      font-weight: normal;
      font-style: normal;
      font-stretch: normal;
      line-height: 1.54;
      letter-spacing: normal;
      text-align: left;
      color: #000000;
      margin-left: 15px; }
  .BooksContainer .noReferences {
    width: 200px;
    margin: 20px auto; }
  .BooksContainer .inputError {
    color: red; }
  .BooksContainer .inputErrorChapter {
    border: solid 1px red; }
  .BooksContainer .rates-list-container {
    padding-right: 0px !important;
    padding-left: 0px !important;
    text-align: left !important;
    overflow-y: hidden !important;
    height: 100vh;
    background-color: #eaecf0; }
    .BooksContainer .rates-list-container .roleRow {
      background-color: #eaecf0; }
      .BooksContainer .rates-list-container .roleRow:hover {
        background-color: #f6f5f4; }
      .BooksContainer .rates-list-container .roleRow.active {
        background-color: #ffffff; }
  .BooksContainer .mTop20 {
    margin-top: 20px; }
  .BooksContainer .booksPageTop {
    display: flex;
    width: 100%;
    margin-top: 20px;
    justify-content: space-between; }
    .BooksContainer .booksPageTop .btn-sm {
      background-color: #366FDE;
      position: relative; }
    .BooksContainer .booksPageTop .search {
      width: 83%;
      margin: auto;
      height: 31px; }
      .BooksContainer .booksPageTop .search .search-input {
        width: 100%; }
      .BooksContainer .booksPageTop .search .fa-remove {
        cursor: pointer;
        color: #95989A;
        float: right;
        position: relative;
        top: -25px;
        right: -6px;
        font-size: 1.4em; }
    .BooksContainer .booksPageTop .fix {
      right: 30px !important; }
    .BooksContainer .booksPageTop .search .fa-search {
      cursor: pointer;
      color: #95989A;
      float: right;
      position: relative;
      top: -25px;
      right: 10px;
      font-size: 1.3em; }
    .BooksContainer .booksPageTop .search .fa-remove.hidden {
      display: none; }
  .BooksContainer .references-list-component {
    clear: both; }
  .BooksContainer .tree-view {
    margin-left: 8px;
    margin-right: 8px;
    transition: transform 1s linear;
    position: relative;
    overflow: visible; }
  .BooksContainer .list-group-item {
    background-color: #eaecf0;
    border: none !important;
    cursor: pointer;
    position: relative;
    padding: 0;
    display: flex;
    flex-flow: column;
    justify-content: center;
    height: 50px; }
    .BooksContainer .list-group-item.dragged-over:before {
      content: "";
      position: absolute;
      background-color: #656565;
      top: 100%;
      height: 4px;
      width: 100%; }
    .BooksContainer .list-group-item.being-dragged {
      opacity: .5; }
    .BooksContainer .list-group-item > span {
      display: flex;
      align-items: stretch;
      justify-content: space-between;
      flex: 1 1 auto; }
  .BooksContainer .tree-view .list-group-item:hover {
    background-color: #f4f5f6; }
  .BooksContainer .actAsChapterContainer .list-group-item:hover {
    background-color: #f4f5f6; }
  .BooksContainer .tree-level-0 {
    flex: 1 1 0;
    margin-left: 15px;
    padding-left: 15px;
    font-weight: bold;
    background-color: white !important;
    border: none !important; }
  .BooksContainer .addBtn {
    margin-left: 0px !important;
    margin-right: 0px !important; }
  .BooksContainer .tree-level-1 {
    flex: 1 1 0;
    margin-top: 8px;
    padding-left: 40px;
    font-weight: bold;
    width: 100%; }
  .BooksContainer .tree-options {
    font-size: 14px;
    color: #999;
    float: right;
    display: flex;
    align-items: stretch; }
    .BooksContainer .tree-options > a {
      padding: .5rem;
      display: inline-flex;
      align-items: center; }
      .BooksContainer .tree-options > a:hover, .BooksContainer .tree-options > a:active {
        background-color: #d8d8d8; }
    .BooksContainer .tree-options > div > div {
      height: 100%; }
      .BooksContainer .tree-options > div > div .threeDotsMenuContainer {
        height: 100%;
        display: inline-flex;
        align-items: center; }
        .BooksContainer .tree-options > div > div .threeDotsMenuContainer:hover, .BooksContainer .tree-options > div > div .threeDotsMenuContainer:active {
          background-color: #d8d8d8; }
    .BooksContainer .tree-options .tooltip-title {
      color: #999; }
    .BooksContainer .tree-options .react-tooltip {
      border: 1px solid #eaecf0; }
    .BooksContainer .tree-options .tooltip-text {
      color: black; }
    .BooksContainer .tree-options .tooltip-icon {
      font-size: 25px; }
      .BooksContainer .tree-options .tooltip-icon .fa-user {
        margin-right: 3px;
        margin-left: 2px; }
    .BooksContainer .tree-options .tooltip-icon-add {
      font-size: 22px;
      color: #0558ff;
      cursor: pointer; }
    .BooksContainer .tree-options .pdfImage {
      width: 20px;
      cursor: pointer; }
    .BooksContainer .tree-options .spinnerPosition {
      padding: 0px;
      padding-right: 10px; }
      .BooksContainer .tree-options .spinnerPosition .fa {
        font-size: 20px; }
  .BooksContainer .addChapterBox {
    padding-left: 0px;
    padding-right: 0px;
    background-color: white !important;
    border: none !important; }
  .BooksContainer .tree-level-2 {
    margin: 5px 0;
    height: 52px; }
  @media screen and (max-width: 767px) {
    .BooksContainer {
      display: block; } }
  .BooksContainer:hover .tree-options {
    color: grey; }
  .BooksContainer .tree-view_item {
    display: contents;
    flex-direction: row; }
  .BooksContainer .tree-view_arrow {
    margin: 0;
    font-size: 30px;
    color: #999;
    position: absolute;
    top: 3px;
    left: 20px;
    z-index: 10;
    transition: transform 1s linear;
    transform: rotate(0deg); }
    .BooksContainer .tree-view_arrow:after {
      font-size: 24px;
      font-weight: normal;
      font-style: normal;
      font-stretch: normal;
      line-height: 1.21;
      letter-spacing: normal;
      text-align: left;
      color: #000000; }
  .BooksContainer .tree-view_arrow-collapsed {
    transform: rotate(270deg);
    transition: transform 1s linear; }
  .BooksContainer .tree-view_children {
    margin-left: 40px; }
  .BooksContainer input {
    padding: 3px 5px; }
  .BooksContainer .searchInput {
    background-color: #FFFFFF;
    height: 40px;
    display: flex;
    margin: 10px 16px 0 0;
    position: relative;
    flex: 1 1 auto; }
    .BooksContainer .searchInput input {
      height: 40px;
      padding: 8px;
      border: none;
      background-color: transparent;
      position: relative;
      z-index: 1; }
    .BooksContainer .searchInput .fa-search {
      display: flex;
      align-items: center;
      color: #e4e5e6;
      margin-right: 16px;
      margin-top: 0;
      position: absolute;
      height: 100%;
      right: 0;
      z-index: 0; }
    .BooksContainer .searchInput .fa-close {
      float: right;
      margin-right: 16px;
      margin-top: 0;
      margin-left: 2px;
      position: absolute;
      right: 0;
      z-index: 2;
      cursor: pointer; }
  .BooksContainer .chapters-qty {
    margin: 0 7px;
    display: flex;
    flex-flow: row wrap;
    max-width: 300px;
    bottom: 0;
    justify-content: center;
    align-items: center;
    font-size: 13px;
    font-weight: normal;
    font-style: normal;
    line-height: 1.46;
    text-align: right;
    color: #000000;
    white-space: nowrap; }
    .BooksContainer .chapters-qty .redText {
      color: red; }
    .BooksContainer .chapters-qty .blackText {
      color: black; }
  .BooksContainer .in-chapters-qty {
    font-size: 13px; }
  .BooksContainer .btn-sm {
    background-color: #366FDE; }
  .BooksContainer .btn-secondary {
    color: white; }
  .BooksContainer .actAsChapterContainer, .BooksContainer .filterListContainer {
    margin-left: 8px !important;
    margin-right: 8px;
    margin-left: 15px; }
  .BooksContainer .actAsChapterContainer:hover {
    background-color: #f4f5f6; }
  .BooksContainer .filterListContainer {
    margin-top: 8.5px; }
    .BooksContainer .filterListContainer .label {
      margin-left: 20px;
      color: #7e7e7e; }
    .BooksContainer .filterListContainer .value {
      font-weight: bold;
      margin-left: 5px; }
    .BooksContainer .filterListContainer .fa-eye {
      cursor: pointer;
      font-size: 16px;
      color: #7e7e7e;
      margin: 0 auto; }
      .BooksContainer .filterListContainer .fa-eye.active {
        color: #ffffff; }
    .BooksContainer .filterListContainer .eyeContainer {
      width: 40px;
      display: flex;
      align-items: center;
      cursor: pointer; }
      .BooksContainer .filterListContainer .eyeContainer.active {
        background-color: #707070; }
    .BooksContainer .filterListContainer .infoSection {
      display: flex;
      align-items: center; }
  .BooksContainer .subtree {
    margin-left: 72px; }
  .BooksContainer .studyProgramsContainer {
    cursor: pointer;
    padding-top: 5px;
    padding-right: 8px;
    padding-left: 7.7px;
    border-right: 1px solid #fff;
    border-left: 1px solid #fff;
    font-family: Helvetica;
    font-size: 13px;
    color: #000000; }
    .BooksContainer .studyProgramsContainer:hover {
      background: #0072d7;
      color: #ffffff !important;
      border-radius: 3px;
      border: 1px solid transparent; }
    .BooksContainer .studyProgramsContainer.centerSPs {
      padding-top: 0px;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 13px;
      font-weight: normal;
      font-style: normal;
      font-stretch: normal;
      line-height: 1.23;
      letter-spacing: normal;
      color: #0058ff; }
    .BooksContainer .studyProgramsContainer .studyProgramWithSpace {
      padding: 0 3px 0 3px; }
  .BooksContainer .noClick {
    cursor: default; }
  .BooksContainer .associateRatePlus {
    cursor: pointer;
    display: flex;
    align-items: center;
    height: 100%;
    color: #707070;
    border-right: 1px solid #fff;
    border-left: 1px solid #fff;
    padding-right: 20px;
    padding-left: 20px;
    margin-right: .5rem; }
    .BooksContainer .associateRatePlus i {
      margin: 0; }
  .BooksContainer .TextLink {
    margin-top: 10px;
    margin-left: 20px;
    float: left;
    color: #0058ff; }
  .BooksContainer .addFilterContainer {
    margin-left: 20px;
    cursor: pointer;
    width: 102px;
    height: 31px;
    border: solid 1px #0058ff;
    color: #0058ff;
    position: relative; }
    .BooksContainer .addFilterContainer .fa-plus {
      position: absolute;
      font-size: 9px;
      margin: 11px 33px; }
    .BooksContainer .addFilterContainer .fa-filter {
      position: absolute;
      margin-top: 7px;
      margin-left: 19px; }
    .BooksContainer .addFilterContainer .filterLabel {
      margin-top: 4px;
      margin-left: 48px;
      display: inline-block; }

@media screen and (max-width: 767px) {
  .BooksContainer {
    display: block; } }

@media screen and (max-width: 767px) and (max-width: 767px) {
  .BooksContainer .addFilterContainer {
    display: none; } }

.LinkStyle {
  color: #20a8d8 !important; }
  .LinkStyle:hover {
    color: #167495 !important;
    text-decoration: underline !important; }

.booksPageTop .addBookButton {
  float: right;
  margin-right: 20px;
  cursor: pointer;
  height: 31px;
  background-color: white !important;
  border: solid 1px #0058ff;
  color: #0058ff; }

.booksPageTop .icon {
  margin: 5px;
  margin-left: -3px;
  margin-right: 6px; }

.fix {
  right: 30px !important; }

.BooksContainer .booksPageTop .search .fa-search {
  cursor: pointer;
  color: #95989A;
  float: right;
  position: relative;
  top: -25px;
  right: 10px;
  font-size: 1.3em; }

.linethrough {
  text-decoration: line-through; }

.tooltip-span {
  margin: 0px !important;
  margin-right: 3px !important; }

.rates-tooltip {
  max-width: 200px; }

.ActivityModal .modal-dialog {
  max-width: 800px;
  min-width: auto; }
  .ActivityModal .modal-dialog .text {
    overflow-y: auto;
    max-height: 500px;
    margin-top: 0px !important; }

.ActivityModal .log-item {
  margin-top: 5px;
  margin-bottom: 5px;
  margin-left: 5px;
  padding: 4px;
  background-color: #eaecf0;
  border-bottom-color: #20a8d8;
  font-weight: bold; }

.ActivityModal .closeBtn {
  max-width: 100px;
  margin-right: 0px !important; }

.ActivityModal .card-block {
  padding: 0px;
  margin-bottom: 15px !important; }
  .ActivityModal .card-block .createChapterTitle {
    font-family: Helvetica;
    font-size: 24px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #000000;
    padding-top: 5px; }
    .ActivityModal .card-block .createChapterTitle .fa-times {
      font-size: 16px;
      cursor: pointer;
      float: right;
      opacity: 0.5;
      margin-top: 5px; }

.searchbar-filter {
  background: #eaecf0;
  width: 100%;
  border: solid 1px lightgray;
  margin-left: 8px;
  width: auto;
  min-height: 40px; }

.letter-filter {
  margin-top: 4px;
  max-height: 50%;
  min-height: 40%;
  display: flex;
  width: 100%; }

.individual-letter {
  padding: 2px;
  margin-left: 0.5rem;
  border: solid 1px lightgray;
  max-height: 2rem;
  min-height: 0.5rem;
  width: inherit;
  text-align: center;
  cursor: pointer;
  border-radius: 3px; }

.second-filter {
  height: 50%;
  position: relative;
  width: 100%;
  display: flex; }

.individual-name {
  padding: 2px;
  padding-top: 3px;
  margin-left: 0.5rem;
  border: solid 1px lightgray;
  white-space: nowrap;
  max-height: 2rem;
  min-height: 1rem;
  width: 17%;
  text-align: center;
  cursor: pointer;
  overflow: hidden;
  margin-top: 4px;
  border-radius: 4px;
  background-color: gray; }

.selected {
  background-color: #35caff; }

.arrow-btn {
  max-width: 25px; }

.InputLebel {
  margin-top: 4px; }

.duplicate-block {
  padding: 0px;
  margin-bottom: -5px !important; }
  .duplicate-block .createChapterTitle {
    font-family: Helvetica;
    font-size: 24px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #000000;
    padding-top: 5px; }
    .duplicate-block .createChapterTitle .fa-times {
      font-size: 16px;
      cursor: pointer;
      float: right;
      opacity: 0.5;
      margin-top: 5px; }

.modalDuplicate {
  display: flex; }
  .modalDuplicate .formSearch {
    flex: 1;
    display: 'flex';
    height: '31px'; }
  .modalDuplicate .search {
    width: 100%; }
    .modalDuplicate .search .search-input {
      width: 100%;
      height: 1.8rem;
      background-color: #fff;
      border: 1px solid #aaa;
      border-radius: 4px; }
    .modalDuplicate .search .fa-remove {
      cursor: pointer;
      color: #95989A;
      float: right;
      position: relative;
      top: -25px;
      right: -6px;
      font-size: 1.4em;
      margin-top: 2px; }
  .modalDuplicate .fix {
    right: 30px !important; }
  .modalDuplicate .search .fa-search {
    cursor: pointer;
    color: #95989A;
    float: right;
    position: relative;
    top: -25px;
    right: 10px;
    font-size: 1.3em;
    margin-top: 2px; }
  .modalDuplicate .search .fa-remove.hidden {
    display: none; }
