.usersListContainer .usersTable {
  clear: both;
  padding-top: 10px;
  height: 80vh; }
  .usersListContainer .usersTable > div {
    overflow: hidden !important; }
    .usersListContainer .usersTable > div:hover {
      overflow-y: auto !important; }

.usersListContainer .card-block {
  width: 150%; }

.usersListContainer .searchInput {
  height: 40px;
  background-color: #ffffff; }

.usersListContainer #input {
  height: 40px;
  background-color: transparent;
  border: none !important;
  z-index: 1; }

.usersListContainer .fa-close {
  display: flex;
  align-items: center;
  margin-right: 16px;
  margin-top: 0;
  position: relative;
  z-index: 2;
  cursor: pointer; }

.usersListContainer .fa-search {
  display: flex;
  align-items: center;
  color: #e4e5e6;
  margin-right: 16px;
  margin-top: 0;
  position: absolute;
  height: 100%;
  right: 0;
  z-index: 0; }

.usersListContainer .dropDownfilterByContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 15px;
  margin: auto;
  padding: 0px; }
  .usersListContainer .dropDownfilterByContainer .fa-plus {
    margin: 0;
    margin-top: 1px; }

.usersListContainer .filteredByContainer {
  width: 64%;
  display: flex;
  align-items: center;
  gap: 1rem;
  margin-left: 2rem;
  margin-top: 5px; }
  .usersListContainer .filteredByContainer .clearFilterBy {
    color: red;
    cursor: pointer; }
    .usersListContainer .filteredByContainer .clearFilterBy .fa-close {
      display: inline !important;
      margin-right: 0; }

.usersListContainer .plusContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 15px;
  margin: auto;
  padding: 0px; }
  .usersListContainer .plusContainer .fa-plus {
    margin: 0;
    margin-top: 1px; }

.usersListContainer .pagination {
  padding-top: 20px;
  width: 75%;
  margin: 0 auto; }

.usersListContainer .rowUser {
  background: #EAECEE;
  height: 70px;
  padding: 15px;
  cursor: pointer; }
  .usersListContainer .rowUser:hover {
    background-color: #f4f5f6; }
  .usersListContainer .rowUser.active {
    background-color: #FCFCFC; }

.usersListContainer .createNewAdminBtn {
  float: right;
  margin-right: 70px;
  margin-top: 10px; }

.usersListContainer .btn-primary {
  cursor: pointer; }

.usersListContainer .noPadRight {
  padding-right: 0px !important; }

.usersListContainer .usersListItemsRow {
  margin: 0;
  width: 100%; }

@media (max-width: 768px) {
  .usersListContainer .searchInput {
    width: 100%; }
  .usersListContainer .noPadRight {
    width: 80%; } }

.fa-user-lock {
  float: right; }
