.topics-report {
    justify-content: center;
  }
  
  .topics-report .generate-report {
    margin: 20px auto;
    display: flex;
    align-items: center;
    height: 31px;
    cursor: pointer;
    font-size: 14px;
    color: #0058ff;
    background-color: white;
    border: solid 1px #0058ff;
    justify-content: center;
    width: 140px;
  }
  
  .topics-report .generate-report.disabled {
    background-color: lightgrey;
    color: darkgrey;
    border-color: darkgray;
    cursor: not-allowed;
  }
  
  .topics-report .report-title {
    font-size: 1.2rem;
    font-weight: 700;
    margin-bottom: 15px;
  }
  
  #all-or-none {
    font-weight: 700;
  }
  