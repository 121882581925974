.FooterRow {
  background: white;
  height: 50px;
  display: flex;
  align-items: center;
  bottom: 0;
  width: 100%;
  z-index: 999;
  position: absolute;
  border-top: 1px solid; }
  .FooterRow .not-visible {
    display: none; }
  .FooterRow .cancelButtonPos {
    color: white;
    width: 86px;
    height: 36px;
    background-color: #ff6060; }
  .FooterRow .approveButtonPos {
    width: 86px;
    height: 36px;
    background-color: #0058ff; }
  .FooterRow .reviewButtonsWrapper {
    display: flex;
    position: absolute;
    right: 10px; }
  .FooterRow .buttonContainer {
    position: absolute;
    right: 10px; }
  .FooterRow .spinnerPosition {
    margin-right: 25px !important; }

.viewText {
  font-family: Helvetica;
  font-size: 14px;
  color: #707070;
  align-items: center;
  display: flex;
  margin-right: 10px; }

.ViewContainer {
  margin: 0 auto;
  display: flex;
  height: 100%;
  position: absolute;
  left: 41%; }
  .ViewContainer i {
    cursor: pointer; }

.FooterNav {
  width: 40px;
  display: flex;
  justify-content: center;
  text-align: center;
  height: 100%;
  align-items: center; }

.FooterActive {
  border-top: solid 4px #000000;
  padding-bottom: 5px; }

.btn {
  margin-right: 15px; }

.btn-save {
  border-color: #0562ff;
  color: #0562ff;
  background-color: #fff; }

.btn-publish {
  background-color: #0562ff; }

.btn-danger {
  background-color: #ff6c6b; }
