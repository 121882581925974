.update-subtopics-component form {
  margin-top: 10px;
  margin-bottom: 10px; }

.update-subtopics-component .btn {
  width: 50%;
  height: 40px;
  font-weight: bold; }

.update-subtopics-component .bottom-buttons {
  display: inline-flex;
  float: right;
  margin-bottom: 15px;
  margin-top: 15px; }

.update-subtopics-component .card-block {
  padding-top: 0px;
  margin-bottom: 0px; }

.update-subtopics-component .text {
  margin-bottom: 20px;
  line-height: 170%; }

.update-subtopics-component .errors {
  color: red;
  text-align: center; }

.update-subtopics-component .label {
  display: inline-block;
  font-weight: bold; }

.update-subtopics-component .labelPosition {
  margin-right: 10px; }

.update-subtopics-component .writersSelector {
  width: 100% !important; }

.update-subtopics-component .selectorContainer {
  margin-top: 5px; }

.update-subtopics-component .center-text {
  text-align: center; }

.update-subtopics-component .subtopicCheckbox {
  margin-right: 10px; }

.update-subtopics-component .subtopicsContainer {
  margin: 0;
  padding: 5px;
  cursor: pointer;
  display: flex;
  align-items: center; }
  .update-subtopics-component .subtopicsContainer:hover {
    background-color: #e9e9e9; }
  .update-subtopics-component .subtopicsContainer i {
    margin: 0 5px; }

.update-subtopics-component .topicSelector {
  margin-top: 10px; }

.update-subtopics-component .separatorLine {
  margin-top: 61px;
  height: 1px;
  background-color: #c2cfd6;
  width: 116.3%;
  margin-left: -35px; }

.update-subtopics-component .btn-success {
  margin-right: 0px;
  width: 78px;
  background-color: #366FDE; }

.update-subtopics-component .btn-secondary {
  width: 76px;
  background-color: #A4B7C1; }

.update-subtopics-component .btn {
  border-radius: 0px;
  font-weight: normal;
  height: 33px; }

.update-subtopics-component .noSubtopics {
  color: #707070; }

.update-subtopics-component .scrollableList {
  max-height: 34rem;
  overflow: auto; }
