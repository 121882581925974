.secondHeader {
  background-color: #134199 !important;
  z-index: 3 !important; }

.firstHeader {
  background-color: #042875 !important;
  border: none !important; }

.mb-09 {
  bottom: 0.9rem !important; }

.dropdownContainer {
  position: fixed; }
  .dropdownContainer .dropDownPosition {
    top: 1.9rem !important;
    left: -7.8rem !important; }

.app-header {
  height: 48px !important; }
  .app-header .navbar-brand {
    background-color: #042875 !important;
    height: 48px !important; }
  .app-header .active {
    padding: 13px !important;
    background-color: #134199; }
  .app-header .nav-link {
    padding: 13px;
    color: #ffffff !important; }
  .app-header .px-3 {
    padding: 0px !important; }
  .app-header .profile-photo {
    border-radius: 50px;
    width: 36px;
    height: 36px;
    background-color: #0E307A;
    margin-right: 10px;
    margin-top: 5.3px; }
  .app-header .activeSecondHeader {
    padding: 13px 16px 10px 16px !important;
    border-bottom: 4px solid #ffffff;
    letter-spacing: 0px; }
  .app-header .profile {
    margin-right: 17.5px; }
  .app-header a.nav-link:not(.activeSecondHeader):not(.active):hover {
    background-color: #073690; }
  .app-header li > a {
    padding: 11px 16px 11px 16px !important;
    letter-spacing: 0.15px; }
  .app-header li:first-child {
    padding-left: 0px !important; }
  .app-header .px-3 {
    padding: 0px !important; }
  .app-header .navbar-right-side {
    display: inline-flex;
    margin-left: auto;
    align-items: center; }
    .app-header .navbar-right-side .search-on-header {
      margin-right: 35px;
      display: inline-flex;
      align-items: center;
      background-color: white;
      height: 32px;
      width: 310px;
      border-radius: 3px; }
      .app-header .navbar-right-side .search-on-header .fa {
        padding-left: 10px;
        padding-right: 10px; }
      .app-header .navbar-right-side .search-on-header .fa-close {
        cursor: pointer; }
      .app-header .navbar-right-side .search-on-header input {
        border: none;
        width: inherit; }

.dropdown-toggle {
  background-color: #042875;
  border: none; }

.dropdown-toggle:hover {
  background-color: #042875; }

.dropdown-toggle:focus {
  background-color: #042875 !important; }

@media (max-width: 1499px) {
  .app-header .navbar-right-side .search-on-header {
    display: none; } }
