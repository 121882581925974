.search__container {
  width: 100%;
  background-color: #eaecf0; }

.flexItemsTitlePlus {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-content: center;
  align-items: center; }

.booksPageTitle {
  font-weight: bold !important;
  cursor: pointer;
  padding: 1rem 2rem; }

.searchInput {
  display: flex;
  align-items: center;
  flex: 1;
  position: relative;
  margin-right: 2px; }

.magnifying__icon {
  position: absolute;
  right: 23px;
  filter: grayscale(1);
  color: grey; }

.search__input {
  display: block;
  width: 100%;
  padding: 0.5rem 1rem;
  font-size: 0.875rem;
  line-height: 1.25;
  color: #607d8b;
  background-color: #fff;
  background-image: none;
  background-clip: padding-box;
  border: none;
  border-radius: 0;
  transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
  padding: 8px;
  margin-right: 1rem;
  height: 2.5rem;
  padding-right: 2.5rem; }

.lineBooksPage {
  margin-top: 0;
  width: 88px;
  height: 4px;
  opacity: 0.79;
  background-color: #000000; }

.rate-list__container {
  padding: 0; }

.category__column__container {
  padding: 0; }

.top__container__submitted {
  display: flex;
  flex-direction: row;
  gap: 2rem;
  margin: 0 0 1rem auto;
  align-items: center; }

.unrevised__questions {
  display: block;
  font-weight: bold;
  font-size: 1.2rem;
  width: fit-content; }

.category__container {
  width: 100%;
  height: 3rem;
  border: 0;
  padding: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 4rem;
  background-color: rgba(19, 65, 153, 0.9); }

.category__button__container {
  width: 50%;
  display: grid; }

.category__button {
  color: white;
  font-weight: 600;
  background: none;
  border: 0;
  height: 3rem;
  cursor: pointer; }

.category__button:focus {
  outline: none; }

.category__button:hover {
  background-color: rgba(19, 65, 153, 0.95); }

.category__button__active {
  height: 2.7rem;
  border-bottom: 0.3rem solid white; }

.category__button__user-submitted {
  justify-self: end; }

.category__button__favorites {
  justify-self: start; }

.submitted__questions__header__title {
  margin-right: auto;
  font-size: 1.5rem;
  font-weight: bold; }

.user__submitted__container {
  padding: 25px;
  width: 99%;
  height: 80vh;
  max-height: 80vh; }

.question__list__grid__container {
  overflow-y: scroll;
  height: 79%;
  width: calc(100% + 15px);
  border: 1px solid #a4b7c1; }

.question__list__grid__container--full {
  overflow-y: scroll;
  max-height: 90%;
  width: calc(100% + 15px);
  border: 1px solid #a4b7c1; }

.submitted__questions__grids__container {
  display: flex;
  flex-direction: column;
  gap: 3.5rem;
  height: 100%; }

.questions__submitted__status__container {
  height: 45%; }

.questions__submitted__status__container--full {
  height: 100%; }

.question__item {
  padding: 15px;
  border: 1px solid #a4b7c1;
  position: 'relative';
  display: flex;
  flex-direction: column;
  gap: 0.5rem; }

.question__item__span {
  line-break: auto; }

.question__item__select {
  width: 12rem; }

.question__item__button__container {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  gap: 1rem;
  height: fit-content;
  margin-top: auto;
  margin-bottom: 1rem; }

.question__item__button__accept {
  background-color: #366fde;
  color: white;
  border: 0;
  cursor: pointer;
  padding: 5px 10px; }

.question__item__button__cancel {
  background-color: #a4b7c1;
  color: white;
  border: 0;
  cursor: pointer;
  padding: 5px 10px; }

.question__item__incorrect-answers__container {
  padding-top: 0.5rem;
  display: flex;
  flex-direction: column;
  gap: 0.5rem; }

.question__item__incorrect-answers__list {
  display: flex;
  flex-direction: column;
  gap: 0.5rem; }

.question__item__input__container {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 1rem; }

.question__item__input {
  width: 95%; }

.question__item__input__button {
  background-color: #366fde;
  padding: 5px 10px; }

.question__item__input:disabled {
  background-color: transparent;
  color: #263238;
  border: none; }

.question__item__options {
  display: flex;
  align-items: center;
  gap: 1rem;
  justify-content: flex-end; }

.question__item__expand-button {
  border: 0;
  background: none;
  cursor: pointer;
  height: 1rem;
  width: 2rem; }

.question__item__expand-button:focus {
  outline: none; }

.question__header {
  background-color: rgba(240, 243, 245, 0.5); }

.modal__close-button {
  background: transparent;
  border: 0;
  font-size: 1.5rem;
  cursor: pointer; }

.column--left {
  padding-right: 10px; }

.column--middle {
  padding: 0 10px; }

.column--right {
  padding-left: 10px; }

.options__container {
  display: flex;
  flex-direction: row;
  gap: 2rem;
  justify-content: flex-end;
  align-items: center; }

.action__buttons__container {
  display: flex;
  flex-direction: row;
  width: fit-content;
  margin: 0 0 1rem auto; }

.favorite__list__container {
  overflow-y: auto;
  max-height: 85%;
  padding-bottom: 5px; }

.refresh__button {
  display: flex;
  flex-direction: row;
  gap: 0.5rem;
  align-items: center;
  margin-left: auto; }

.delete__selected__button:disabled {
  cursor: unset; }

.question__item__error {
  color: red; }
