.reports-list .rates-list {
  height: unset;
  padding-bottom: 3rem; }

.reports-list .rates-title .fa-plus {
  margin: 0px 15px !important; }

.reports-list .rate-line .description {
  font-weight: bold;
  color: #000000; }

.reports-list .rate-line .codes {
  font-weight: normal;
  color: #9e9f9f; }

.reports-list .flexItemsTitlePlus {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-content: center;
  align-items: center; }

.reports-list .searchInputContainer {
  min-width: 300px;
  display: flex;
  align-items: center;
  justify-content: flex-end; }
  .reports-list .searchInputContainer .fa-in-search {
    position: absolute;
    right: 50px;
    top: 26px; }
  .reports-list .searchInputContainer .fa-close {
    cursor: pointer; }

.reports-list-row {
  height: 100%;
  flex-wrap: nowrap; }
  .reports-list-row .whiteButtons {
    display: flex;
    align-items: center;
    height: 31px;
    cursor: pointer;
    font-size: 14px;
    color: #0058ff;
    border: solid 1px #0058ff;
    justify-content: center;
    margin-right: 15px;
    width: 140px;
    margin: 0 auto;
    margin-top: 50px; }
  .reports-list-row .manuallyReportButtonWidth {
    width: 350px; }

.select-rate-report {
  margin-left: 3px; }

.d-contents {
  display: contents; }

.report-title {
  font-size: 1.2rem;
  font-weight: 700;
  margin-bottom: 15px; }

.all-or-none {
  font-weight: 700; }

/* Rating exam selector */
.sticky-rating-selector {
  width: 500px;
  height: max-content;
  position: sticky;
  top: 10px;
  z-index: 10; }

/* Button generate report*/
.generate-report {
  margin: 35px auto 50px auto;
  user-select: none;
  display: flex;
  align-items: center;
  height: 2.4rem;
  cursor: pointer;
  font-size: 14px;
  color: #0058ff;
  border: solid 1px #0058ff;
  width: fit-content;
  padding: 1rem;
  transition: all 0.2s ease;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); }

.generate-report.disabled {
  background-color: lightgrey;
  color: darkgrey;
  border-color: darkgray;
  cursor: not-allowed; }

.generate-report:hover {
  background-color: #0058ff;
  color: white; }

.generate-report.disabled:hover {
  background-color: lightgrey;
  color: darkgrey;
  cursor: not-allowed; }

.generate-report:active {
  transform: translateY(2px);
  box-shadow: 0 2px 3px rgba(0, 0, 0, 0.2); }

.generate-report.disabled:active {
  transform: none; }
