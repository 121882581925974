.GroupList {
  background-color: #edeff2;
  height: 100%; }
  @media screen and (max-width: 767px) {
    .GroupList {
      height: auto;
      width: 100%;
      max-width: none; } }

.Group {
  margin-top: 16px; }
  .Group .GroupRowHover:hover {
    background-color: #f4f5f6; }

.GroupRow {
  text-align: center;
  margin-top: 10px;
  justify-content: center;
  margin-left: 15px;
  margin-right: 0px; }

.marginRight14 {
  margin-right: 14px; }

.marginRight18 {
  margin-right: 18px; }

.RateName {
  font-weight: bold;
  padding-right: 16.7px; }

.SelectableName {
  padding-right: 16.7px;
  cursor: pointer;
  height: 40px;
  display: flex;
  align-items: center;
  padding-left: 18px;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none; }
  .SelectableName.active {
    font-weight: bold;
    background-color: #ffffff; }

.TextLinkAtBottom {
  position: relative;
  bottom: 0;
  width: 90%;
  margin-bottom: 10PX; }

.GroupList {
  padding-left: 0px;
  padding-right: 0px; }
  .GroupList .threeDotsMenuContainer {
    margin-top: 0px !important; }
  .GroupList .width100 {
    width: 100%; }
  .GroupList .adminCountNumber {
    float: right;
    margin-right: 15px; }
  .GroupList .globalCountsContainer {
    display: flex;
    width: 100%; }
  .GroupList .countsContainer {
    display: flex; }
  .GroupList .count {
    cursor: pointer;
    display: flex;
    padding: 0 10px 12px;
    justify-content: center;
    align-items: center; }
  .GroupList .noMarginLeft {
    margin-left: 0px !important; }
  .GroupList .activeTab {
    border-bottom: 3px solid rgba(0, 0, 0, 0.79);
    font-weight: bold;
    margin-top: 3px; }
  .GroupList .icons {
    cursor: pointer;
    margin-right: 10px; }
  .GroupList .firstRate {
    margin-top: 10px; }
  .GroupList .ratesContainer {
    padding-bottom: 80px;
    height: 90%;
    padding-left: 10px;
    overflow-y: scroll; }
  .GroupList .isSameAdmin {
    background-color: #f7f8f9; }
  .GroupList .expandIconContainer {
    cursor: pointer;
    margin-left: 18px;
    font-size: 20px;
    align-items: center; }

@media (max-width: 1200px) {
  .RateName {
    width: 60%; } }

@media (max-width: 991px) {
  .RateName {
    width: 50%; } }

.mySPContainer {
  width: 100%;
  text-align: left; }
  .mySPContainer .mySP {
    display: flex;
    justify-content: space-between;
    font-weight: bold;
    padding: 15px;
    cursor: pointer; }
    .mySPContainer .mySP.active {
      background-color: #ffffff; }
    .mySPContainer .mySP:hover {
      background-color: #f4f5f6; }
    .mySPContainer .mySP .count {
      float: right; }
