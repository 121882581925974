[title~="Disabled"] {
  color: #263238; }

[title~="Enabled"] {
  color: #263238; }

.questionGridContainer {
  height: calc(100vh - 200px);
  padding-left: 5px; }
  .questionGridContainer .whitespace-wrap {
    white-space: nowrap; }
  .questionGridContainer .headerContainer {
    display: flex;
    height: 47px;
    background-color: rgba(240, 243, 245, 0.5); }
  .questionGridContainer .tHeader, .questionGridContainer .tRow, .questionGridContainer .tShortCellHeader {
    display: inline-flex;
    align-items: center;
    align-content: center;
    font-family: Helvetica;
    border: solid 1px #a4b7c1;
    overflow: hidden; }
  .questionGridContainer .questionNonModified {
    margin-left: -4px;
    padding: 0.5rem; }
  .questionGridContainer .tHeader, .questionGridContainer .tShortCellHeader {
    padding-left: 12px;
    font-size: 12px;
    color: #707070; }
  .questionGridContainer .tShorCellHeader {
    white-space: pre-wrap; }
  .questionGridContainer .tRow p {
    margin-bottom: 0rem; }
  .questionGridContainer .questionRowContainer {
    display: flex;
    min-height: 47px; }
  .questionGridContainer .bold {
    font-weight: bold; }
  .questionGridContainer .fa-plus {
    color: #0058ff;
    margin-left: 0px; }
  .questionGridContainer .tRowWithOrder {
    align-items: center;
    border: solid 1px #a4b7c1;
    overflow: hidden; }
  .questionGridContainer .orderAnswer {
    width: 100%;
    text-align: center;
    border: 1px solid black; }
  .questionGridContainer .order {
    margin-left: 10px;
    margin-right: 10px; }
  .questionGridContainer .fa-file-pdf-o {
    color: gray; }
  .questionGridContainer .default-img {
    width: 28px;
    height: 26px; }
  .questionGridContainer .blueText {
    color: blue; }
  .questionGridContainer .textWrapper {
    display: inline-block;
    white-space: nowrap;
    width: 58%;
    overflow: hidden;
    margin-right: 5px;
    text-overflow: ellipsis; }
  .questionGridContainer .questionContent {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center; }
  .questionGridContainer .divContent {
    width: 100%;
    text-overflow: ellipsis;
    overflow: hidden; }
  .questionGridContainer .divContent p {
    text-overflow: ellipsis;
    overflow: hidden;
    margin-top: none !important;
    margin-bottom: 0rem !important; }
  .questionGridContainer .textContainer {
    width: 100%; }
  .questionGridContainer .LineThrough {
    text-decoration: line-through; }

.img-question {
  padding-right: 4px; }

.img-preview {
  object-fit: cover;
  padding-right: 4px; }

.question-id {
  padding: 1px; }

.question-number {
  display: none; }

.question-id:hover .presentation-index {
  display: none; }

.question-id:hover .question-number {
  display: block; }

.image-question-preview {
  max-width: 25px !important;
  padding-right: 4px; }

.furtherreading {
  text-align: center !important; }

.pagination-options {
  display: flex;
  align-items: center;
  max-width: 200px; }

.pagination-page-options {
  display: flex;
  align-items: center;
  max-width: 600px;
  overflow: auto; }

.button-pagination {
  padding: 8px;
  background-color: #f7f9fa;
  border-radius: 4px;
  border: solid 1px #a4b7c1;
  height: 39px;
  width: 39px;
  text-align: center;
  cursor: pointer; }

.questionGridContainerScroll {
  overflow: scroll;
  position: fixed;
  height: calc(100vh - 275px);
  width: calc(100vw - 5px);
  padding-bottom: 200px; }

.button-pagination-width-auto {
  padding: 8px;
  background-color: #f7f9fa;
  border-radius: 4px;
  border: solid 1px #a4b7c1;
  text-align: center;
  cursor: pointer; }

.d-webkit {
  display: -webkit-box;
  overflow: auto; }

.columnDirection {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%; }

.rowDirection {
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 100%;
  padding: 0.5rem; }

.originalResponseContainerGrid {
  background-color: #dfd8fd;
  border-top: 0.5px solid #9f8fef;
  padding: 5px;
  height: 100%; }
  .originalResponseContainerGrid span {
    font-weight: 600; }

.gridActionsContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-right: 0.5rem;
  padding-right: 5rem;
  border-bottom: solid 1px #a4b7c1; }

.misspelling {
  text-decoration: red wavy underline; }
