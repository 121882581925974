.CodeGroupList {
  height: 100%; }
  .CodeGroupList .centered {
    text-align: center;
    margin-top: 30px; }
  .CodeGroupList .scrollable {
    height: 100%;
    overflow-y: scroll; }
  .CodeGroupList .CodeGroup {
    height: 52px;
    display: flex;
    background-color: #eaecf0;
    justify-content: space-between;
    align-items: center;
    font-size: 13px;
    color: #000000; }
    .CodeGroupList .CodeGroup .fa-ellipsis-v {
      margin-left: 15px; }
  .CodeGroupList .CodeGroupRow {
    margin: 10px; }
    .CodeGroupList .CodeGroupRow .CodeGroupTitle {
      font-weight: bold;
      margin-left: 10px; }
    .CodeGroupList .CodeGroupRow .Flex {
      display: flex;
      text-align: right;
      align-items: center; }
      .CodeGroupList .CodeGroupRow .Flex .fa-caret-right, .CodeGroupList .CodeGroupRow .Flex .fa-caret-down {
        cursor: pointer; }
    .CodeGroupList .CodeGroupRow .expireDate {
      max-width: 100px;
      margin-left: 10px; }
    .CodeGroupList .CodeGroupRow .paginationContainer {
      margin-top: 15px;
      margin-bottom: 30px; }
    .CodeGroupList .CodeGroupRow .CodeListRow {
      height: 52px;
      border-bottom: solid 1px #eaecf0;
      margin: 0px;
      display: flex;
      padding-left: 15px;
      width: 100%;
      align-items: center;
      justify-content: space-between; }
      .CodeGroupList .CodeGroupRow .CodeListRow .line-through {
        text-decoration: line-through; }
    .CodeGroupList .CodeGroupRow .CodeHeader {
      color: #9e9f9f;
      font-weight: bold; }
  .CodeGroupList .searchInput {
    width: 300px;
    float: right; }
    .CodeGroupList .searchInput .fa-in-search {
      position: absolute;
      right: 0px;
      top: 10px; }
    .CodeGroupList .searchInput .fa-close {
      cursor: pointer; }
  .CodeGroupList .CodeGroupListHeader {
    margin-top: 10px;
    margin-left: 0px;
    margin-right: 0px; }
    .CodeGroupList .CodeGroupListHeader .threeDotsMenuContainer {
      margin: 10px;
      margin-top: 5px;
      z-index: 0; }
    .CodeGroupList .CodeGroupListHeader .whiteButton {
      height: 32px;
      background-color: #ffffff;
      border: solid 1px #0058ff;
      font-size: 14px;
      line-height: 1.21;
      letter-spacing: normal;
      text-align: left;
      width: 190.8px;
      align-items: center;
      color: #0058ff;
      justify-content: center;
      display: flex;
      float: right;
      cursor: pointer; }
      .CodeGroupList .CodeGroupListHeader .whiteButton .fa-plus {
        margin: 0px 10px; }
    .CodeGroupList .CodeGroupListHeader .linkButton {
      color: #387cff !important;
      cursor: pointer;
      font-weight: bold;
      font-size: 14px; }
    .CodeGroupList .CodeGroupListHeader .spanButtonSeparator {
      margin-left: 10px;
      margin-right: 10px;
      color: #d0d6dd; }
    .CodeGroupList .CodeGroupListHeader .spanButton {
      margin-left: 5px;
      margin-right: 5px; }
