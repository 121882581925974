.input-group {
  z-index: 5; }
  .input-group .viewPassword {
    align-items: center;
    cursor: pointer;
    display: flex;
    margin-left: 10px;
    font-size: 20px; }
  .input-group .form-checkbox {
    margin-top: 6px;
    margin-left: 10px; }
  .input-group .label-checkbox {
    font-weight: bold; }
  .input-group #textarea-input {
    height: 50px; }
  .input-group .radio-button-label {
    margin-left: 20px; }
  .input-group .radio-btn {
    margin-top: 3.5px; }

.inlineInput .check {
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 33px;
  background-color: #366fde; }
  .inlineInput .check:hover {
    background-color: #387CFF; }

.inlineInput .cross {
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 33px;
  background-color: #ffffff;
  border: 1px solid #dedede; }
  .inlineInput .cross:hover {
    background-color: #F4F5F6; }

.inlineInput .fa-check {
  color: #ffffff; }

.inlineInput .redBorder {
  border: 1px solid red; }

.btn {
  cursor: pointer; }

.admin-profile .form-control {
  padding: 2px; }
