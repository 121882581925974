.review-history-assigment-component {
  overflow-x: hidden;
  height: 100%; }
  .review-history-assigment-component .title {
    font-weight: bold; }
  .review-history-assigment-component .filterSection {
    margin-top: 10px; }
  .review-history-assigment-component .radioContainer {
    display: inline-block; }
  .review-history-assigment-component .marginRight20 {
    margin-right: 20px; }
  .review-history-assigment-component .radio {
    margin-right: 5px; }
  .review-history-assigment-component .approveButtonPos {
    float: left; }
  .review-history-assigment-component .cancelButtonPos {
    float: right; }
  .review-history-assigment-component .approveCol, .review-history-assigment-component .cancelCol {
    width: 50%; }
