.containerList .noPrompts {
  margin: 15px; }

.containerList .searchInput {
  background-color: white;
  height: 40px;
  display: flex;
  margin: 10px 16px 0 0;
  position: relative;
  flex: 1 1 auto; }
  .containerList .searchInput .fa-search {
    display: flex;
    align-items: center;
    color: lightgray;
    margin-right: 16px;
    margin-top: 0;
    position: absolute;
    height: 100%;
    right: 0;
    z-index: 0; }
  .containerList .searchInput .fa-close {
    float: right;
    margin-right: 16px;
    margin-top: 10px;
    margin-left: 2px;
    position: absolute;
    right: 0;
    z-index: 2;
    cursor: pointer; }

.containerList .promptName {
  padding: 15px;
  margin-top: 10px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap; }
  .containerList .promptName:hover {
    background-color: #f6f5f4; }
  .containerList .promptName.selected {
    background: white;
    font-weight: bold; }

.containerList .plusContainer {
  display: flex;
  align-items: center;
  margin-top: 10px; }
  .containerList .plusContainer .fa-plus {
    margin-top: 1px;
    margin-left: 2px; }

.btn-success.activeLabel {
  font-size: 0.6rem;
  padding: 0.2rem;
  text-align: center;
  border: 1px solid lightgray;
  border-radius: 5px;
  margin-left: 10px;
  white-space: nowrap; }

.btn-success.btnSetActive {
  margin-right: 0rem; }

.cardCreatePrompt {
  padding: 1rem;
  margin-top: 2rem;
  background: #eaecee;
  border: solid 1px black;
  margin-left: 1rem;
  width: 80%; }
  .cardCreatePrompt .buttonContainer {
    margin-top: 1rem;
    text-align: end; }

.btn-primary.btnSave {
  color: white;
  margin-right: 0rem; }

.btn-danger.btnUnsetActive {
  margin-right: 0rem; }

.promptBody {
  height: 200px; }

.lastModifiedLabel {
  color: gray; }
