.usage-statistics-report{
    padding: 1rem;
}
.report-section {
    margin-bottom: 20px;
}

.report-section button {
    background-color: #007bff; 
    color: white;
    border: none;
    padding: 10px;
    cursor: pointer;
    margin-bottom: 10px; 
}

.report-section button:hover {
    background-color: #0056b3;
}

.usage-statistics-report .generate-report {
    user-select: none;
    display: flex;
    align-items: center;
    height: 2.4rem;
    cursor: pointer;
    font-size: 14px;
    color: #0058ff;
    border: solid 1px #0058ff;
    justify-content: center;
    width: fit-content;
    padding: 1rem;
    margin-top: 2rem;
    margin-bottom: 3.5rem;
    transition: all 0.2s ease;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}
.usage-statistics-report .generate-report:hover {
    background-color: #0058ff;
    color: white; 
}
.usage-statistics-report .generate-report:active {
    transform: translateY(2px);
    box-shadow: 0 2px 3px rgba(0, 0, 0, 0.2); 
}
.usage-statistics-report .generate-report-disabled {
    background-color: #cccccc; 
    color: #666666;
    border: 1px solid #999999; 
    cursor: not-allowed; 
    opacity: 0.6; 
    box-shadow: none;
    pointer-events: none;
  }
.usage-statistics-report .generate-report-disabled:hover {
  background-color: #cccccc; /* Mantener el mismo color en hover */
  color: #666666;
}

.usage-statistics-report .datePickers{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: start;
    gap:10px;
    margin-top: 2rem;

}
.usage-statistics-report .datePickers .datePicker{
    padding: 0.5rem;
    font-size: 1rem;
    width: fit-content;
}

.usage-statistics-report .preset-options {
    display: flex;
    gap: 10px;
  }
.usage-statistics-report .preset-options button{
    outline: none;
    user-select: none;
    cursor: pointer;
    padding: 0.3rem;
}

.usage-statistics-report .preset-options button:active {
    transform: translateY(2px);
}

.usage-statistics-report .preset-options button.selected {
    background-color: #0058ff;
    color: white;            
    border: 1px solid black;
}

.latest-created{
  margin-bottom: 20px;
  margin-top: 20px;
  font-size: 1rem;
  color:#007bff;
  cursor: pointer;
}

.filterDate .datePicker{
  padding: 0.5rem;
  font-size: 0.9rem;
  margin-right: 0.6rem;
  min-width: max-content !important;
}

.filterDate .clearFilterBy{
  color: red;
  cursor: pointer;
  border: none;
  background-color: transparent;
  outline: none;
  font-size: 1rem;
}
.filterDate .clearFilterBy .fa-close{
  display: inline !important;
  margin-right: 0
}

.tableContainer{
  height: 50vh;
  overflow-y: auto;
}
table {
  width: 100%;
  border-collapse: collapse;
  margin: 20px 0;
}
th, td {
  padding: 10px;
  text-align: left;
  border: 1px solid #dddddd;
}
th {
  background-color: #f4f4f4;
}
a {
  color: #007bff;
  text-decoration: none;
}
a:hover {
  text-decoration: underline;
}

.background-loading{
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 15px;
  font-size: 1.2rem;
  margin-bottom: 20px;
  margin-top: 20px;
}

.background-loading .spinnerPosition{
  margin: 0 !important;
  padding: 0 !important;
  width: 20px;
}
.background-loading .spinnerPosition .fa {
  font-size: 1.2rem;
}