.maintenance-page {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100vh;
    background-color: #1b215f;
    padding: 0 15%;
  }
  
  .bj-logo {
    width: 200px;
    border-radius: 18px;
    margin: 2rem 0;
    opacity: .9;
  }
  
  .maintenance-title {
    font-family: ubuntu !important;
    color: white;
    font-size: 41px;
    margin-bottom: 0;
  }
  
  .default-content {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .ten-week-content {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .maintenance-description {
    font-family: ubuntu !important;
    color: white;
    text-align: center;
  }
  
  p.maintenance-description {
    font-weight: bold;
    font-size: 20px;
  }
  
  small.maintenance-description {
    margin-top: 2rem;
    font-size: 16px;
  }
  