.statusHeaderContainer {
  margin-top: 15px; }
  .statusHeaderContainer .assignmentsStatusContainer {
    display: flex;
    flex-direction: row; }
  .statusHeaderContainer .statusName {
    cursor: pointer;
    display: flex;
    padding: 0 10px 12px;
    justify-content: center;
    align-items: center; }
  .statusHeaderContainer .activeStatus {
    border-bottom: 3px solid rgba(0, 0, 0, 0.79);
    font-weight: bold; }
