.topics-list-component .list-group-item {
  background-color: #F7F8F9; }

.topics-list-component .list-group-item {
  background-color: #EAECEE;
  border: none !important; }

.topics-list-component .tree-view .list-group-item:hover {
  background-color: #f4f5f6; }

.topics-list-component .tree-view .list-group-item > span {
  display: flex;
  align-items: stretch;
  justify-content: space-between;
  flex: 1 1 auto; }

.topics-list-component .itemName {
  cursor: pointer;
  align-self: center;
  flex: 1 1 auto;
  white-space: nowrap;
  overflow: hidden !important;
  text-overflow: ellipsis;
  font-size: 13px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.54;
  letter-spacing: normal;
  text-align: left;
  color: #000000;
  padding: 0 1rem; }

.topics-list-component .tree-level-0 {
  flex: 1 1 0;
  margin-top: 10px;
  margin-left: 15px;
  padding-left: 15px;
  font-weight: bold;
  background-color: white !important;
  border: none !important; }

.topics-list-component .tree-options {
  font-size: 14px;
  color: grey;
  float: right;
  display: flex; }
  .topics-list-component .tree-options .studyProgramsContainer {
    cursor: pointer;
    padding-top: 5px;
    padding-right: 8px;
    padding-left: 7.7px;
    font-size: 14px;
    color: #000000; }
    .topics-list-component .tree-options .studyProgramsContainer:hover {
      color: #0072d7 !important; }
    .topics-list-component .tree-options .studyProgramsContainer .undo-sp {
      float: left;
      padding: 0px 5px 0px 0;
      margin: -4px 0 0 0; }
    .topics-list-component .tree-options .studyProgramsContainer .redo-sp {
      float: left;
      padding: 0px 5px 0px 0;
      margin: -4px 0 0 0; }

.topics-list-component .tree-level-1 {
  flex: 1 1 0;
  margin-top: 10px;
  padding-left: 40px;
  font-weight: bold;
  overflow-y: hidden; }

.topics-list-component .addBtn {
  margin-left: 0px !important; }

.topics-list-component .sub-tree-item {
  display: flex;
  justify-content: space-between; }
  .topics-list-component .sub-tree-item .tree-options {
    display: flex;
    font-size: 14px;
    color: grey; }
    .topics-list-component .sub-tree-item .tree-options .icon-button {
      padding: 0; }

.topics-list-component .btn-sm {
  background-color: #366FDE;
  margin-left: 5px; }

.topics-list-component .addSubtopicBox {
  padding-left: 0px;
  background-color: white !important;
  border: none !important; }

.topics-list-component .tree-level-2 {
  margin: 5px 0; }
  .topics-list-component .tree-level-2 .tree-options {
    display: flex; }
    .topics-list-component .tree-level-2 .tree-options .icon-button {
      padding: 0; }
    @media screen and (max-width: 767px) {
      .topics-list-component .tree-level-2 .tree-options {
        display: flex; } }
  .topics-list-component .tree-level-2:hover .tree-options {
    display: flex; }

.topics-list-component .tree-view_item {
  cursor: pointer;
  display: flex;
  flex-direction: row; }

.topics-list-component .tree-view_arrow {
  transition: all 0.1s;
  margin: 0;
  font-size: 30px;
  color: #999;
  position: relative;
  top: 4px;
  left: 30px;
  z-index: 10; }

.topics-list-component .subtree {
  margin-left: 72px; }

.topics-list-component .tree-view_children {
  margin-left: 40px; }

.topics-list-component input {
  padding: 3px 5px; }

.topics-list-component .TextLink {
  margin-top: 10px;
  margin-left: 20px;
  float: left; }

.topics-list-component .tree-view {
  clear: both; }

.topics-list-component .subtopics-qty {
  margin: 0 7px;
  white-space: nowrap; }
  @media screen and (max-width: 767px) {
    .topics-list-component .subtopics-qty {
      display: none; } }

.pointer {
  cursor: pointer; }
