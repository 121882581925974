.associate-rates-modal .select2-container {
  width: 100% !important; }

.associate-rates-modal .select2-selection {
  display: block;
  width: 100% !important; }

.associate-rates-modal .separatorLine {
  margin-top: 20px;
  height: 1px;
  background-color: #c2cfd6;
  width: 106.6%;
  margin-left: -15px; }

.associate-rates-modal .btn-success {
  margin-right: 0px;
  width: 78px;
  background-color: #366FDE; }

.associate-rates-modal .btn-secondary {
  width: 76px;
  background-color: #A4B7C1; }

.associate-rates-modal .bottom-buttons {
  display: inline-flex;
  float: right;
  margin-bottom: 15px;
  margin-top: 15px; }
