.modal-question-container {
  padding: 0px !important; }
  .modal-question-container .card-block {
    padding: 0px; }
  .modal-question-container .hrModal {
    width: 600px;
    margin-left: -15px; }
  .modal-question-container .title-container {
    font-family: Helvetica;
    font-size: 24px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #000000;
    padding-top: 5px; }
    .modal-question-container .title-container .fa-times {
      font-size: 16px;
      cursor: pointer;
      float: right;
      opacity: 0.5;
      margin-top: 5px; }
  .modal-question-container .labelForm {
    display: block;
    line-height: 35px;
    margin-bottom: 1rem; }
  .modal-question-container .checkBoxContainer {
    display: flex;
    flex-direction: row;
    align-items: center; }
  .modal-question-container .lebelCheck {
    margin-bottom: 0px !important;
    margin-right: 10px; }
  .modal-question-container .spanPrompts {
    font-style: italic;
    font-size: 10px; }
  .modal-question-container .bottom-buttons-new {
    display: flex;
    justify-content: flex-end; }
    .modal-question-container .bottom-buttons-new .btn-success {
      color: #fff;
      background-color: #3e7ae2;
      border-color: #3e7ae2; }
    .modal-question-container .bottom-buttons-new .btn-secondary {
      color: #263238;
      background-color: #d6dbe1;
      border-color: #d6dbe1; }
    .modal-question-container .bottom-buttons-new .fa-trash {
      position: absolute;
      left: 20px;
      bottom: 27px;
      font-size: 20px;
      color: grey; }
  .modal-question-container .alignItems {
    display: flex;
    align-items: center; }
