.statisticsContainer {
  width: 100%; }
  .statisticsContainer .posts {
    color: #366fde; }
  .statisticsContainer .separatorLineMargin {
    width: 100%;
    margin-top: 19px;
    margin-bottom: 10px; }
  .statisticsContainer .statsTitleMargin {
    margin-top: 20px; }
  .statisticsContainer .cardContentWrapper {
    max-height: calc(100vh - 180px);
    overflow-y: auto !important; }

.userON {
  color: #009900; }

.educationQuestionsContainer {
  margin: 25px 0;
  width: 100%; }

.educationQuestions {
  border: 2px solid #ddd;
  border-radius: 10px;
  padding: 10px 0px 10px 0px;
  background: #ddd;
  text-align: center;
  font-weight: bold;
  width: 100%; }

.educationQuestions-div {
  margin-left: 0;
  justify-content: space-evenly; }

.input-width {
  width: 80% !important; }

.buttons-width {
  width: 20% !important; }

#input-educational-institution {
  border: none;
  background: #ddd;
  font-weight: bold;
  width: 100%; }

.educationLabelInformation {
  font-size: small;
  font-style: italic; }

.notContact {
  margin-top: 20px;
  font-size: 15px;
  font-style: italic; }
  .notContact .buttonSave {
    display: flex;
    justify-content: center; }

.textAreaCancellation {
  margin-top: 10px; }

.change-password-option, .change-password-option:hover {
  color: #1A4BEF;
  font-size: 0.875rem;
  margin-left: 0.25rem;
  text-decoration: underline;
  cursor: pointer; }

.change-password-button {
  margin-left: auto;
  font-size: 0.875rem;
  padding: 0.3rem 0.7rem;
  cursor: pointer; }

.change-password-footer {
  display: flex;
  align-items: center;
  margin-left: auto;
  font-size: 0.875rem;
  padding: 0.3rem 0.7rem;
  cursor: pointer;
  color: #1A4BEF;
  font-size: 0.875rem;
  margin-left: 0.25rem;
  text-decoration: underline;
  cursor: pointer; }

.question__container {
  padding: 25px 0;
  display: flex;
  flex-direction: column;
  width: 100%; }

.question__title {
  margin-bottom: 1rem; }

.question__buttons__container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
  padding-top: 1rem; }

.question__button--block {
  background-color: red;
  color: white; }

.question__button--block:disabled {
  background-color: #FF6666; }

.btn:disabled {
  cursor: default; }
