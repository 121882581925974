.messageCenterContainer {
  height: 100%; }
  .messageCenterContainer .notificationsDetailsContainer {
    overflow: auto;
    height: 100%; }
  .messageCenterContainer .rateListContainer {
    background-color: #eaecee;
    align-items: flex-start;
    overflow: auto;
    background-color: #eaecee;
    padding-right: 0px;
    max-width: 480px;
    height: 100%; }
    .messageCenterContainer .rateListContainer .searchContainer {
      align-items: center;
      justify-content: space-between; }
      .messageCenterContainer .rateListContainer .searchContainer .searchInput {
        height: 40px;
        display: flex;
        margin: 10px 16px 0 20px;
        background-color: #FFFFFF;
        position: relative;
        flex: 1 1 auto; }
        .messageCenterContainer .rateListContainer .searchContainer .searchInput input {
          height: 40px;
          padding: 8px;
          border: none;
          background-color: transparent;
          position: relative;
          z-index: 1; }
        .messageCenterContainer .rateListContainer .searchContainer .searchInput .fa-search {
          display: flex;
          align-items: center;
          color: #e4e5e6;
          margin-right: 16px;
          margin-top: 0;
          position: absolute;
          height: 100%;
          right: 0;
          z-index: 0; }
        .messageCenterContainer .rateListContainer .searchContainer .searchInput .fa-close {
          float: right;
          margin-right: 16px;
          margin-top: 10px;
          margin-left: 2px;
          position: absolute;
          right: 0;
          z-index: 2;
          cursor: pointer; }
      .messageCenterContainer .rateListContainer .searchContainer .fa-plus {
        margin: 0px;
        cursor: pointer; }
    .messageCenterContainer .rateListContainer .rate-name-list {
      margin-bottom: 50px;
      margin-top: 10px;
      height: 100%;
      text-align: start;
      padding-left: 15px; }
      .messageCenterContainer .rateListContainer .rate-name-list .rate-title {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        height: auto !important;
        padding: 15px 16.7px 15px 15px;
        cursor: pointer; }
        .messageCenterContainer .rateListContainer .rate-name-list .rate-title.active {
          background-color: #ffffff; }
      .messageCenterContainer .rateListContainer .rate-name-list .fa-bell {
        color: #f8cb00; }
  .messageCenterContainer .notificationsMessageContainer .addButton {
    float: right;
    margin: 20px;
    cursor: pointer;
    height: 31px;
    background-color: white !important;
    border: solid 1px #0058ff;
    color: #0058ff; }
  .messageCenterContainer .notificationsMessageContainer .icon {
    margin-left: -3px;
    margin-right: 6px;
    margin: 5px; }
