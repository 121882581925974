.ratesListContainer .rolesTable {
  clear: both;
  padding-top: 10px;
  height: 90vh;
  overflow: auto;
  padding-bottom: 10px; }

.ratesListContainer .card-block {
  width: 150%; }

.ratesListContainer .plusContainer {
  display: flex;
  align-items: center; }

.ratesListContainer .pagination {
  width: 75%;
  margin: 0 auto; }

.ratesListContainer .roleRow {
  height: auto !important;
  padding: 15px 16.7px 15px 15px;
  cursor: pointer;
  background: #EAECEE; }
  .ratesListContainer .roleRow.active {
    background-color: #ffffff; }

.ratesListContainer .roleRow:hover {
  background-color: #f4f5f6; }

.ratesListContainer .btn-primary {
  cursor: pointer; }

.ratesListContainer .title, .ratesListContainer .booksPageTitle {
  font-weight: bold !important;
  cursor: pointer; }

.ratesListContainer .title {
  margin-left: 19px; }

.ratesListContainer .booksPageTitle {
  padding: 16px 25px;
  margin-right: 16px; }

.ratesListContainer .line, .ratesListContainer .lineBooksPage {
  margin-top: 0;
  width: 88px;
  height: 4px;
  opacity: 0.79;
  background-color: #000000; }

.ratesListContainer .line {
  width: 72px !important; }

.ratesListContainer .lineBooksPage {
  margin-left: 0px; }

.ratesListContainer .lineQuals {
  margin-left: 85px; }

.ratesListContainer .roleRow {
  width: 100%;
  height: 48px; }

.ratesListContainer .fa-plus {
  margin-top: 16px; }

.ratesListContainer .shortCutsContainer a {
  color: black; }

.ratesListContainer .fa-book {
  float: right;
  margin-right: 10px; }

.ratesListContainer .fa-book, .ratesListContainer .fa-list-ul {
  cursor: pointer;
  margin-top: 5px; }

.ratesListContainer .fa-list-ul {
  float: right; }

.ratesListContainer .PaginationWrapper {
  background: #F0F3F5; }
  @media screen and (max-width: 767px) {
    .ratesListContainer .PaginationWrapper {
      bottom: 0; } }

.ratesListContainer .page-link {
  padding: 0.4rem 0.55rem; }

.ratesListContainer .booksPageHeaderContainer {
  width: 100%; }

.ratesListContainer .flexItemsTitlePlus {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-content: center;
  align-items: center; }

.ratesListContainer .addRate {
  padding-top: 15px !important;
  margin-right: 15px !important;
  color: #95989A; }

.ratesListContainer .titleContainer {
  display: flex; }

.ratesListContainer .ratesTitle, .ratesListContainer .qualsTitle {
  margin-top: 16px;
  font-weight: bold !important;
  cursor: pointer; }

.ratesListContainer .qualsTitle {
  margin-left: 46px; }

.ratesListContainer .ratesTitle {
  margin-left: 16px; }

.ratesListContainer .qualsLine {
  margin-left: 26px !important; }

.ratesListContainer .form-control {
  margin-right: 0px !important; }
