.usersSectionContainer {
  width: 100%;
  height: 100%; }
  .usersSectionContainer .select2-search__field {
    width: 100% !important; }
  .usersSectionContainer .containerRow {
    overflow-y: hidden !important;
    height: 100%; }
    .usersSectionContainer .containerRow .cardContentWrapper {
      max-height: calc(100vh - 205px);
      overflow-y: auto !important; }
  .usersSectionContainer .usersListContainer {
    margin-top: 10px; }
  .usersSectionContainer .usersListSector {
    padding-right: 0px !important;
    padding-left: 0px !important;
    background-color: #f0f3f5; }

.newStudentContainer .separatorLine {
  width: 100%; }

.newStudentContainer .newStudentSeparatorLine {
  width: 100%;
  border: solid 1px #c2cfd6; }

.newStudentContainer .buttonsContainer {
  margin-top: 5px;
  text-align: center;
  padding-bottom: 15px; }

.newStudentContainer .btn-success {
  background-color: #366FDE; }

.newStudentContainer .btn-secondary {
  background-color: #A4B7C1; }

.newStudentContainer .redBorder {
  border: 1px solid red; }

.newStudentContainer .errors {
  color: red; }

.notCycles {
  margin-top: 1rem;
  border: 2px solid #ddd;
  border-radius: 10px;
  padding: 10px;
  background: #ddd;
  text-align: center;
  font-weight: bold; }
