.cardContainer {
  width: 100%; }
  .cardContainer .header {
    width: 100%;
    height: 44px;
    background-color: #f0f3f5;
    border: solid 1px #c2cfd6;
    padding-bottom: 12px;
    padding-top: 12px;
    padding-left: 19px;
    padding-right: 19px;
    border-bottom: none;
    white-space: nowrap; }
  .cardContainer .cardContentWrapper, .cardContainer .cardFooterWrapper {
    width: 100%;
    margin-right: 0px;
    margin-left: 0px;
    border: solid 1px #c2cfd6; }
  .cardContainer .valueName, .cardContainer .value {
    font-size: 14px;
    margin-bottom: 30px; }
  .cardContainer .value, .cardContainer .valueName {
    width: 100%;
    display: inline-block; }
  .cardContainer .value {
    font-weight: bold;
    display: block;
    overflow: hidden;
    text-overflow: ellipsis; }
  .cardContainer .separatorLine {
    height: 1px;
    background-color: #c2cfd6;
    width: 410px; }
  .cardContainer .rateInfo {
    margin-top: 5px; }
  .cardContainer .passwordLabel {
    margin-bottom: 10px; }
  .cardContainer .separatorLineMargin {
    width: 100%;
    margin-top: 5px; }
  .cardContainer .cardFooterWrapper {
    padding-top: 7.5px;
    padding-left: 19.5px;
    height: 55px;
    background-color: #f0f3f5; }
  .cardContainer .cardContentWrapper {
    padding: 1.25rem;
    word-wrap: break-word; }
  .cardContainer .btn-success-roles {
    width: 68px;
    background-color: #366fde; }
  .cardContainer .btn-cancel-roles {
    width: 80px;
    background-color: #a4b7c1;
    border: none; }
  .cardContainer .activityHeader {
    float: left;
    color: #366fde;
    position: relative;
    bottom: 21px;
    margin-left: 65px; }
  .cardContainer .newStudentWrapper {
    background-color: #F0F3F5; }
  .cardContainer .responsive-col-7 {
    width: 58.33333% !important; }
  @media (min-width: 576px) {
    .cardContainer .middleColumn {
      padding-left: 15px !important; } }
  @media (min-width: 768px) {
    .cardContainer .middleColumn {
      padding-left: 1.25em !important; } }
  @media (min-width: 992px) {
    .cardContainer .middleColumn {
      padding-left: 1.25em !important; } }
