.threeDotsMenuContainer, .threeDotsMenuContainer2 {
  float: right;
  position: relative; }
  .threeDotsMenuContainer .box, .threeDotsMenuContainer2 .box {
    z-index: 20; }
  .threeDotsMenuContainer .title, .threeDotsMenuContainer2 .title {
    font-weight: bold;
    letter-spacing: 0.4px;
    text-align: left;
    color: #536c79;
    padding: 5px;
    text-align: center;
    background-color: #EDF0F3;
    width: 180px;
    height: 37px;
    border: 1px solid #c2cfd6; }
  .threeDotsMenuContainer .closeIconContainer, .threeDotsMenuContainer2 .closeIconContainer {
    display: inline-block;
    float: right; }
  .threeDotsMenuContainer .titleContent, .threeDotsMenuContainer2 .titleContent {
    padding-left: 16px;
    display: inline-block; }
  .threeDotsMenuContainer .option, .threeDotsMenuContainer2 .option {
    white-space: pre-wrap;
    z-index: 100;
    cursor: pointer;
    text-align: center;
    padding: 5px;
    width: 180px;
    border: 1px solid #c2cfd6;
    background-color: #ffffff; }
  .threeDotsMenuContainer .icons, .threeDotsMenuContainer2 .icons {
    cursor: pointer; }
  .threeDotsMenuContainer .fa-gear, .threeDotsMenuContainer2 .fa-gear {
    margin-left: 15px; }
  .threeDotsMenuContainer .fa-close, .threeDotsMenuContainer2 .fa-close {
    cursor: pointer; }
  .threeDotsMenuContainer .fa-trash, .threeDotsMenuContainer .fa-pencil, .threeDotsMenuContainer .fa-exchange, .threeDotsMenuContainer .fa-history, .threeDotsMenuContainer .fa-arrow-circle-up, .threeDotsMenuContainer .fa-arrow-circle-down, .threeDotsMenuContainer .fa-undo, .threeDotsMenuContainer2 .fa-trash, .threeDotsMenuContainer2 .fa-pencil, .threeDotsMenuContainer2 .fa-exchange, .threeDotsMenuContainer2 .fa-history, .threeDotsMenuContainer2 .fa-arrow-circle-up, .threeDotsMenuContainer2 .fa-arrow-circle-down, .threeDotsMenuContainer2 .fa-undo {
    color: grey;
    float: left;
    margin-right: 20px; }
  .threeDotsMenuContainer .fa-pencil, .threeDotsMenuContainer .fa-exchange, .threeDotsMenuContainer .fa-history, .threeDotsMenuContainer .fa-arrow-circle-up, .threeDotsMenuContainer .fa-arrow-circle-down, .threeDotsMenuContainer .fa-undo, .threeDotsMenuContainer2 .fa-pencil, .threeDotsMenuContainer2 .fa-exchange, .threeDotsMenuContainer2 .fa-history, .threeDotsMenuContainer2 .fa-arrow-circle-up, .threeDotsMenuContainer2 .fa-arrow-circle-down, .threeDotsMenuContainer2 .fa-undo {
    margin-left: 10px; }

.shadow {
  box-shadow: #666 0px 0px 5px; }

.mask {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 3;
  width: 100%;
  height: 100%; }

.positionCalendar {
  margin-right: 18px;
  padding-top: 1px;
  font-size: 1.25rem !important; }
