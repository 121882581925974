.assignmentsByWriter {
  overflow: auto; }
  .assignmentsByWriter .AA-assignments-count {
    color: red; }
  .assignmentsByWriter .writers {
    text-align: left;
    padding: 5px;
    padding-left: 0px;
    width: 100%; }
    .assignmentsByWriter .writers .writer {
      cursor: pointer;
      font-weight: bold; }

.align-left {
  justify-content: left !important;
  margin-left: 15px; }

.writer {
  display: -webkit-flex;
  -webkit-align-items: center;
  padding-left: 10px;
  padding-right: 20px;
  align-content: bottom;
  height: 40px;
  width: 100%;
  cursor: pointer;
  display: flex;
  align-items: center; }

.writer.active {
  background-color: #ffffff; }

.filterName {
  float: left;
  font-weight: bold;
  margin-bottom: 15px; }

.noAssignmentsText {
  clear: both;
  text-align: left;
  font-style: italic;
  color: #7e7e7e; }

.statusTableContainer {
  margin-top: 30px; }

.assignmentsTables {
  overflow: auto;
  height: 100%;
  font-size: x-small; }
  .assignmentsTables .assignmentsBtnRow {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 25px; }
    .assignmentsTables .assignmentsBtnRow .assignmentsBtn {
      float: right;
      cursor: pointer;
      display: flex;
      align-items: center;
      align-content: center;
      width: 70px;
      height: 36px;
      border: solid 1px #0058ff; }
    .assignmentsTables .assignmentsBtnRow .assignmentsBtnText {
      margin: 0 auto;
      color: #0058ff; }

.box {
  font-size: 0.875rem; }

@media all and (min-width: 767px) {
  .writer {
    padding-right: 10px; } }

@media all and (min-width: 910px) {
  .assignmentsTables {
    font-size: small; } }

@media all and (min-width: 1200px) {
  .assignmentsTables {
    font-size: 0.875rem; } }
