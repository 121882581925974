.quals-content-container {
  padding-left: 0px;
  flex: 1 1; }
  @media screen and (max-width: 767px) {
    .quals-content-container {
      width: 100%; } }
  .quals-content-container .tree-options .tooltip-title {
    color: #999; }
  .quals-content-container .tree-options .react-tooltip {
    border: 1px solid #EAECEE; }
  .quals-content-container .tree-options .tooltip-text {
    color: black; }
  .quals-content-container .tree-options .tooltip-icon {
    font-size: 25px; }
  .quals-content-container .tree-options .tooltip-icon-add {
    font-size: 22px;
    color: #0558ff;
    cursor: pointer; }
  .quals-content-container .tree-options .pdfImage {
    width: 20px;
    margin-left: 10px;
    margin-right: 10px;
    cursor: pointer; }
  .quals-content-container .itemName {
    cursor: pointer;
    align-self: center;
    flex: 1 1 auto;
    white-space: nowrap;
    overflow: hidden !important;
    text-overflow: ellipsis;
    font-size: 13px;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.54;
    letter-spacing: normal;
    text-align: left;
    color: #000000;
    padding: 0 1rem;
    width: auto !important; }
    .quals-content-container .itemName .NameWithArrows {
      display: flex;
      align-items: center; }
      .quals-content-container .itemName .NameWithArrows .MoveRowUpDown {
        margin-right: 10px;
        margin-left: 5px;
        color: #7b7b7b; }
    .quals-content-container .itemName .chapter-name {
      font-size: 13px;
      font-weight: normal;
      font-style: normal;
      font-stretch: normal;
      line-height: 1.54;
      letter-spacing: normal;
      text-align: left;
      color: #000000;
      margin-left: 15px; }

.create-reference-btn-container {
  float: right;
  margin-top: 30px;
  margin-right: 30px; }

.rates-content-container {
  padding-left: 0px;
  flex: 1 1; }
  @media screen and (max-width: 767px) {
    .rates-content-container {
      width: 100%; } }

.icon-button {
  cursor: pointer;
  padding: 0 .75rem;
  display: flex;
  align-items: center; }

.nav .active {
  font-weight: bold; }

.rates-list-container {
  max-width: 250px;
  text-align: center;
  height: 100%;
  overflow: auto;
  background-color: #eaecee; }
  @media screen and (max-width: 767px) {
    .rates-list-container {
      height: auto;
      width: 100%;
      max-width: none; } }

.bibliographies-secondHeader {
  background-color: #d3d3d3; }

.listsContainer {
  margin-top: 60px;
  margin-left: 2%; }

.buttonsContainer {
  padding-left: 15px;
  margin-top: 20px; }

.add {
  margin-right: 10px; }

.select-all-option {
  margin-top: 40px; }

.subtopics-list {
  width: 500px;
  margin-top: 20px; }

.create-reference-btn-container {
  float: right;
  margin-top: 30px;
  margin-right: 30px; }

.gridContainer {
  margin: 30px; }

.ReactTable .rt-tr:hover {
  background: #e2e2e2; }

.ReactTable .rt-tr:hover .settingsRow {
  visibility: visible; }

.statusLabel {
  z-index: 1000;
  cursor: pointer !important;
  color: #20a8d8; }

.fa-trash {
  cursor: pointer; }

.fa-gear, .fa-file-pdf-o {
  cursor: pointer; }

.circleSize {
  cursor: pointer;
  width: 15px;
  height: 15px; }

.circleSizeArrow {
  position: absolute;
  margin-top: 5px;
  cursor: pointer;
  width: 15px;
  height: 15px; }

.circleSizeManualRed {
  width: 16px;
  height: 15px; }

.fa-plus {
  margin: 11px 22px;
  cursor: pointer; }

.noMargins {
  margin: 0 !important; }

.settingsRow {
  visibility: hidden; }

.quals-spinner {
  position: absolute;
  top: 50%;
  left: 50%;
  height: 30%;
  width: 50%;
  margin: -15% 0 0 -25%; }
