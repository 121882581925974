input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type='number'] {
  -moz-appearance: textfield;
  appearance: textfield;
}

.input {
  max-width: 100px;
  text-align: end;
}

.is-invalid {
  border: 2px solid red;
  border-radius: 5px;
}

.invalid-feedback {
  font-size: 12px;
  margin-top: 5px;
}
