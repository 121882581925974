.definitions-list-component .headerRow, .definition-list-component-question .headerRow {
  border-top: 0 !important; }

.definitions-list-component .row, .definition-list-component-question .row {
  border-top: 1px solid #ecedee;
  padding-top: 8px;
  padding-bottom: 8px; }

.definitions-list-component .rowStriped, .definition-list-component-question .rowStriped {
  background-color: #edf2f5; }

.definitions-list-component .fa-trash, .definitions-list-component .fa-plus, .definitions-list-component .fa-trash, .definitions-list-component .fa-times, .definitions-list-component .fa-check, .definition-list-component-question .fa-trash, .definition-list-component-question .fa-plus, .definition-list-component-question .fa-trash, .definition-list-component-question .fa-times, .definition-list-component-question .fa-check {
  margin-right: 8px;
  cursor: pointer; }

.definitions-list-component .form-group, .definitions-list-component .mb-3, .definition-list-component-question .form-group, .definition-list-component-question .mb-3 {
  margin-bottom: 0px !important; }

.definitions-list-component .editableButtons, .definition-list-component-question .editableButtons {
  display: flex;
  align-items: center; }

.definitions-list-component .closeButton, .definition-list-component-question .closeButton {
  font-size: 30px;
  line-height: 1px;
  text-align: right;
  cursor: pointer;
  color: gray; }

.definitions-list-component .textRight, .definition-list-component-question .textRight {
  text-align: right; }

.definitions-list-component .errors, .definition-list-component-question .errors {
  color: red;
  text-align: center;
  position: relative; }

.definitions-list-component .definition-text, .definition-list-component-question .definition-text {
  margin-left: 5px; }

.definitions-list-component {
  max-height: 60vh;
  overflow-y: auto;
  overflow-x: hidden; }

.definition-list-component-question .definitions-container {
  height: 30vh;
  overflow-y: scroll;
  overflow-x: hidden; }

.definition-list-component-question .btn {
  position: relative;
  margin-top: 10px;
  display: block;
  float: right; }
