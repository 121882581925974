.comments-display {
  padding: 5px 10px 5px 10px;
  border-radius: 4px;
  margin-left: 20%; }

.comment-show {
  background-color: #63c2de; }

.comment-none {
  background-color: #eceff1; }

.w13 {
  width: 13%; }

.w15 {
  width: 15%; }

.containerAssignmentsBtn {
  display: flex;
  justify-content: flex-end; }

.assignmentsTableContainer {
  margin-top: 20px;
  margin-left: 15px;
  margin-right: 15px; }
  .assignmentsTableContainer .referenceTitle {
    padding-right: 10px;
    word-wrap: anywhere; }
  .assignmentsTableContainer .referenceTitle.rateColumn {
    justify-content: center; }
  .assignmentsTableContainer .headerContainer {
    clear: both;
    background-color: rgba(240, 243, 245, 0.5); }
  .assignmentsTableContainer .tHeader {
    text-align: left;
    display: inline-flex;
    align-items: center;
    align-content: center; }
  .assignmentsTableContainer .assignmentRow, .assignmentsTableContainer .headerContainer {
    padding-right: 10px;
    padding-left: 10px;
    display: flex;
    align-items: center;
    min-height: 47px;
    border: solid 1px #a4b7c1; }
  .assignmentsTableContainer .assignmentRow .react-datepicker__input-container input {
    border: none;
    cursor: pointer; }
  .assignmentsTableContainer .assignmentsBtn {
    cursor: pointer;
    display: flex;
    align-items: center;
    align-content: center;
    width: 70px;
    height: 36px;
    border: solid 1px #0058ff; }
  .assignmentsTableContainer .assignmentsBtnText {
    margin: 0 auto;
    color: #0058ff; }
  .assignmentsTableContainer .react-datepicker__input-container {
    width: 100% !important; }

.createGPTquestions {
  background: transparent;
  display: flex;
  align-items: center;
  text-align: center;
  border: 0.5px solid #4dbd74;
  color: #4dbd74;
  cursor: pointer;
  padding: 6px;
  margin: 6px; }

.textButtonAndDrop {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%; }

.creatingAIquestions {
  border: 0.5px solid #9f8fef;
  background: #dfd8fd;
  padding: 6px;
  margin: 6px;
  gap: 6px;
  display: flex;
  flex-direction: row;
  align-items: center;
  position: absolute; }
