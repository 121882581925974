.SingleDatePickerInput__calendar-icon {
  margin: -3px 5px 0 10px !important; }

.SingleDatePicker__picker {
  z-index: 2000 !important; }

.SingleDatePickerInput {
  width: 170px !important;
  height: 35px !important; }

.DateInput {
  font-weight: 100 !important;
  font-size: 18px !important;
  line-height: 20px !important;
  padding: 0px !important;
  width: 115px !important; }

.simple-datepicker-container .red input {
  color: red; }

.simple-datepicker-container .exam {
  width: 170px !important;
  height: 35px !important;
  background-color: #fff;
  border: 1px solid #dbdbdb; }
