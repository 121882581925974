.import-component {
  height: 60vh; }
  .import-component .title {
    display: flex;
    justify-content: space-between;
    font-size: 24px; }
  .import-component .titleHR {
    width: 600px;
    margin-left: -40px; }
  .import-component ._react-file-reader-input {
    margin-bottom: 30px; }
  .import-component .question-wrapper {
    margin-top: 10px; }
    .import-component .question-wrapper p {
      font-weight: bold; }
    .import-component .question-wrapper .question-container {
      height: calc(60vh - 220px);
      overflow-y: auto; }
      .import-component .question-wrapper .question-container .question-line {
        width: 95%;
        overflow-y: auto;
        overflow-x: hidden;
        white-space: nowrap;
        text-overflow: ellipsis; }
