.ActivityContainer .link {
  color: #366fde; }

.ActivityContainer .bold {
  font-weight: bold; }

.ActivityContainer .separatorLine {
  margin-top: 9px;
  margin-left: 15px;
  width: 100%; }

.ActivityContainer .paginationContainer {
  margin-top: 9px;
  margin-left: 0 !important;
  margin-right: 0 !important;
  display: flex;
  align-items: center;
  align-content: center;
  text-align: center;
  width: 115%;
  height: 64px;
  background-color: #f0f3f5;
  border: solid 1px #c2cfd6; }

.ActivityContainer .cardContentWrapper {
  height: 400px;
  overflow-y: auto !important;
  overflow-x: hidden !important; }

.ActivityContainer .no-activity {
  min-height: 150px;
  text-align: center;
  width: 100%; }

.ActivityContainer .cardContentWrapper .row {
  margin-left: 0 !important;
  margin-right: 15px !important; }

.ActivityContainer .threeDotsMenuContainer {
  margin-top: -25px;
  position: absolute;
  z-index: 5;
  right: 0px; }

.ActivityContainer .threeDotsMenuContainer2 {
  margin-top: -25px;
  position: absolute;
  z-index: 5;
  right: 0px; }

.ActivityContainer div.react-datepicker__input-container {
  width: 100% !important; }

.ActivityContainer div.react-datepicker__input-container input {
  text-align: center; }

.ActivityContainer .paddingRow {
  width: 100%;
  margin-top: -10px; }
