.cardContentWrapper {
  max-height: calc(100vh - 220px);
  overflow-y: auto !important; }

.roles-spinner {
  position: absolute;
  top: 30%;
  left: 25%;
  height: 30%;
  width: 50%;
  margin: -15% 0 0 0; }
