.modal, .fade {
  opacity: 1; }

.modal-content {
  margin-top: 50px; }

.modal.fade .modal-dialog, .modal.in .modal-dialog {
  -webkit-transform: translate(0, 0);
  -ms-transform: translate(0, 0);
  transform: translate(0, 0); }

.modal {
  background: rgba(0, 0, 0, 0.5); }

.modal-question-definitions .modal-dialog {
  max-width: 900px !important; }

.viewComments .modal-dialog {
  max-width: 900px !important; }

.modal-add-subtopic .modal-content {
  background-color: #F7F8F9; }

.modal-add-subtopic .modal-body {
  padding-top: 0px; }

.modal-add-subtopic .modal-dialog {
  max-width: 1000px !important; }

.modal-associate-rates .modal-content {
  background-color: #F7F8F9; }

.AddFilterModal .modal-dialog {
  max-width: 600px; }

.AddFilterModal .modal-content {
  margin-top: 250px; }
  .AddFilterModal .modal-content .fa-times {
    opacity: 0.5;
    color: #000000;
      color-cursor: pointer; }
  .AddFilterModal .modal-content .fa {
    font-size: 16px !important;
    cursor: pointer; }
  .AddFilterModal .modal-content .addFilterTitle {
    font-size: 24px;
    color: #000000; }
  .AddFilterModal .modal-content .btn-success {
    margin-right: 8px !important;
    background-color: #366FDE; }
  .AddFilterModal .modal-content .btn-secondary {
    background-color: #A4B7C1; }
