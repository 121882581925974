.HeaderRow {
  min-height: 108px;
  display: flex;
  align-items: center;
  padding: 10px;
  border-bottom: solid 1px #c2cfd6; }
  .HeaderRow a {
    padding-bottom: 5px;
    color: #838383;
    cursor: pointer; }
  .HeaderRow a:hover {
    text-decoration: none; }
  .HeaderRow .pdf-upload input {
    display: none; }
  .HeaderRow .pdf-upload {
    display: inline-block;
    margin-left: 10px; }
  .HeaderRow .fa-plus {
    margin: 0px; }
  .HeaderRow .DisabledChapterBar {
    width: 100%;
    margin-bottom: 10px;
    background: #f29c99;
    color: white;
    height: 30px;
    font-size: 16px;
    font-weight: bold;
    align-items: center;
    justify-content: center;
    display: flex; }

.noPaddingTop {
  padding-top: 0px !important; }

.Pointer {
  cursor: pointer; }

.ReferenceName {
  font-family: Helvetica;
  font-size: 14px;
  color: #000000; }

.SubtopicBox {
  border: solid 1px #c2cfd6;
  display: inline-block;
  padding: 5px;
  margin-left: 5px; }
  .SubtopicBox i {
    color: #c2cfd6; }
  .SubtopicBox .fa-times {
    cursor: pointer; }
  .SubtopicBox .questionImageUrl {
    color: #696666;
    text-decoration: none; }

.PlusBox {
  border: solid 1px #0058ff;
  display: inline-block;
  padding: 5px;
  width: 39px;
  text-align: center;
  margin-left: 5px;
  cursor: pointer; }
  .PlusBox i {
    color: #0058ff;
    margin-left: 0px; }

.Definitions {
  font-family: Helvetica;
  font-size: 14px;
  color: #0058ff;
  display: inline-block;
  float: right;
  padding-top: 8px;
  cursor: pointer; }

.header__title {
  display: flex;
  flex-direction: row;
  justify-content: space-between; }

.header__left {
  display: flex;
  flex-direction: row;
  gap: 15px; }

.header__right {
  display: flex;
  flex-direction: row;
  gap: 50px; }
