.all-csv-report {
  justify-content: center;
}

.all-csv-report .generate-report {
  margin: 20px auto;
  display: flex;
  align-items: center;
  height: 31px;
  cursor: pointer;
  font-size: 14px;
  color: #0058ff;
  border: solid 1px #0058ff;
  justify-content: center;
  width: 140px;
  background-color: white;
}

.all-csv-report .generate-report.disabled {
  background-color: lightgrey;
  color: darkgrey;
  border-color: darkgray;
  cursor: not-allowed;
}

.all-csv-report .report-title {
  font-size: 1.2rem;
  font-weight: 700;
  margin-bottom: 15px;
}

#all-or-none {
  font-weight: 700;
}
