.columnCell {
  display: flex;
  align-items: center;
  justify-content: center; }

.assignButton {
  float: right;
  margin-bottom: 10px; }

.centeredCol {
  margin: 0;
  width: 98%;
  padding-top: 15px;
  text-align: center;
  padding-right: 0px; }
  .centeredCol .filterName {
    float: left;
    font-weight: bold;
    margin-bottom: 15px; }
  .centeredCol .statusTableContainer {
    margin-top: 30px; }
  .centeredCol .noAssignmentsText {
    clear: both;
    text-align: left;
    font-style: italic;
    color: #7e7e7e; }
  .centeredCol .assignmentsBtn {
    float: right;
    margin-right: 18px;
    cursor: pointer;
    display: flex;
    align-items: center;
    align-content: center;
    width: 70px;
    height: 36px;
    border: solid 1px #0058ff; }
  .centeredCol .assignmentsBtnText {
    margin: 0 auto;
    color: #0058ff; }

.myAssignmentList {
  padding-left: 0px;
  padding-right: 0px;
  overflow: hidden; }
  .myAssignmentList:hover {
    overflow-y: scroll; }
