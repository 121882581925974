.form-group-marginTop {
  margin-top: 10px; }

.individualExam-contianer {
  width: 95%;
  margin-left: 2.5%;
  margin-right: 2.5%;
  margin-bottom: 10px; }

.individualExam-contianer .individualExam-text {
  font-size: small;
  font-style: italic; }

.width-auto {
  width: auto; }

.ml-10 {
  margin-left: 10px; }

.mt-10 {
  margin-top: 10px; }
