.copy-reference-component form {
  margin-top: 10px;
  margin-bottom: 10px; }

.copy-reference-component .btn {
  width: 50%;
  height: 40px;
  font-weight: bold; }

.copy-reference-component .bottom-buttons {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 15px;
  margin-right: 15px; }

.copy-reference-component .card-block {
  margin-bottom: 35px; }

.copy-reference-component .text {
  margin-bottom: 20px;
  line-height: 170%; }

.copy-reference-component .errors {
  color: red;
  text-align: center; }

.copy-reference-component .label {
  display: inline-block;
  font-weight: bold; }

.copy-reference-component .labelPosition {
  margin-right: 15px;
  margin-bottom: 10px; }
