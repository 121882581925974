.subnavbar-component {
  position: static !important;
  flex-direction: row;
  height: 40px;
  margin: 12px 0 0;
  padding: 0;
  background-color: #fff;
  border-bottom: 1px solid #cfd8dc; }
  .subnavbar-component .span-clickable {
    cursor: pointer; }

.light-grey {
  background-color: #f7f9fa; }

.dark-grey {
  background-color: #ecedee; }

.light-grey, .dark-grey {
  padding-top: 10px; }

.noMargin {
  margin-top: 0px !important; }
