.searchInputText {
  background-color: #ffffff;
  height: 40px;
  flex: 1 1 auto;
  position: relative;
  display: flex;
  align-items: center; }
  .searchInputText input {
    padding: 8px;
    border: none;
    height: 40px;
    background: transparent;
    position: relative;
    z-index: 1; }
  .searchInputText .fa-search {
    display: flex;
    align-items: center;
    color: #e4e5e6;
    margin-right: 16px;
    margin-top: 0;
    position: absolute;
    height: 100%;
    right: 0;
    z-index: 0; }
  .searchInputText .fa-search.tenWeeksearchIcon {
    margin-right: 0px;
    position: relative; }
  .searchInputText .fa-close {
    float: right;
    margin-right: 16px;
    margin-top: 0;
    margin-left: 2px;
    position: absolute;
    right: 0;
    z-index: 2;
    cursor: pointer; }
  .searchInputText .fa-close.tenWeekCloseIcon {
    position: relative;
    margin: 0px 0px 0px 0px; }
  .searchInputText .searchBox {
    display: inline-block;
    width: 61%;
    padding: 0.5rem 1rem;
    font-size: 0.875rem;
    line-height: 1.25;
    color: #607d8b;
    background-color: #fff;
    background-image: none;
    background-clip: padding-box;
    border: 1px solid rgba(0, 0, 0, 0.15);
    border-radius: 0;
    transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s; }

.tenWeekPage .statusHeaderContainer {
  margin: 0px;
  padding-top: 10px; }

.tenWeekPage .searchInputText {
  margin-left: 5px; }

.tenWeekPage .fa-plus {
  margin: 12px 0px 12px 12px;
  display: flex; }

.tenWeekPage .fa-plus.tenWeekPlus {
  margin: 12px 0px 12px 12px !important; }

.tenWeekPage .fa-newspaper-o {
  margin: 12px 0px 12px 12px;
  display: flex;
  cursor: pointer; }

.build-all-exams-button {
  margin: 0 0 0 15px !important; }
