.daily-table-container {
  margin-top: 20px;
  overflow-x: auto;
  max-height: 343px;
  min-width: 450px;
  border: 1px solid #ddd; }
  .daily-table-container .table-daily {
    width: 100%;
    border-collapse: collapse; }
    .daily-table-container .table-daily .table-daily-header th {
      position: sticky;
      top: 0;
      background-color: #f2f2f2;
      border-bottom: 2px solid #ddd;
      padding: 8px;
      text-align: left;
      font-weight: bold;
      z-index: 1; }
    .daily-table-container .table-daily .table-daily-body th {
      border-bottom: 1px solid #ddd;
      padding: 8px;
      text-align: left; }
    .daily-table-container .table-daily .table-daily-body tr:nth-child(odd) {
      background-color: #f9f9f9; }
    .daily-table-container .table-daily .table-daily-body tr:nth-child(even) {
      background-color: #eeeeee; }
    .daily-table-container .table-daily .table-daily-body tr:hover {
      background-color: #d1e7ff; }
