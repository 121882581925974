.app-body {
  background-color: white; }

.breadcrumb {
  margin-bottom: 0px; }

.navbar-brand {
  width: 70px !important;
  background-color: #021e5f !important;
  border-bottom: none !important;
  padding: 4px 10px !important;
  background-image: none !important;
  text-align: center !important; }

.logo {
  height: 100% !important; }

.app-header.navbar .navbar-nav {
  height: 100%; }
  .app-header.navbar .navbar-nav .nav-item {
    height: 100%; }
    .app-header.navbar .navbar-nav .nav-item .nav-link {
      padding: 5px;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center; }
    .app-header.navbar .navbar-nav .nav-item .dropdown {
      height: 100%; }

.main .container-fluid {
  height: 100%;
  width: 100%;
  padding-left: 0px;
  padding-right: 0px; }

tr:hover {
  background-color: #dcf5f3; }

.app-body {
  overflow: hidden;
  z-index: 2;
  align-content: flex-start; }
  .app-body .main {
    overflow: hidden; }

.header-fixed .app-body {
  margin-top: 0px !important; }

.noSecondHeader {
  height: 100%;
  background-color: white;
  overflow: hidden;
  z-index: 2; }

.noMarginTopContent .app-body {
  margin-top: 0px !important; }

.devBar {
  background-color: red;
  text-align: center;
  font-size: 14px;
  font-weight: bold;
  color: white; }

.stagingBar {
  background-color: orange;
  text-align: center;
  font-size: 14px;
  font-weight: bold;
  color: white; }

@media (max-width: 991px) {
  .app-header .navbar-toggler {
    color: white !important; } }

@media (max-width: 1249px) {
  .usersSectionContainer .containerRow {
    overflow-y: auto !important; } }
