.options-list .rates-title .fa-plus {
  margin: 0px 15px !important; }

.options-list .rates-title .line-quals-page {
  width: 150px; }

.options-list .rate-line .description {
  font-weight: bold;
  color: #000000; }

.options-list .rate-line .codes {
  font-weight: normal;
  color: #9e9f9f; }

.options-list .flexItemsTitlePlus {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-content: center;
  align-items: center; }

.options-list-row {
  height: 100%;
  flex-wrap: nowrap; }
  .options-list-row .border {
    border: 1px solid black; }
  .options-list-row .border-red {
    border: 1px solid red; }
  .options-list-row .coupon {
    background: #eaecee;
    display: flex;
    justify-content: space-between;
    font-family: Helvetica;
    border-radius: 5px;
    padding: 10px 15px;
    margin: 10px; }
    .options-list-row .coupon .coupon-buttons {
      display: flex !important;
      justify-content: center !important;
      align-items: center !important; }
      .options-list-row .coupon .coupon-buttons .coupon-btn {
        cursor: pointer;
        height: 2.5rem;
        text-decoration: none;
        margin: 0 5px; }
      .options-list-row .coupon .coupon-buttons .btn-primary {
        border: 2px solid #0562ff !important; }
      .options-list-row .coupon .coupon-buttons .btn-secondary {
        border: 2px solid #ccc !important; }
    .options-list-row .coupon .coupon-info {
      display: flex;
      flex-direction: column; }
      .options-list-row .coupon .coupon-info .coupon-name, .options-list-row .coupon .coupon-info .coupon-discount, .options-list-row .coupon .coupon-info .coupon-stripeid {
        margin: 0 !important; }
      .options-list-row .coupon .coupon-info .bold {
        font-weight: bold;
        margin-right: 5px; }

.referral-dates {
  width: 50%;
  margin: 30px; }
  .referral-dates .btn-lg {
    font-size: 1rem !important; }
  .referral-dates .input-dates .input-date {
    margin-bottom: 1rem; }
    .referral-dates .input-dates .input-date .input-label {
      font-size: 1rem;
      font-weight: bold; }
