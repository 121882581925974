.change-primary-writer-component form {
  margin-top: 10px;
  margin-bottom: 10px; }

.change-primary-writer-component .btn {
  width: 50%;
  height: 40px;
  font-weight: bold; }

.change-primary-writer-component .bottom-buttons {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 15px;
  margin-right: 15px; }

.change-primary-writer-component .card-block {
  margin-bottom: 35px; }

.change-primary-writer-component .text {
  margin-bottom: 20px;
  line-height: 170%; }

.change-primary-writer-component .errors {
  color: red;
  text-align: center; }

.change-primary-writer-component .label {
  display: inline-block;
  font-weight: bold; }

.change-primary-writer-component .labelPosition {
  margin-right: 10px; }

.change-primary-writer-component .writersSelector {
  width: 100% !important; }

.change-primary-writer-component .selectorContainer {
  margin-top: 5px; }

.change-primary-writer-component .center-text {
  text-align: center; }

.change-primary-writer-component .label {
  display: inline-block; }

.change-primary-writer-component .label-exam-date {
  padding-top: 5px;
  margin-right: 10px; }

.change-primary-writer-component .marginBottom5 {
  margin-bottom: 15px; }

.change-primary-writer-component .react-datepicker__input-container {
  width: 100%;
  border: 1px solid #aaa;
  background-color: white;
  border-radius: 4px; }

.change-primary-writer-component .react-datepicker-wrapper {
  width: 100%; }

.change-primary-writer-component .datePickerClass {
  padding: 0.5rem 1rem;
  font-size: 0.875rem;
  line-height: 1.25;
  border: 0;
  cursor: pointer;
  width: 100%;
  border-radius: 4px; }

.change-primary-writer-component .explanation {
  padding-bottom: 12px; }
