.manual-red-component .modal-content {
  width: 614px; }

.manual-red-component .manualRedConfirmationForm {
  margin-top: 10px; }

.manual-red-component .bottom-buttons-new {
  display: flex;
  justify-content: flex-end; }
  .manual-red-component .bottom-buttons-new .btn-success {
    color: #fff;
    background-color: #3e7ae2;
    border-color: #3e7ae2; }
  .manual-red-component .bottom-buttons-new .btn-secondary {
    color: #263238;
    background-color: #d6dbe1;
    border-color: #d6dbe1; }

.manual-red-component .card-block {
  padding: 0px;
  padding-bottom: 15px; }
  .manual-red-component .card-block .createChapterTitle {
    font-family: Helvetica;
    font-size: 24px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #000000;
    padding-top: 5px; }
    .manual-red-component .card-block .createChapterTitle .fa-times {
      font-size: 16px;
      cursor: pointer;
      float: right;
      opacity: 0.5;
      margin-top: 5px; }

.manual-red-component .text {
  margin-bottom: 20px;
  line-height: 170%; }

.manual-red-component .errors {
  color: red;
  text-align: center; }
