.create-chapter-component form {
  margin-top: 10px;
  margin-bottom: 10px; }

.create-chapter-component .btn {
  width: 80px;
  height: 40px;
  font-weight: bold; }

.create-chapter-component .bottom-buttons-new {
  display: flex;
  justify-content: flex-end; }
  .create-chapter-component .bottom-buttons-new .btn-success {
    color: #fff;
    background-color: #3e7ae2;
    border-color: #3e7ae2; }
  .create-chapter-component .bottom-buttons-new .btn-secondary {
    color: #263238;
    background-color: #d6dbe1;
    border-color: #d6dbe1; }
  .create-chapter-component .bottom-buttons-new .fa-trash {
    position: absolute;
    left: 20px;
    bottom: 27px;
    font-size: 20px;
    color: grey; }

.create-chapter-component .card-block {
  margin-bottom: 15px;
  padding: 0px; }
  .create-chapter-component .card-block .createChapterTitle {
    font-family: Helvetica;
    font-size: 24px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #000000;
    padding-top: 5px; }
    .create-chapter-component .card-block .createChapterTitle .fa-times {
      font-size: 16px;
      cursor: pointer;
      float: right;
      opacity: 0.5;
      margin-top: 5px; }

.create-chapter-component .text {
  margin-bottom: 20px;
  line-height: 170%; }

.create-chapter-component .errors {
  color: red;
  text-align: center; }

.create-chapter-component .label {
  display: block;
  font-weight: bold; }

.create-chapter-component .InputLebel {
  display: block;
  line-height: 35px;
  margin-bottom: 1rem; }

.create-chapter-component .labelPosition {
  margin-right: 15px;
  margin-bottom: 10px; }

.create-chapter-component .editRefLabel {
  margin-bottom: 0px !important; }

.create-chapter-component .WrappText {
  word-wrap: break-word; }

.create-chapter-component .BibliographyLog {
  padding-bottom: 10px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1); }

.modal-create-chapter .modal-content {
  margin-top: 50px !important;
  width: 600px;
  border: 2px solid #179ce9; }

.modal-create-chapter .card-block {
  padding-top: 0px; }

.modal-create-chapter .modal-body {
  padding-bottom: 0px; }

.marginTop10 {
  margin-top: 10px !important; }

.log-modal .modal-content {
  max-height: 600px;
  overflow: auto; }

.colNeedPublication {
  display: flex;
  align-items: center;
  margin-bottom: 15px; }
