.assign-writers-component .new-admin-form .form-group .form-control {
  margin: 0px; }

.assign-writers-component .new-admin-form .form-control {
  margin-left: 0px; }

.assign-writers-component .greyText {
  color: #7f7f7f; }

.assign-writers-component form {
  margin-top: 5px;
  margin-bottom: 10px; }

.assign-writers-component .btn {
  width: 80px;
  margin-right: 0px;
  height: 40px;
  font-weight: bold; }

.assign-writers-component .bottom-buttons-new {
  display: flex;
  justify-content: flex-end; }
  .assign-writers-component .bottom-buttons-new .btn-success {
    color: #fff;
    background-color: #3e7ae2;
    border-color: #3e7ae2; }
  .assign-writers-component .bottom-buttons-new .btn-secondary {
    color: #263238;
    background-color: #d6dbe1;
    border-color: #d6dbe1; }
  .assign-writers-component .bottom-buttons-new .fa-trash {
    position: absolute;
    left: 20px;
    bottom: 27px;
    font-size: 20px;
    color: grey; }

.assign-writers-component .card-block {
  padding: 0px; }
  .assign-writers-component .card-block .createChapterTitle {
    font-family: Helvetica;
    font-size: 24px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #000000;
    padding-top: 5px; }
    .assign-writers-component .card-block .createChapterTitle .fa-times {
      font-size: 16px;
      cursor: pointer;
      float: right;
      opacity: 0.5;
      margin-top: 5px; }

.assign-writers-component .text {
  margin-bottom: 20px;
  line-height: 170%; }

.assign-writers-component .errors {
  color: red;
  text-align: center; }

.assign-writers-component .label {
  display: inline-block;
  font-weight: bold; }

.assign-writers-component .labelPosition {
  margin-right: 5px; }

.assign-writers-component .writersSelector {
  width: 100% !important; }

.assign-writers-component .selectorContainer {
  margin-top: 5px; }

.assign-writers-component .center-text {
  text-align: center; }

.assign-writers-component .label {
  display: inline-block; }

.assign-writers-component .label-exam-date {
  padding-top: 5px;
  margin-right: 10px; }

.assign-writers-component .marginBottom5 {
  margin-bottom: 15px; }

.assign-writers-component .react-datepicker__input-container {
  width: 100% !important;
  border: 1px solid #aaa;
  background-color: white;
  border-radius: 4px; }

.assign-writers-component .react-datepicker-wrapper {
  width: 100%; }

.assign-writers-component .datePickerClass {
  padding: 0.5rem 1rem;
  font-size: 0.875rem;
  line-height: 1.25;
  border: 0;
  cursor: pointer;
  width: 100%;
  border-radius: 4px; }

.grey-modal-body .modal-body {
  background: #f6f6f7; }

.hidden {
  display: none; }
