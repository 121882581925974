.modal-dialog {
  max-width: 600px !important; }

.addContainer {
  padding-bottom: 10px; }
  .addContainer .headContainer {
    justify-content: space-between;
    align-items: center;
    margin: 1px; }
    .addContainer .headContainer .fa-times {
      cursor: pointer; }
    .addContainer .headContainer .title {
      font-family: helvetica;
      font-size: 24px; }
  .addContainer .bodyContainer .directionRow {
    display: flex;
    flex-direction: row;
    align-items: center; }
  .addContainer .bodyContainer .selects {
    height: 2.1rem;
    border: 1px solid #d9d9d9;
    background: transparent; }
    .addContainer .bodyContainer .selects.error {
      border: 1px solid red; }
  .addContainer .bodyContainer .expiryAT {
    width: 100% !important;
    height: 2.1rem;
    border: 1px solid #d9d9d9;
    background: transparent; }
  .addContainer .bodyContainer .inputDays {
    height: 2.1rem;
    width: 5.1rem;
    border: 1px solid #d9d9d9;
    margin-left: 5px; }
  .addContainer .bodyContainer .react-datepicker__input-container {
    width: 100% !important; }
  .addContainer .footerContainer {
    text-align: end; }
    .addContainer .footerContainer .btn-success {
      color: #fff;
      background-color: #3e7ae2;
      border-color: #3e7ae2;
      font-weight: bold; }
    .addContainer .footerContainer .btn-secondary {
      color: #263238;
      background-color: #d6dbe1;
      border-color: #d6dbe1;
      font-weight: bold; }

.dividingLine {
  width: 100%; }

.redBorder {
  height: 2.1rem;
  width: 5.1rem;
  border: 1px solid #d9d9d9;
  margin-left: 5px;
  border: 1px solid red; }
