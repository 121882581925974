.add-member-in-group-component .add-member-in-group-form {
  margin-top: 10px; }

.add-member-in-group-component .btn {
  width: 50%;
  height: 40px;
  font-weight: bold; }

.add-member-in-group-component .bottom-buttons {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 15px;
  margin-right: 15px; }

.add-member-in-group-component .card-block {
  margin-bottom: 35px; }

.add-member-in-group-component .text {
  margin-bottom: 20px;
  line-height: 170%; }

.add-member-in-group-component .errors {
  color: red;
  text-align: center; }

.add-member-in-group-component .label {
  display: inline-block;
  width: 30%; }

.add-member-in-group-component form {
  margin-bottom: 10px; }

.add-member-in-group-component .buttonsContainer {
  padding-right: 21.5px;
  float: right;
  margin-top: 5px; }
  .add-member-in-group-component .buttonsContainer .btn-success {
    margin-top: 19px;
    background-color: #366FDE;
    width: 80px;
    height: 40px; }
  .add-member-in-group-component .buttonsContainer .btn-secondary {
    margin-top: 19px;
    width: 80px;
    height: 40px;
    background-color: #A4B7C1; }

.add-member-in-group-component .buttonsSection {
  margin-top: 23.5px;
  width: 116.3%;
  height: 1px;
  background-color: rgba(0, 0, 0, 0);
  border-top: solid 1px #c2cfd6;
  margin-left: -35px; }
