.quals-component {
  height: 100%;
  align-items: flex-start; }
  @media screen and (max-width: 767px) {
    .quals-component {
      width: auto;
      flex-direction: column;
      height: initial; } }

.quals-list-container {
  max-width: 250px;
  text-align: center;
  height: 100%;
  align-items: flex-start;
  overflow: auto;
  background-color: #eaecee;
  padding-right: 0px;
  max-width: 480px;
  min-width: 320px; }
  @media screen and (max-width: 767px) {
    .quals-list-container {
      height: auto;
      width: 100%;
      max-width: none; } }
  .quals-list-container .rates-title {
    background-color: #eaecee;
    font-weight: bold;
    text-align: left;
    font-size: 14px; }
  .quals-list-container .line-quals-page {
    margin: 12px 0 0 -18px;
    width: 88px;
    height: 4px;
    opacity: 0.79;
    background-color: #000000; }
