.childGrid {
  display: flex;
  flex-direction: column;
  width: 95%;
  margin: 10px auto; }

.centerText {
  text-align: center; }

.quals-content-container {
  height: 100%;
  overflow: auto; }
  .quals-content-container .inputErrorChapter {
    border: solid 1px red; }
  .quals-content-container .noReferences {
    width: 200px;
    margin: 20px auto; }
  .quals-content-container .inputError {
    color: red; }
  .quals-content-container .rates-list-container {
    padding-right: 0px !important;
    padding-left: 0px !important;
    text-align: left !important;
    overflow-y: hidden !important;
    height: 100vh; }
  .quals-content-container .mTop20 {
    margin-top: 20px; }
  .quals-content-container .listWrapper {
    margin-top: 20px;
    height: 100%; }
  .quals-content-container .booksPageTop {
    display: flex;
    width: 100%;
    margin-top: 20px;
    justify-content: space-between; }
    .quals-content-container .booksPageTop .btn-sm {
      background-color: #366FDE; }
  .quals-content-container .references-list-component {
    clear: both; }
  .quals-content-container .list-group-item {
    background-color: #eaecf0;
    border: none !important;
    cursor: pointer;
    position: relative;
    padding: 0;
    display: flex;
    flex-flow: column;
    justify-content: center;
    height: 50px; }
    .quals-content-container .list-group-item.dragged-over:before {
      content: "";
      position: absolute;
      background-color: #656565;
      top: 100%;
      height: 4px;
      width: 100%; }
    .quals-content-container .list-group-item.being-dragged {
      opacity: .5; }
    .quals-content-container .list-group-item > span {
      display: flex;
      align-items: stretch;
      justify-content: space-between;
      flex: 1 1 auto; }
  .quals-content-container .tree-view {
    position: relative;
    overflow: visible; }
    .quals-content-container .tree-view .list-group-item:hover {
      background-color: #f4f5f6; }
  .quals-content-container .tree-level-0 {
    flex: 1 1 0;
    margin-top: 10px;
    margin-left: 15px;
    padding-left: 15px;
    font-weight: bold;
    background-color: white !important;
    border: none !important; }
  .quals-content-container .addBtn {
    margin-left: 0px !important; }
  .quals-content-container .tree-level-1 {
    flex: 1 1 0;
    margin-top: 10px;
    padding-left: 40px;
    font-weight: bold; }
  .quals-content-container .tree-options {
    font-size: 14px;
    color: #999;
    float: right;
    display: flex;
    align-items: stretch; }
    .quals-content-container .tree-options > a {
      padding: .5rem;
      display: inline-flex;
      align-items: center; }
      .quals-content-container .tree-options > a:hover, .quals-content-container .tree-options > a:active {
        background-color: #d8d8d8; }
    .quals-content-container .tree-options > div > div {
      height: 100%; }
      .quals-content-container .tree-options > div > div .threeDotsMenuContainer {
        height: 100%;
        display: inline-flex;
        align-items: center; }
        .quals-content-container .tree-options > div > div .threeDotsMenuContainer:hover, .quals-content-container .tree-options > div > div .threeDotsMenuContainer:active {
          background-color: #d8d8d8; }
  .quals-content-container .addChapterBox {
    padding-left: 0px;
    background-color: white !important;
    border: none !important; }
  .quals-content-container .tree-level-2 {
    margin: 5px 0; }
    .quals-content-container .tree-level-2:hover .tree-options {
      color: grey; }
  @media screen and (max-width: 767px) {
    .quals-content-container {
      display: block; } }
  .quals-content-container .tree-view_item {
    cursor: pointer;
    display: flex;
    flex-direction: row; }
    .quals-content-container .tree-view_item:hover .tree-options {
      color: grey; }
  .quals-content-container .tree-view_arrow {
    transition: all 0.1s;
    margin: 0;
    font-size: 30px;
    color: #999;
    position: relative;
    top: 4px;
    left: 30px;
    z-index: 10; }
  .quals-content-container .tree-view_children {
    margin-left: 40px; }
  .quals-content-container input {
    padding: 3px 5px; }
  .quals-content-container .chapters-qty {
    display: flex;
    align-items: center; }
  .quals-content-container .btn-sm {
    background-color: #366FDE; }
  .quals-content-container .btn-secondary {
    color: white; }
  .quals-content-container .actAsChapterContainer, .quals-content-container .filterListContainer {
    margin-left: 15px; }
  .quals-content-container .filterListContainer {
    margin-top: 8.5px; }
    .quals-content-container .filterListContainer .label {
      margin-left: 20px;
      color: #7e7e7e; }
    .quals-content-container .filterListContainer .value {
      font-weight: bold;
      margin-left: 5px; }
    .quals-content-container .filterListContainer .fa-eye {
      cursor: pointer;
      font-size: 16px;
      color: #7e7e7e;
      margin: 0 auto; }
      .quals-content-container .filterListContainer .fa-eye.active {
        color: #ffffff; }
    .quals-content-container .filterListContainer .eyeContainer {
      width: 40px;
      position: absolute;
      height: 100%;
      margin-top: -12px;
      margin-left: -20px;
      display: flex;
      align-items: center; }
      .quals-content-container .filterListContainer .eyeContainer.active {
        background-color: #707070; }
    .quals-content-container .filterListContainer .infoSection {
      margin-left: 10px; }
  .quals-content-container .subtree {
    margin-left: 72px; }
  .quals-content-container .studyProgramsContainer {
    cursor: pointer;
    padding-top: 5px;
    position: absolute;
    height: 100%;
    right: 195px;
    padding-right: 8px;
    padding-left: 7.7px;
    border-right: 1px solid #fff;
    border-left: 1px solid #fff;
    font-family: Helvetica;
    font-size: 13px;
    color: #000000; }
    .quals-content-container .studyProgramsContainer.centerSPs {
      padding-top: 0px;
      display: flex;
      justify-content: center;
      align-items: center; }
  .quals-content-container .associateRatePlus {
    margin-top: -12px;
    display: block;
    height: 100%;
    position: absolute;
    right: 242px;
    color: #707070;
    border-right: 1px solid #c2cfd6;
    border-left: 1px solid #c2cfd6;
    padding-top: 12px;
    padding-right: 20px;
    padding-left: 20px; }
    .quals-content-container .associateRatePlus i {
      margin: -5px; }
  .quals-content-container .TextLink {
    margin-top: 10px;
    margin-left: 20px; }
  .quals-content-container .addFilterContainer {
    margin-left: 20px;
    cursor: pointer;
    width: 89px;
    height: 31px;
    border: solid 1px #0058ff;
    color: #0058ff; }
    .quals-content-container .addFilterContainer .fa-plus {
      margin-top: 11px;
      margin-left: 23px;
      position: absolute;
      font-size: 9px; }
    .quals-content-container .addFilterContainer .fa-filter {
      position: absolute;
      margin-top: 7px;
      margin-left: 10px; }
    .quals-content-container .addFilterContainer .filterLabel {
      margin-top: 4px;
      margin-left: 40px;
      display: inline-block; }
    @media screen and (max-width: 767px) {
      .quals-content-container .addFilterContainer {
        display: none; } }
