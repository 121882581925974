.react-resizable {
  margin-right: 20px !important; }

h3 {
  margin: 10px 0; }

.rememberMessage {
  color: red;
  font-size: 1rem; }

.weekLabel {
  display: block;
  margin: 10px 0;
  font-size: 1rem; }

.availableVars {
  display: block;
  margin-bottom: 10px; }
