.quals-list-component .PaginationWrapper {
  bottom: 35px;
  position: absolute; }
  @media screen and (max-width: 767px) {
    .quals-list-component .PaginationWrapper {
      bottom: 0; } }
  .quals-list-component .PaginationWrapper .page-link {
    padding: 0.4rem 0.55rem; }

.quals-list-component .quals-title {
  margin: 10px;
  font-size: 120%; }
  .quals-list-component .quals-title .fa-plus {
    float: right;
    cursor: pointer; }

.quals-list-component .qual-line {
  cursor: pointer;
  font-size: 93%; }
  .quals-list-component .qual-line.active {
    font-weight: bold; }

.quals-list-component .quals-list {
  text-align: left;
  margin-top: 10px; }
  @media screen and (max-width: 767px) {
    .quals-list-component .quals-list {
      display: flex;
      justify-content: space-between; } }

.quals-list-component .fa-check {
  margin-top: 2.4px;
  float: right; }
