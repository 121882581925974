.role-editor-component {
  width: 100%; }
  .role-editor-component label.form-control-label {
    font-weight: bold; }
  .role-editor-component .buttons {
    text-align: right; }
    .role-editor-component .buttons button {
      margin: 5px 0 5px 10px; }
  .role-editor-component hr {
    margin: 25px 0; }
  .role-editor-component input[type=text] {
    color: #333; }
  .role-editor-component .rct-collapse.rct-collapse-btn {
    visibility: hidden; }

.react-checkbox-tree .rct-icon {
  font-family: "Font Awesome 5 Free", "FontAwesome", sans-serif; }

.rct-icon-uncheck:before {
  content: "\F0C8"; }

.rct-icon-check:before {
  content: "\F14A"; }

.rct-icon-half-check:before {
  content: "\F14A"; }
